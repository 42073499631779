import styled from 'styled-components';
import { theme } from '../../../../themes';

export const Content = styled.div`
  margin: 16px 0 24px;
  display: flex;
  flex-direction: column;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 8px;


  margin: 24px auto 24px 0;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;

    > span {
      color: ${theme.colors.grayishBlue};
      font-family: ${theme.fonts.lato};
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
  
      :last-of-type {
        text-decoration: underline;
        cursor: pointer;
      }
  
    }
  }
`;
