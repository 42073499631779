import React from 'react';
import { Container } from './style';
import FacebookImage from './images/facebook.png';
import LinkedinImage from './images/linkedin.png';
import InstagramImage from './images/instagram.png';
import TelegramImage from './images/telegram.png';
import WhatsappImage from './images/whatsapp.png';

const links = {
  facebook: 'https://www.facebook.com/reencarcom',
  linkedin: 'https://www.linkedin.com/company/reencar/',
  telegram: 'https://t.me/ReenCar',
  instagram: 'https://instagram.com/reen_car',
  whatsapp: 'https://api.whatsapp.com/send?phone=38268701334',
};

export const SocialNetworks = () => {
  return <Container>
    <a href={links.facebook} target="_blank"><img src={FacebookImage}/></a>
    <a href={links.linkedin} target="_blank"><img src={LinkedinImage}/></a>
    <a href={links.telegram} target="_blank"><img src={TelegramImage}/></a>
    <a href={links.instagram} target="_blank"><img src={InstagramImage}/></a>
    <a href={links.whatsapp} target="_blank"><img src={WhatsappImage}/></a>
  </Container>;
};
