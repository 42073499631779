import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Actions, RootState } from '../../../../../../redux';
import { useLocalStorage } from '../../../../../../shared/hooks';
import { Language, languageList } from '../../../../../../types';
import { ReactComponent as ArrowImage } from './images/arrow.svg';
import { ReactComponent as RUImage } from './images/es.svg';
import { ReactComponent as ESImage } from './images/ru.svg';
import { ReactComponent as UKImage } from './images/uk.svg';
import { Container, Menu, Select } from './style';

const languages: Record<Language, { title: string, icon: ReactNode }> = {
  'EN': {
    title: 'ENG',
    icon: <UKImage />,
  },
  'RU': {
    title: 'РУС',
    icon: <ESImage />,
  },
  'ES': {
    title: 'ESP',
    icon: <RUImage />,
  },
};

export const LanguageSelect = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { search } = useLocation();

  const language = useSelector((state: RootState) => state.App.language);

  useEffect(() => {
    const query = new URLSearchParams(search);

    if (!query) {
      return;
    }

    const languageQuery  = query.get('lang')?.toUpperCase() as Language;

    if (languageList.includes(languageQuery)) {
      dispatch(Actions.App.setLanguage(languageQuery));
      query.delete('lang');

      history.replace({
        search: query.toString(),
      });
    }
  }, [search, history, dispatch]);

  const [opened, setOpened] = useState(false);

  const [, setLanguageLS] = useLocalStorage('language', language);

  return <Container>
    <Select opened={opened} onClick={() => setOpened(!opened)}>
      <div>
        {languages[language].icon}
        {languages[language].title}
      </div>
      <ArrowImage />
    </Select>
    {opened && <Menu>{(Object.keys(languages) as Language[])
      .filter((key) => key !== language).map((key, i) => <li key={i} onClick={() => {
        dispatch(Actions.App.setLanguage(key));
        setLanguageLS(key);
        setOpened(false);
      }}>
        {languages[key].icon}
        {languages[key].title}
      </li>)}</Menu>}
  </Container>;
};
