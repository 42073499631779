import React from 'react';
import { itemsPerPageList } from '../../shared';
import { useTranslation } from 'react-i18next';
import { Dropdown } from '../dropdown';
import ArrowImage from './images/arrow.svg';
import { Content, Empty, Label, Page, Wrapper } from './style';

interface Props {
  page: number;
  totalPages: number;
  margin?: string;
  itemsPerPage: number;
  totalItems: number;
  setPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}

export const Pagination = ({ page, totalPages, margin, itemsPerPage, totalItems, setPage, setItemsPerPage }: Props) => {
  const { t } = useTranslation();

  return <Wrapper>
    <Content margin={margin}>
      <Label>{t('Rows per page')}</Label>
      <Dropdown
        value={itemsPerPage.toString()}
        onChange={(value) => {
          setItemsPerPage(+value);
          setPage(1);
        }}
        options={[...itemsPerPageList.map((item) => item.toString()), 'All'].map((label) => ({ value: label === 'All' ? totalItems.toString() : label, label }))}
        autoDropdownWidth
        noClearButton
        inline
      />
      {totalPages > 1 && <>
        <Page onClick={page > 1 ? () => setPage(page - 1) : undefined} buttonType='prev'><img src={ArrowImage} /></Page>
        <Page onClick={() => setPage(1)} buttonType={page === 1 ? 'active' : undefined}>{1}</Page>
        {page > 3 && <Empty>...</Empty>}
        {page === totalPages && totalPages > 3 && <Page onClick={() => setPage(page - 2)}>{page - 2}</Page>}
        {page > 2 && <Page onClick={() => setPage(page - 1)}>{page - 1}</Page>}
        {page !== 1 && page !== totalPages && <Page onClick={() => setPage(page)} buttonType='active'>{page}</Page>}
        {page < totalPages - 1 && <Page onClick={() => setPage(page + 1)}>{page + 1}</Page>}
        {page === 1 && totalPages > 3 && <Page onClick={() => setPage(page + 2)}>{page + 2}</Page>}
        {page < totalPages - 2 && <Empty>...</Empty>}
        {totalPages > 1 && <Page onClick={() => setPage(totalPages)} buttonType={page === totalPages ? 'active' : undefined}>{totalPages}</Page>}
        <Page onClick={page < totalPages ? () => setPage(page + 1) : undefined} buttonType='next'><img src={ArrowImage} /></Page>
      </>}
    </Content>
  </Wrapper>;
};
