import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CarShort, Color, Condition, PartCode, PartStatus, PositionCenter, PositionFrontRear, PositionLeftRight, PositionUpperLower, PriceListItem, avitoCategoriesMapping, colorName, conditionList, conditionTitles, partCodes, partStatusList, partStatusTitles } from '../../../../../../../../types';
import { Checkbox, ColorPicker, Dropdown, Input, Selector } from '../../../../../../components';
import storageImage from './images/storage.png';
import { Content, Row, StorageImage } from './style';
import { AddPicture } from '../add-picture';

interface Props {
  part?: Partial<PriceListItem>;
  filesToDelete: string[];
  carsShort: CarShort[];
  setPart: (part: Partial<PriceListItem>) => void;
  addFileToDelete: (file: string) => void;
}

const statusOptions = partStatusList.map((status) => ({ label: partStatusTitles[status], value: status }));
const conditionOptions = conditionList.map((status) => ({ label: conditionTitles[status], value: status }));
const typeOptions = partCodes.map((value) => ({ value, label: value }));

export const Basic = ({ part, filesToDelete, carsShort, setPart, addFileToDelete }: Props) => {
  const { t } = useTranslation();

  const carOptions = useMemo(() => carsShort.map(({ id, carName }) => ({ value: id.toString(), label: carName })) || [], [carsShort]);

  return <Content>
    <div>
      <Input
        width='100%'
        placeholder='Name'
        value={part?.name?.toString?.()}
        onChange={(value) => setPart({ name: value })}
      />
      <Input
        width='100%'
        placeholder='Description'
        value={part?.description?.toString?.()}
        onChange={(value) => setPart({ description: value })}
      />
      <Dropdown
        width='100%'
        placeholder='Type'
        value={part?.code}
        options={typeOptions}
        onChange={(value) => setPart({
          code: value as PartCode,
          ...(avitoCategoriesMapping[value] ? { avito: avitoCategoriesMapping[value] } : {}),
        })}
        search
      />
      <Row>
        <Dropdown
          width='100%'
          placeholder='Status'
          value={part?.status}
          options={statusOptions}
          onChange={(value) => setPart({ status: value as PartStatus })}
        />
        <Dropdown
          width='100%'
          placeholder='State'
          value={part?.condition}
          options={conditionOptions}
          onChange={(value) => setPart({ condition: value as Condition })}
        />
      </Row>
      <Row>
        <Input
          width='100%'
          placeholder='Price'
          value={part?.price}
          onChange={(value) => setPart({ price: +value })}
        />
        <Input
          width='100%'
          placeholder='Quantity'
          value={part?.quantity}
          onChange={(value) => setPart({ quantity: +value })}
        />
      </Row>
      <Row>
        <Input
          width='100%'
          placeholder='Length'
          value={part?.length}
          onChange={(value) => setPart({ length: +value })}
        />
        <Input
          width='100%'
          placeholder='Width'
          value={part?.width}
          onChange={(value) => setPart({ width: +value })}
        />
        <Input
          width='100%'
          placeholder='Height'
          value={part?.height}
          onChange={(value) => setPart({ height: +value })}
        />
        <Input
          width='100%'
          placeholder='Weight'
          value={part?.weight}
          onChange={(value) => setPart({ weight: +value })}
        />
      </Row>
      <div>
        <Checkbox
          value={part?.isChooseLocation || false}
          label='Location'
          onChange={(value) => setPart({ isChooseLocation: value })}
          noPadding
          small
        />
      </div>
      <div>
        <Row gap={8}>
          <Selector
            type='left-right'
            selected={part?.positionLeftRight}
            setSelected={(value) => setPart({ positionLeftRight: value as PositionLeftRight })}
            disabled={!part?.isChooseLocation}
            border
          />
          <Selector
            type='front-rear'
            selected={part?.positionFrontRear}
            setSelected={(value) => setPart({ positionFrontRear: value as PositionFrontRear })}
            disabled={!part?.isChooseLocation}
            border
          />
          <Selector
            type='upper-lower'
            selected={part?.positionUpperLower}
            setSelected={(value) => setPart({ positionUpperLower: value as PositionUpperLower })}
            disabled={!part?.isChooseLocation}
            border
          />
          <Selector
            type='center'
            selected={part?.positionCenter}
            setSelected={(value) => setPart({ positionCenter: value as PositionCenter })}
            disabled={!part?.isChooseLocation}
            border
          />
        </Row>
      </div>
      <div>
        <Checkbox
          value={part?.isChooseColor || false}
          label='Color'
          onChange={(value) => setPart({ isChooseColor: value })}
          noPadding
          small
        />
      </div>
      <div>
        <Row>
          <Input
            placeholder='Color'
            width='100%'
            value={part?.color ? t(colorName[part?.color]) : ''}
            onChange={(value) => setPart({ color: value as Color })}
            disabled
          />
          <Input
            placeholder='Color code'
            width='100%'
            value={part?.colorCode}
            onChange={(value) => setPart({ colorCode: value })}
            disabled={!part?.isChooseColor}
          />
        </Row>
      </div>
      <div>
        <ColorPicker
          selected={part?.color}
          setSelected={(value) => setPart({ color: value as Color })}
          disabled={!part?.isChooseColor}
        />
      </div>
      <div>
        <Row>
          <StorageImage src={storageImage} alt='Storage image' />
          <Input
            placeholder='Storage cell'
            width='100%'
            value={part?.storageCell}
            onChange={(value) => setPart({ storageCell: value })}
          />
        </Row>
      </div>
      <div>
        <Row>
          <Dropdown
            width='292px'
            placeholder='Car'
            value={part?.carId?.toString?.()}
            options={carOptions}
            search
            onChange={(value) => {
              const car = carsShort.find((item) => item.id === +value);

              setPart({
                carId: +value,
                carName: car?.carName,
                brand: car?.brand,
              });
            }}
          />
          <Input
            width='292px'
            placeholder='Brand'
            value={part?.brand}
            onChange={(value) => setPart({ brand: value })}
          />
        </Row>
      </div>
      <AddPicture urls={part?.images?.filter?.((url) => !filesToDelete.includes(url))} addToDelete={addFileToDelete} />
    </div>
  </Content>;
};
