import React, { useEffect } from 'react';
import { Popup } from '../../popup';
import { Buttons, Label, Title } from '../../style';
import { Button } from '../../../button';

interface Props {
  title: string;
  text: string;
  buttons: Array<{
    label: string;
    secondary?: boolean;
    onClick: () => void;
  }>;
  onClose: () => void;
}

export const Confirmation = ({ title, text, buttons, onClose }: Props) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Enter' ) {
        buttons.find(({ secondary }) => !secondary)?.onClick?.();
        e.preventDefault();
        e.stopPropagation();
      }
    };

    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [buttons]);

  return <Popup width='400px' onClose={onClose}>
    <Title margin='0 0 10px 0'>{title}</Title>
    <Label margin='0 0 20px 0'>{text}</Label>
    <Buttons>
      {buttons.map(({ label, secondary, onClick }, i) => <Button key={i} secondary={secondary} onClick={() => onClick()}>{label}</Button>)}
    </Buttons>
  </Popup>;
};
