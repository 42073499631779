import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { menuItems } from '../../../../menu-items';
import { MenuStyle, MenuItem } from './style';

interface Props {
  isAdmin: boolean;
}

export const Menu = ({ isAdmin }: Props) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState(-1);

  return <MenuStyle>
    {menuItems.filter((item) => item.isAdmin === isAdmin || item.isAdmin === undefined).map(({ title, link }, i) => <MenuItem
      key={i}
      selected={selected === i || window.location.pathname === link}
      onClick={() => {
        setSelected(i);
        document.location.href = link;
      }}
    >
      {t(title)}
    </MenuItem>)}
  </MenuStyle>;
};
