import { PriceListItem, Shop } from '../../../../types';
import { prepareAvito } from '../../../../shared';

export const exportToAvito = (parts: PriceListItem[], shop: Shop) => {
  const xmlContent = prepareAvito(parts, shop || {});

  const formattedDate = (new Date()).toISOString().replace(/[:T-]/g, '').slice(0, 8);

  const blob = new Blob([xmlContent], { type: 'application/xml' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = `price-list-avito-${formattedDate}.xml`;
  document.body.append(link);
  link.click();
  link.remove();
  URL.revokeObjectURL(url);
};
