import { AppThunk } from '../../store';

export const uploadFiles = (files: File[], callback?: (urls: string[]) => void): AppThunk => async (dispatch, getState) => {
  const urls = await Promise.all(files.map((file) => {
    const url = fetch('/api/upload-file', {
      method: 'post',
      headers: {
        'Content-Type': file.type || '',
      },
      body: file,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.error(`Error during fetch: ${response.statusText}`);
      }
    }).catch((error) => {
      console.error(error);
    });

    return url;
  }));

  callback?.(urls ? urls.map((item) => item?.url) as string[] : []);
};
