import { BodyType, Doors, Tailgate } from "../types";

const bodyByDoors: { [doors: string]: BodyType } = {
  '2': 'coupe',
  '3': 'hatchback',
  '4': 'sedan',
  '5': 'wagon',
  '6': 'van',
};

const tailgateByDoors: { [doors: string]: Tailgate } = {
  '2': 'trunk-lid',
  '3': 'back-door',
  '4': 'trunk-lid',
  '5': 'back-door',
  '6': '2-doors-vertical',
};

export const getBodyAndTailgateByDoors = (doors: Doors) => ({
  body: bodyByDoors[doors],
  tailgate: tailgateByDoors[doors],
});

const TailgateByBody: { [body: string]: Tailgate } = {
  'hatchback': 'back-door',
  'coupe': 'trunk-lid',
  'pickup': 'trunk-lid',
  'sedan': 'trunk-lid',
  'liftback': 'back-door',
  'cabriolet': 'trunk-lid',
  'wagon': 'back-door',
  'van': '2-doors-vertical',
  'suv': 'back-door',
};

const DoorsByBody: { [body: string]: Doors } = {
  'hatchback': '5',
  'coupe': '2',
  'pickup': '4',
  'sedan': '4',
  'liftback': '4',
  'cabriolet': '2',
  'wagon': '5',
  'van': '6',
  'suv': '5',
};

export const getTailgateAndDoorsByBody = (body: BodyType) => ({
  tailgate: TailgateByBody[body],
  doors: DoorsByBody[body],
});
