import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { GoogleTagTracker } from './components';

interface Props {
  googleTagId?: string;
}

const PublicRoutes = ({ googleTagId }: Props) => {
  return <BrowserRouter>
    <LastLocationProvider>
      {googleTagId && <GoogleTagTracker id={googleTagId} />}
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path={[ '/' ]} component={lazy(() => import('./features/landing'))}/>
          <Route exact path={[ '/add-car' ]} component={lazy(() => import('./features/add-car'))}/>
          <Route exact path={[ '/cars' ]} component={lazy(() => import('./features/cars'))}/>
          <Route exact path={[ '/car/:id' ]} component={lazy(() => import('./features/car'))}/>
          <Route exact path={[ '/parts' ]} component={lazy(() => import('./features/parts'))}/>
          <Route exact path={[ '/users' ]} component={lazy(() => import('./features/users'))}/>
          <Route exact path={[ '/profile/:section?/:id?' ]} component={lazy(() => import('./features/profile'))}/>
          <Route exact path={[ '/controls' ]} component={lazy(() => import('./features/controls'))}/>
        </Switch>
      </Suspense>
    </LastLocationProvider>
  </BrowserRouter>;
};

export default PublicRoutes;
