import React, { ReactNode } from 'react';
import { Loader } from '../loader';
import { NavigationBar, Footer } from './components';
import { Container, Content } from './style';

interface Props {
  children: ReactNode | ReactNode[];
  maxWidth?: number;
  noMargin?: boolean;
  loading?: boolean;
  authButtons?: boolean;
  flex?: boolean;
}

export const Page = ({ children, maxWidth, noMargin, loading, authButtons, flex }: Props) => {
  return <Container>
    {loading && <Loader />}
    <Content maxWidth={maxWidth} flex={flex}>
      <NavigationBar noMargin={noMargin} authButtons={authButtons} />
      {children}
    </Content>
    <Footer noMargin={noMargin} />
  </Container>;
};
