import styled from 'styled-components';
import { theme } from '../../../../themes';

export const Dropzone = styled.div<{hover: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  border: 1px solid ${theme.colors.grayishBlue};
  width: 100%;
  height: 200px;

  cursor: pointer;

  > svg {
    width: 40px;
    height: 33px;
  }

  ${(p) => p.hover ? `
    background: ${theme.colors.veryLightGray};
  ` : ''}

  > span {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.lato};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Statistics = styled.div`
  margin-right: auto;
  display: flex;
  flex-direction: column;

  color: ${theme.colors.black};
  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  > div {
    width: 100%;
    gap: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span:first-of-type {
      font-weight: 500;
    }
  }
`;
