export const postOptions = {
  method: 'post',
  headers: {
    'Content-Type': 'application/json',
  },
};

export const processResponse = (response: Response) => {
  if (response.ok) {
    return response.json();
  } else {
    throw new Error(`Error during fetch: ${response.statusText}`);
  }
};
