import React, { MouseEventHandler, ReactNode, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconImage } from './images/settings.svg';
import { ReactComponent as CartImage } from './images/cart.svg';
import { ReactComponent as NextImage } from './images/next.svg';
import { ReactComponent as ExportImage } from './images/export.svg';
import { ReactComponent as AddImage } from './images/add.svg';
import { ReactComponent as ImportImage } from './images/import.svg';
import { ReactComponent as RefreshImage } from './images/refresh.svg';
import { ReactComponent as ExecuteImage } from './images/execute.svg';
import { ReactComponent as OpenImage } from './images/open.svg';
import { ButtonStyle } from './style';

type IconType = 'settings' | 'cart' | 'next' | 'export' | 'add' | 'import' | 'refresh' | 'execute' | 'open';

const icons: Record<IconType, ReactNode> = {
  settings: <IconImage />,
  cart: <CartImage />,
  next: <NextImage />,
  export: <ExportImage />,
  add: <AddImage />,
  import: <ImportImage />,
  refresh: <RefreshImage />,
  execute: <ExecuteImage />,
  open: <OpenImage />,
};

interface Props {
  children?: string | React.ReactNode;
  width?: string;
  minWidth?: string;
  margin?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  disabled?: boolean;
  wide?: boolean;
  secondary?: boolean;
  hashlink?: string;
  onClick?: (MouseEventHandler<Element>) | (() => void);
}

export const Button = forwardRef((props: Props, ref) => {
  const { t } = useTranslation();

  const { children, iconLeft, iconRight, hashlink, ...componentProps } = props;

  componentProps.onClick = componentProps.onClick || (hashlink ? () => setTimeout(() => {
    const element = document.querySelector(hashlink);

    if (element) {
      element.scrollIntoView();
    }
  }, 0) : undefined);

  return <ButtonStyle {...componentProps} ref={ref} iconLeft={!!iconLeft} iconRight={!!iconRight} title={children} noIconMargin={!children}>
    {iconLeft && icons[iconLeft]}
    {typeof children === 'string' ? t(children) : children}
    {iconRight && icons[iconRight]}
  </ButtonStyle>;
});

