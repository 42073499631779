import styled from 'styled-components';
import { theme } from '../../themes';
import { ReactComponent as RemoveImage } from './images/remove.svg';

export const Container = styled.div`
  display: flex;
  gap: 12px;
`;

export const Add = styled.div<{hover: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 12px;
  border: 1px solid ${theme.colors.grayishBlue};
  width: 96px;
  height: 64px;

  cursor: pointer;

  > svg {
    width: 40px;
    height: 33px;
  }

  ${(p) => p.hover ? `
    background: ${theme.colors.veryLightGray};
  ` : ''}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  width: 96px;
  height: 82px;

  > span {
    color: ${theme.colors.grayishBlue};
    font-family: ${theme.fonts.lato};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const Remove = styled(RemoveImage)`
  position: absolute;
  top: 7.29px;
  right: 7.29px;

  cursor: pointer;
`;

export const Picture = styled.div<{dragged: boolean; notDraggable: boolean}>`
  width: 96px;
  height: 64px;

  ${(p) => !p.notDraggable ? 'cursor: move' : ''};

  > img {
    border-radius: 12px;
    border: 1px solid ${theme.colors.grayishBlue};
  }
`;
