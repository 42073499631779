import React, { PropsWithChildren } from 'react';
import { Style } from './style';

interface Props {
  error?: boolean;
}

export const Label = ({ error, children }: PropsWithChildren<Props>) => {
  return <Style error={error} className={error ? 'input-error' : undefined}>{children}</Style>;
};
