import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router';

interface Props {
  id: string;
}

export const GoogleTagTracker = ({ id }: Props) => {
  const location = useLocation();

  useEffect(() => {
    TagManager.initialize({
      gtmId: id,
      dataLayer: {
        page: location.pathname,
      },
    });
  }, [id, location]);

  return <></>;
};
