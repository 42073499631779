import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Input, Popup } from '../../../../components';
import { Actions } from '../../../../redux';
import { sendGTMEvent, validateEmail, validatePhone } from '../../../../shared';
import { SendRequestEventLocation, gtmLanguageMapping } from '../../../../types';
import { Label } from '../../style';

interface Props {
  sent?: boolean;
  location?: SendRequestEventLocation;
  onClose: () => void;
}

export const PopupSendRequest = ({ sent, location, onClose }: Props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [requestSent, setRequestSent] = useState(sent);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');

  useEffect(() => {
    sendGTMEvent('dataLayer', {
      event: 'form_start',
      language: gtmLanguageMapping[i18n.language],
      ...(location ? { location } : {}),
    });
  }, [i18n, location]);

  useEffect(() => { setEmailError(''); }, [email]);

  useEffect(() => { setPhoneError(''); }, [phone]);

  return <Popup width={requestSent ? '350px' : '410px'} onClose={onClose}>
    <Label margin='0 0 24px 0'>{requestSent ?
      t('Thank you! Your application has been sent. We will contact you soon!') :
      t('Leave your name and phone number, our specialist will contact you and hold a personal presentation')}
    </Label>
    {!requestSent && <>
      <Input margin='0 0 16px 0' placeholder='Name*' value={name} onChange={(value) => setName(value)} />
      <Input margin='0 0 16px 0' placeholder='Email*' value={email} error={emailError} onChange={(value) => setEmail(value)} />
      <Input margin='0 0 24px 0' placeholder='Phone*' value={phone} error={phoneError} onChange={(value) => setPhone(value)} />
      <Button width='100%' wide onClick={() => {
        if (!validateEmail(email)) {
          setEmailError('Email is not valid');
        }

        if (!validatePhone(phone)) {
          setPhoneError('Phone is not valid');
        }

        if (validateEmail(email) && validatePhone(phone)) {
          sendGTMEvent('dataLayer', {
            event: 'gtm.formSubmit',
            field_name: name,
            field_mail: email,
            field_phone: phone,
            language: gtmLanguageMapping[i18n.language],
            ...(location ? { location } : {}),
          });

          dispatch(Actions.App.sendTelegramMessage(`New lead\nName: ${name}\nemail: ${email}\nphone: ${phone}\nlanguage: ${i18n.language}${location ? `\nlocation: ${location}`: ''}`));

          setRequestSent(true);
        }
      }}>Send request</Button>
    </>}
    {requestSent && <Button width='100%' wide onClick={() => onClose()}>Ok</Button>}
  </Popup>;
};
