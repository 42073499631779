export const avitoPrefix = 'REENCAR/';

export const avitoCategories = [{
  Type: 'TRUNK_DOOR_HORIZONTAL_BOTTOM',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крышка, дверь багажника',
}, {
  Type: 'HEATER_RADIATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'DOOR_LOCK',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Замки',
}, {
  Type: 'TRUNK_DOOR_HORIZONTAL_TOP',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крышка, дверь багажника',
}, {
  Type: 'ENGINE_GUARD',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Защита',
}, {
  Type: 'LICENSE_PLATE_LIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'SPARE_WHEEL',
  GoodsType: 'Шины, диски и колёса',
  SparePartType: 'Колёса',
}, {
  Type: 'TIRE',
  GoodsType: 'Шины, диски и колёса',
  SparePartType: 'Легковые шины',
}, {
  Type: 'BODY_TAILLIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'FUEL_TANK_CAP',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Лючок бензобака',
}, {
  Type: 'PARKING_BRAKE_CABLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'INTERIOR_DOOR_TRIM',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'STABILIZER_LINK',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'FUEL_TANK',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'STEERING_KNUCKLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'POWER_STEERING_PUMP',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'HEADLIGHT_WASHER_NOZZLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'WINDSHIELD_WASHER_NOZZLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'FINAL_DRIVE',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Дифференциал',
}, {
  Type: 'MASTER_CYLINDER',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'DOOR_SILL_COVERS',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Молдинги, накладки',
}, {
  Type: 'CLUTCH_DISK',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Сцепление',
}, {
  Type: 'TRUNK_DOOR_VERTICAL',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крышка, дверь багажника',
}, {
  Type: 'ABS_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'PARKING_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'RADIATOR_GRILLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Решетка радиатора',
}, {
  Type: 'CAMSHAFT_POSITION_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Катушка зажигания, свечи, электрика',
}, {
  Type: 'ENGINE_BAY_WIRING',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'MUD_FLAP',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Брызговики',
}, {
  Type: 'TRUNK_LID',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крышка, дверь багажника',
}, {
  Type: 'HEADLIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'SPLASH_SHIELD',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Защита',
}, {
  Type: 'BRAKE_DRUM',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'WIPER_MOTOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'HOOD_LOCK',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Замки',
}, {
  Type: 'ABS_UNIT',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'TRUNK_DOOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крышка, дверь багажника',
}, {
  Type: 'EMBLEM',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Молдинги, накладки',
}, {
  Type: 'TRANSFER_CASE',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Раздаточная коробка',
}, {
  Type: 'SEAT_DRIVER',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'STEERING_WHEEL',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'COOLING_FAN',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'STABILIZER',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'HORN',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'OUTSIDE_TEMPERATURE_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'CARDAN_SHAFT',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Карданная передача',
}, {
  Type: 'ENGINE_CONTROL_MODULE',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'EXTERIOR_DOOR_HANDLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Замки',
}, {
  Type: 'STEREO_MULTIMEDIA',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'DOOR_GLASS',
  GoodsType: 'Запчасти',
  SparePartType: 'Стёкла',
}, {
  Type: 'SEAT_PASSENGER',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'STEERING_RACK',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'BUMPER',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Бамперы',
}, {
  Type: 'STARTER',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Генераторы, стартеры',
}, {
  Type: 'BRAKE_BOOSTER',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'TAILLIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'MASS_AIR_FLOW_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Впускная система',
}, {
  Type: 'WIPER_ARM_REAR',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'WHEEL_HUB',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'SHOCK_ABSORBER',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'HEATER_MOTOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'INSTRUMENT_PANEL',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'POWER_STEERING_FLUID_TANK',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'A_C_COMPRESSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'TRUNK_INTERIOR_TRIM',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'ENGINE_RADIATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'ENGINE_COOLANT_TEMPERATURE_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'EGR_VALVE',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'FOG_LIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'OIL_PRESSURE_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Катушка зажигания, свечи, электрика',
}, {
  Type: 'INTERIOR_MIRROR',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'SUBFRAME',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Балки, лонжероны',
}, {
  Type: 'EXPANSION_TANK',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'LIGHTING_SWITCH',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'DASHBOARD',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'WINDOW_REGULATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'CLUTCH_PEDAL',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Сцепление',
}, {
  Type: 'REAR_FRAME',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Балки, лонжероны',
}, {
  Type: 'WIPER_SWITCH',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'WHEEL_DISK',
  GoodsType: 'Шины, диски и колёса',
  SparePartType: 'Диски',
}, {
  Type: 'BUMPER_GRILLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Решетка радиатора',
}, {
  Type: 'DRIVER_AIRBAG',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'OXYGEN_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'AUTOMATIC_TRANSMISSION',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'КПП в сборе',
}, {
  Type: 'STEERING_LINK',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'PARKING_BRAKE_LEVER',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'CLUTCH_BASKET',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Сцепление',
}, {
  Type: 'WIPER_ARM_FRONT',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'FENDER',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крылья',
}, {
  Type: 'TRUNK_COVER',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'WASHER_TANK',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'TORQUE_CONVERTER',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Переключение передач',
}, {
  Type: 'CLIMATE_CONTROL_MODULE',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'WIPER_MECHANISM',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'GENERATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Генераторы, стартеры',
}, {
  Type: 'CAR_BATTERY',
  GoodsType: 'Запчасти',
  SparePartType: 'Аккумуляторы',
}, {
  Type: 'BUMPER_GUARD',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Защита',
}, {
  Type: 'ENGINE',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Двигатель в сборе',
}, {
  Type: 'ROOF',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крыша',
}, {
  Type: 'BATTERY',
  GoodsType: 'Запчасти',
  SparePartType: 'Аккумуляторы',
}, {
  Type: 'EXTERIOR_REAR_VIEW_MIRROR',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Зеркала',
}, {
  Type: 'SEAT_REAR',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'TRANSMISSION_LEVER',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'BUMPER_REINFORCEMENT',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Крепления',
}, {
  Type: 'SUSPENSION_ARM',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'WASHER_PUMP',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'DOOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Двери',
}, {
  Type: 'CRANKSHAFT_POSITION_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Катушка зажигания, свечи, электрика',
}, {
  Type: 'TELEVISION',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Балки, лонжероны',
}, {
  Type: 'BRAKE_PEDAL',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'SUSPENSION_SPRING',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'INTERIOR_WIRING',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'BRAKE_CALIPER',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'BRAKE_DISC',
  GoodsType: 'Запчасти',
  SparePartType: 'Тормозная система',
}, {
  Type: 'INTERIOR_DOOR_HANDLE',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Замки',
}, {
  Type: 'FUEL_PUMP_MODULE',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'POWER_STEERING_HOSE',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'THIRD_BRAKE_LIGHT',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'STRUT_MOUNT',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}, {
  Type: 'ROOF_INTERIOR_LINING',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'OIL_RADIATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'TIE_ROD_END',
  GoodsType: 'Запчасти',
  SparePartType: 'Рулевое управление',
}, {
  Type: 'SIDE_BODY_WINDOW',
  GoodsType: 'Запчасти',
  SparePartType: 'Стёкла',
}, {
  Type: 'TRUNK_LOCK',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Замки',
}, {
  Type: 'CATALYTIC_CONVERTER',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'TURN_SIGNAL_INDICATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Автосвет',
}, {
  Type: 'WINDSHIELD',
  GoodsType: 'Запчасти',
  SparePartType: 'Стёкла',
}, {
  Type: 'TURN_SIGNAL_SWITCH',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'GAS_PEDAL',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'HOOD',
  GoodsType: 'Запчасти',
  SparePartType: 'Кузов',
  BodySparePartType: 'Капот',
}, {
  Type: 'WINDOW_REGULATOR_CONTROL_MODULE',
  GoodsType: 'Запчасти',
  SparePartType: 'Электрооборудование',
}, {
  Type: 'DRIVE_SYSTEM',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Приводные валы, полуоси и ШРУСы',
}, {
  Type: 'KNOCK_SENSOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Катушка зажигания, свечи, электрика',
}, {
  Type: 'A_C_RADIATOR',
  GoodsType: 'Запчасти',
  SparePartType: 'Система охлаждения',
}, {
  Type: 'EXHAUST_SYSTEM',
  GoodsType: 'Запчасти',
  SparePartType: 'Топливная и выхлопная системы',
}, {
  Type: 'TURBOCHARGER',
  GoodsType: 'Запчасти',
  SparePartType: 'Двигатель',
  EngineSparePartType: 'Турбины, компрессоры',
}, {
  Type: 'SEAT_BELT',
  GoodsType: 'Запчасти',
  SparePartType: 'Салон',
}, {
  Type: 'REAR_WINDOW',
  GoodsType: 'Запчасти',
  SparePartType: 'Стёкла',
}, {
  Type: 'CLUTCH_RELEASE_BEARING',
  GoodsType: 'Запчасти',
  SparePartType: 'Трансмиссия и привод',
  TransmissionSparePartType: 'Сцепление',
}, {
  Type: 'HUB_BEARING',
  GoodsType: 'Запчасти',
  SparePartType: 'Подвеска',
}];

export const avitoCategoriesMapping = avitoCategories.reduce((acc, { Type, ...rest }) => ({ ...acc, [Type]: rest }), {});

export const sparePartTypeList = Array.from(new Set(avitoCategories.filter((item) => !!item.SparePartType).reduce<string[]>((acc, cur) => [ ...acc, cur.SparePartType ], [])));
export const goodsTypeList = Array.from(new Set(avitoCategories.filter((item) => !!item.GoodsType).reduce<string[]>((acc, cur) => [ ...acc, cur.GoodsType ], [])));
export const bodySparePartTypeList = Array.from(new Set(avitoCategories.filter((item) => !!item.BodySparePartType).reduce<string[]>((acc, cur) => [ ...acc, cur.BodySparePartType || '' ], [])));
export const engineSparePartTypeList = Array.from(new Set(avitoCategories.filter((item) => !!item.EngineSparePartType).reduce<string[]>((acc, cur) => [ ...acc, cur.EngineSparePartType || '' ], [])));
export const transmissionSparePartTypeList = Array.from(new Set(avitoCategories.filter((item) => !!item.TransmissionSparePartType).reduce<string[]>((acc, cur) => [ ...acc, cur.TransmissionSparePartType || '' ], [])));

export interface Ad {
  Id: string;
  Address: string;
  ContactPhone?: string;
  Latitude?: string;
  Longitude?: string;
  Category: string;
  Description: string;
  Title: string;
  GoodsType: string;
  AdType: string;
  ProductType: string;
  SparePartType: string;
  Condition: string;
  Brand: string;
  EngineSparePartType?: string;
  TransmissionSparePartType?: string;
  BodySparePartType?: string;
  Price?: string;
  Images?: { url: string }[];
  Length?: string;
  Width?: string;
  Height?: string;

  ContactMethod?: string;
  DateBegin?: string;
  DateEnd?: string;
  ListingFee?: string;
  AdStatus?: string;
  AvitoId?: string;
  ManagerName?: string;
  DisplayAreas?: string;
  VideoURL?: string;
  TypeId?: string;
  InternetCalls?: string;
  CallsDevices?: string;
  Delivery?: string;
  WeightForDelivery?: string;
  LengthForDelivery?: string;
  HeightForDelivery?: string;
  WidthForDelivery?: string;
  Voltage?: string;
  Capacity?: string;
  DCL?: string;
  Polarity?: string;
  Originality?: string;
  Availability?: string;
  OriginalVendor?: string;
  OriginalOEM?: string;
  Make?: string;
  Model?: string;
  Generation?: string;
  Modification?: string;
  BodyType?: string;
  Doors?: string;
}
