import { Color } from "./car";

export const partStatusList = ['car', 'reserved', 'in-stock', 'sold'] as const;

export type PartStatus = typeof partStatusList[number];

export const partStatusTitles: Record<PartStatus, string> = {
  'car': 'On car',
  'reserved': 'Reserved',
  'in-stock': 'In stock',
  'sold': 'Sold',
}

export const conditionList = ['perfect', 'good', 'satisfactory', 'repairable'] as const;

export type Condition = typeof conditionList[number];

export const conditionTitles: Record<Condition, string> = {
  'perfect': 'Perfect',
  'good': 'Good',
  'satisfactory': 'Satisfactory',
  'repairable': 'Repairable',
}

export const positionLeftRightList = ['left', 'right'] as const;

export type PositionLeftRight = typeof positionLeftRightList[number];

export const positionFrontRearList = ['front', 'rear'] as const;

export type PositionFrontRear = typeof positionFrontRearList[number];

export const positionUpperLowerList = ['upper', 'lower'] as const;

export type PositionUpperLower = typeof positionUpperLowerList[number];

export const positionCenterList = ['center'] as const;

export type PositionCenter = typeof positionCenterList[number];

export interface OEM {
  oem?: string;
  image?: string;
  imageName?: string;
  partName?: string;
  codeOnImage?: string;
  quantity?: number;
  isMain?: boolean;
}

export const priceListFields = ['carName', 'color', 'colorCode', 'condition', 'description', 'height', 'images', 'isChooseColor',
  'isChooseLocation', 'length', 'name', 'oems', 'positionCenter', 'positionFrontRear', 'positionLeftRight', 'positionUpperLower',
  'price', 'quantity', 'status', 'storageCell', 'weight', 'width', 'carId', 'code', 'brand', 'isExportAvito', 'avito'];

export const partCodes = ['AUTOMATIC_TRANSMISSION', 'DRIVE_SYSTEM', 'SHOCK_ABSORBER', 'ENGINE', 'BUMPER', 'FUEL_TANK',
  'POWER_STEERING_PUMP', 'DOOR', 'TRUNK_DOOR', 'TRUNK_LID', 'TRUNK_DOOR_VERTICAL', 'TRUNK_DOOR_HORIZONTAL_TOP',
  'TRUNK_DOOR_HORIZONTAL_BOTTOM', 'FENDER', 'SPARE_WHEEL', 'WHEEL_DISK', 'BUMPER_GUARD', 'ENGINE_GUARD',
  'SPLASH_SHIELD', 'INTERIOR_MIRROR', 'EXTERIOR_REAR_VIEW_MIRROR', 'HOOD', 'CARDAN_SHAFT', 'ENGINE_CONTROL_MODULE',
  'ROOF', 'FUEL_TANK_CAP', 'STEREO_MULTIMEDIA', 'INSTRUMENT_PANEL', 'GAS_PEDAL', 'BRAKE_PEDAL', 'CLUTCH_PEDAL',
  'TURN_SIGNAL_SWITCH', 'WIPER_SWITCH', 'LIGHTING_SWITCH', 'SUBFRAME', 'REAR_FRAME', 'ROOF_INTERIOR_LINING',
  'ENGINE_BAY_WIRING', 'INTERIOR_WIRING', 'ENGINE_RADIATOR', 'A_C_RADIATOR', 'HEATER_RADIATOR', 'OIL_RADIATOR',
  'FINAL_DRIVE', 'RADIATOR_GRILLE', 'BUMPER_GRILLE', 'STEERING_WHEEL', 'TRANSMISSION_LEVER', 'SUSPENSION_ARM',
  'SEAT_DRIVER', 'SEAT_PASSENGER', 'SEAT_REAR', 'STABILIZER', 'BUMPER_REINFORCEMENT', 'TRUNK_COVER', 'TELEVISION',
  'WASHER_TANK', 'POWER_STEERING_FLUID_TANK', 'EXPANSION_TANK', 'ABS_UNIT', 'CLIMATE_CONTROL_MODULE', 'BRAKE_BOOSTER',
  'EXHAUST_SYSTEM', 'WINDSHIELD', 'REAR_WINDOW', 'DOOR_GLASS', 'STEERING_KNUCKLE', 'STEERING_RACK', 'BRAKE_CALIPER',
  'BRAKE_DISC', 'BRAKE_DRUM', 'WIPER_MECHANISM', 'HEADLIGHT', 'TAILLIGHT', 'BODY_TAILLIGHT', 'FOG_LIGHT', 'MASTER_CYLINDER',
  'DASHBOARD', 'FUEL_PUMP_MODULE', 'COOLING_FAN', 'CATALYTIC_CONVERTER', 'OXYGEN_SENSOR', 'TURBOCHARGER', 'TRANSFER_CASE',
  'STRUT_MOUNT', 'STABILIZER_LINK', 'WHEEL_HUB', 'HUB_BEARING', 'POWER_STEERING_HOSE', 'STEERING_LINK', 'TIE_ROD_END',
  'PARKING_BRAKE_CABLE', 'PARKING_BRAKE_LEVER', 'STARTER', 'GENERATOR', 'BATTERY', 'HEADLIGHT_WASHER_NOZZLE', 'A_C_COMPRESSOR',
  'WINDOW_REGULATOR', 'DOOR_LOCK', 'TRUNK_LOCK', 'HOOD_LOCK', 'WINDOW_REGULATOR_CONTROL_MODULE', 'EXTERIOR_DOOR_HANDLE',
  'INTERIOR_DOOR_HANDLE', 'TURN_SIGNAL_INDICATOR', 'THIRD_BRAKE_LIGHT', 'SUSPENSION_SPRING', 'DOOR_SILL_COVERS', 'MUD_FLAP',
  'EMBLEM', 'INTERIOR_DOOR_TRIM', 'TRUNK_INTERIOR_TRIM', 'CLUTCH_RELEASE_BEARING', 'CLUTCH_DISK', 'CLUTCH_BASKET', 'TORQUE_CONVERTER',
  'TIRE', 'LICENSE_PLATE_LIGHT', 'HORN', 'ABS_SENSOR', 'WIPER_MOTOR', 'WASHER_PUMP', 'WIPER_ARM_FRONT', 'WIPER_ARM_REAR',
  'WINDSHIELD_WASHER_NOZZLE', 'HEATER_MOTOR', 'DRIVER_AIRBAG', 'SEAT_BELT', 'PARKING_SENSOR', 'ENGINE_COOLANT_TEMPERATURE_SENSOR',
  'OUTSIDE_TEMPERATURE_SENSOR', 'KNOCK_SENSOR', 'CRANKSHAFT_POSITION_SENSOR', 'OIL_PRESSURE_SENSOR', 'MASS_AIR_FLOW_SENSOR',
  'CAMSHAFT_POSITION_SENSOR', 'CAR_BATTERY', 'EGR_VALVE', 'SIDE_BODY_WINDOW'] as const;

export type PartCode = typeof partCodes[number];

export interface AvitoData {
  id?: string;
  customId?: string;
  SparePartType?: string;
  BodySparePartType?: string;
  EngineSparePartType?: string;
  TransmissionSparePartType?: string;
  GoodsType?: string;
}

export interface PriceListItem {
  id?: number;
  name: string;
  quantity: number;
  carName?: string;
  oems: OEM[];
  price?: number;
  link?: string | string[]; //TBD - remove?
  status?: PartStatus;
  storageCell?: string;
  images?: string[];
  code?: PartCode;

  carId?: number;
  brand?: string;

  description?: string;
  condition?: Condition;
  length?: number;
  width?: number;
  height?: number;
  weight?: number;
  isChooseLocation?: boolean;
  isChooseColor?: boolean;
  color?: Color;
  colorCode?: string;

  positionLeftRight?: PositionLeftRight;
  positionFrontRear?: PositionFrontRear;
  positionUpperLower?: PositionUpperLower;
  positionCenter?: PositionCenter;
  
  isExportAvito?: boolean;
  avito?: AvitoData;

  nodesId: string;
}

export interface PriceListStat {
  responseTime: number;
  total: number;
  totalWithOems: number;
  percent: number;
}

export interface PriceListResponse {
  items: PriceListItem[];
  stat: PriceListStat;
}
