import { styled } from '@mui/system';
import { theme } from '../../themes';
import { ReactComponent as CheckedImage } from './images/checked.svg';
import { ReactComponent as UncheckedImage } from './images/unchecked.svg';

export const Container = styled('div')<{pale?: boolean; noWrap?: boolean; margin?: string; width?: string}>`
  display: flex;
  align-items: center;

  ${(p) => p.pale ? `
    svg {
      rect {
        stroke: ${theme.colors.veryLightGray};
      }
    }
  ` : ''}

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
  ${(p) => p.width ? `width: ${p.width};` : ''}

  > div {
    cursor: pointer;
    user-select: none;
    margin-left: 12px;

    ${(p) => p.pale ? 'opacity: 0.3;' : ''}

    ${(p) => p.noWrap ? `
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    ` : ''}
  }
`;

export const Checked = styled(CheckedImage, {
  shouldForwardProp: (prop) => !['small'].includes(prop as string),
})<{small?: boolean}>`
  height: ${(p) => !p.small ? 32 : 25}px;
  width: ${(p) => !p.small ? 32 : 24}px;
`;

export const Unchecked = styled(UncheckedImage, {
  shouldForwardProp: (prop) => !['small'].includes(prop as string),
})<{small?: boolean}>`
  height: ${(p) => !p.small ? 32 : 25}px;
  width: ${(p) => !p.small ? 32 : 24}px;
`;
