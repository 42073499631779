import styled from 'styled-components';
import { theme } from '../../themes';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Pair = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const PairWithLabels = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const Text = styled.div<{marginTop?: number; marginBottom?: number}>`
  color: ${theme.colors.black};

  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  ${(p) => p.marginTop ? `
    margin-top: ${p.marginTop}px;
  ` : ''}

${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  ` : ''}
`;
