import MuiInput from '@mui/base/Input';
import { styled } from '@mui/system';
import { theme } from '../../themes';
import { ReactComponent as RemoveImage } from './images/remove.svg';
import { ReactComponent as SearchImage } from './images/search.svg';
import { ReactComponent as ErrorImage } from './images/error.svg';
import { ReactComponent as CopyImage } from './images/copy.svg';

export const Container = styled('div')<{width?: string; margin?: string}>`
  display: flex;
  flex-direction: column;
  position: relative;

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const InputStyle = styled(MuiInput, {
  shouldForwardProp: (prop) => !['active', 'errorIcon', 'flat', 'small', 'inline', 'centered', 'width'].includes(prop as string),
})<{active: boolean; error?: boolean; errorIcon?: boolean; disabled?: boolean; small?: boolean; flat?: boolean; inline?: boolean; centered?: boolean; width?: string}>`
  height: ${(p) => p.small ? 44 : 56}px;
  padding: ${(p) => `${p.small ? 6 : 22}px ${p.small ? 20 : 50}px 0 ${p.errorIcon ? 48 : 20}px`};
  border-radius: 12px;
  box-shadow: ${(p) => !p.flat ? '0px 2px 6px 0px #00000033' : 'none'};
  background: ${(p) => p.flat ? theme.colors.whiteSmoke : (p.inline ? 'none' : theme.colors.white)};
  color: ${(p) => p.disabled ? theme.colors.grayishBlue : theme.colors.black};
  overflow: hidden;
  transition: all 0.2s ease-out;

  ${(p) => p.width ? `
    width: ${p.width};
  ` : ''}

  > input {
    border: none;
    outline: none;
    width: 100%;

    font-family: ${theme.fonts.lato};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;

    background: ${(p) => p.flat ? theme.colors.whiteSmoke : 'none'};

    ::placeholder {
      color: ${theme.colors.veryLightGray};
    }

    ${(p) => p.centered ? `
      text-align: center;
    ` : ''}
  }

  ${(p) => {
    if (p.error) {
      return `border: 2px solid ${theme.colors.vividRed};`;
    } else if (p.active) {
      return `border: 2px solid ${theme.colors.grayishBlue};`;
    } else {
      return `border: 2px solid transparent;`;
    }
  }}


  ${(p) => p.inline ? `
    padding: 0;
    height: auto;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 2px solid ${theme.colors.veryLightGray};
    
    > input {
      border: none;
    }
  `: ''}
`;

const getPlaceholderTop = (p: { active: boolean, small?: boolean }) => {
  if (p.small) {
    return 8;
  } else if (p.active) {
    return 8;
  } else {
    return 17;
  }
};

export const Placeholder = styled('span')<{active: boolean; small?: boolean}>`
  position: absolute;
  top: ${(p) => getPlaceholderTop(p)}px;
  left: ${(p) => p.small ? 20 : 24}px;
  color: ${theme.colors.grayishBlue};

  font-family: ${theme.fonts.lato};
  font-size: ${(p) => p.active ? 12 : 18}px;
  font-weight: 500;
  line-height: ${(p) => p.active ? 18 : 27}px;
  letter-spacing: 0em;
  text-align: left;

  transition: all 0.2s ease-out;
`;

export const Error = styled('span')<{active: boolean}>`
  opacity: ${(p) => p.active ? 1 : 0};
  position: absolute;
  bottom: -14px;
  left: 24px;
  color: ${theme.colors.vividRed};

  font-family: ${theme.fonts.lato};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  transition: opacity 0.5s ease-out;
`;

export const Remove = styled(RemoveImage, {
  shouldForwardProp: (prop) => !['small'].includes(prop as string),
})<{small? :boolean}>`
  position: absolute;
  top: ${(p) => p.small ? 12 : 16}px;
  right: ${(p) => p.small ? 15 : 24}px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const SearchIcon = styled(SearchImage)`
  position: absolute;
  top: 20px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

export const SearchSmallIcon = styled(SearchImage)`
  position: absolute;
  top: 12px;
  right: 15px;
  width: 24px;
  height: 24px;
`;

export const ErrorIcon = styled(ErrorImage)`
  position: absolute;
  top: 26px;
  left: 24px;
  width: 24px;
  height: 24px;
`;

export const CopyIcon = styled(CopyImage)`
  position: absolute;
  top: 18px;
  right: 24px;
  width: 24px;
  height: 24px;
`;

export const CopySmallIcon = styled(CopyImage)`
  position: absolute;
  top: 12px;
  right: 15px;
  width: 24px;
  height: 24px;
`;

export const IconContainer = styled('span')<{clickable}>`
  ${(p) => p.clickable ? `cursor: pointer` : ''}
`;
