import styled from 'styled-components';
import { theme } from '../../themes';

export const Container = styled.div<{width?: number; itemGap?: number; noMargin?: boolean; disabled?: boolean; border?: boolean; rightAlignment?: boolean}>`
  display: flex;
  gap: ${(p) => p.itemGap || 12}px;

  ${(p) => !p.noMargin ? 'margin-top: 8px;' : ''}

  ${(p) => p.width ? `
    width: ${p.width}px;
    flex-wrap: wrap;
  ` : ''};

  ${(p) => p.disabled ? 'opacity: 0.3;' : ''}
  ${(p) => p.border ? `
    border: 1px solid ${theme.colors.grayishBlue};
    border-radius: 12px;
    padding: 4px;
  ` : ''}

  ${(p) => p.rightAlignment ? 'margin-left: auto;' : ''}
`;

export const Item = styled.div<{selected: boolean; iconWidth?: boolean; height?: number; itemWidth?: number; padding?: string; borderRadius?: number; disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  width: ${(p) => p.itemWidth || 72}px;

  ${(p) => p.disabled ? 'opacity: 0.3;' : ''}

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: ${(p) => p.padding || '12px'};
    border-radius: ${(p) => p.borderRadius || 12}px;

    ${(p) => p.height ? `height: ${p.height}px;` : ''}

    ${(p) => p.selected ? `
      border: 1.5px solid ${theme.colors.grayishBlue};
      background: white;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
    ` : `
      border: 1.5px solid ${theme.colors.grayishBlue}00;
    `}

    > img {
      ${(p) => p.iconWidth ? `
        width: ${p.iconWidth}px;
      ` : `
        width: 48px;
        height: 48px;
      `}
    }
  }

  > span {
    color: ${theme.colors.grayishBlue};
    font-family: ${theme.fonts.lato};
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
  }
`;
