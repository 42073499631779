/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Checkbox, Confirmation, Popup } from '../../..';
import { ExportType, PriceListItem, PriceListResponse, Shop } from '../../../../../../types';
import { setLoadingStatus, storePartBatch } from '../../../../redux/car';
import { firstUppercase } from '../../../../shared';
import { Buttons, Label } from '../../style';
import { exportToAvito } from '../../../../shared/avito/export';
import { exportToExcel } from './excel';
import { processResponse } from '../../../../../../shared';

const selectionTypes = ['selected', 'page', 'all', 'previous'] as const;
type SelectionType = typeof selectionTypes[number];

const addTypes = ['add', 'skip', 'remove'] as const;
type AddType = typeof addTypes[number];
const addTypesLabels: Record<AddType, string> = {
  'add': "Add new items to export, save and export everything",
  'skip': "Export only selected items, keep export unchanged",
  'remove': "Export only selected items and save them as a new export",
};

const labels: Record<SelectionType, string> = {
  'page': 'Export N from page',
  'selected': 'Export N',
  'all': 'Export N',
  'previous': 'Repeat previous export N',
};

interface Props {
  selectedPartIds: number[];
  pageIds: number[];
  exportType: ExportType;
  shop?: Shop;
  onSuccess?: () => void;
  onClose: () => void;
}

export const ExportDialog = ({ selectedPartIds, pageIds, exportType, shop, onClose, onSuccess }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectionType, setSelectionType] = useState<SelectionType>(selectionTypes[selectedPartIds.length > 0 ? 0 : 1]);
  const [addType, setAddType] = useState<AddType>(addTypes[0]);
  const [prevExportIds, setPrevExportIds] = useState<number[] | null>();
  const [totalParts, setTotalParts] = useState(0);
  const [confirmationText, setConfirmationText] = useState<string | null>();
  const [resultText, setResultText] = useState<string | null>();

  const counters = useMemo(() => ({
    'page': pageIds.length,
    'selected': selectedPartIds?.length || 0,
    'all': totalParts,
    'previous': prevExportIds?.length || 0,
  }) as Record<SelectionType, number>, [pageIds.length, prevExportIds, selectedPartIds?.length, totalParts]);

  const countToExport = useMemo(() => {
    const addIds = addType === 'add' ? (prevExportIds || []) : [];

    if (selectionType === 'all') {
      return totalParts;
    } else if (selectionType === 'previous') {
      return (prevExportIds || []).length;
    } else if (selectionType === 'page') {
      return Array.from(new Set([...pageIds, ...addIds])).length;
    } else if (selectionType === 'selected') {
      return Array.from(new Set([...selectedPartIds, ...addIds])).length;
    }
  }, [addType, pageIds, prevExportIds, selectedPartIds, selectionType, totalParts]);

  useEffect(() => {
    fetch('/api/storage/part/list?mode=total')
      .then(processResponse)
      .then((res) => setTotalParts(res.total))
      .catch((error) => console.error(error));

    fetch('/api/storage/part/list?filter=isExportAvito=true')
      .then(processResponse)
      .then((res) => setPrevExportIds(res.items?.map?.((item: PriceListItem) => item.id)))
      .catch((error) => console.error(error));
  }, []);

  const exportData = useCallback(() => {
    if (!shop) {
      return;
    }

    (async () => {
      dispatch(setLoadingStatus('loading'));

      try {
        let query = '';
        const addIds = addType === 'add' ? (prevExportIds || []) : [];

        if (selectionType === 'page') {
          query = `filter=id=in(${Array.from(new Set([...pageIds, ...addIds])).join(',')})`;
        } else if (selectionType === 'selected') {
          query = `filter=id=in(${Array.from(new Set([...selectedPartIds, ...addIds])).join(',')})`;
        } else if (selectionType === 'previous') {
          query = `filter=id=in(${prevExportIds || [].join(',')})`;
        }

        // TBD add fields here

        query = query !== '' ? `?${query}` : '';

        const parts = ((await fetch(`/api/storage/part/list${query}`).then(processResponse)) as PriceListResponse)?.items;

        if (exportType === 'avito') {
          exportToAvito(parts, shop);
        } else if (exportType === 'excel') {
          exportToExcel(parts, t);
        }

        dispatch(setLoadingStatus('done'));

        if (addType === 'remove') {
          const partsAdd = ((await fetch('/api/storage/part/list?filter=isExportAvito=true').then(processResponse)) as PriceListResponse)?.items;
          dispatch(storePartBatch({ isExportAvito: false }, partsAdd.map(({ id }) => id!.toString())));
        }

        dispatch(storePartBatch({ isExportAvito: true }, parts.map(({ id }) => id!.toString())));
        setConfirmationText(null);
        onSuccess?.();
        setResultText(t('Exported N items', { count: parts.length }));
      } catch (error) {
        console.error(error);
      }
    })();
  }, [shop, addType, prevExportIds, selectionType, exportType, pageIds, selectedPartIds, dispatch, onSuccess, t]);

  return <>
    {resultText && <Confirmation
      title={t('Export successfull')}
      text={resultText}
      buttons={[{
        label: 'Ok',
        onClick: () => onClose(),
      }]}
      onClose={() => onClose()}
    />}
    {!confirmationText && !resultText && <Popup width='700px' onClose={onClose}>
      <Label bold margin='0 0 24px 0'>{t(`Export to ${firstUppercase(exportType)}`)}</Label>
      <Label margin='0 0 24px 0'>{t('Export items')}</Label>
      {selectionTypes.filter((type) => (selectedPartIds.length > 0 || type !== 'selected') && ((prevExportIds?.length || 0) > 0 || type !== 'previous'))
        .map((type, i) => <Checkbox
          key={i}
          value={selectionType === type}
          label={t(labels[type], { count: counters[type] })}
          onChange={() => setSelectionType(type)}
        />)}
      {(prevExportIds?.length || 0) > 0 && <>
        <Label margin='30px 0 24px 0'>{t('Previously, N items exported', { count: (prevExportIds?.length || 0) })}</Label>
        {addTypes.map((type, i) => <Checkbox key={i} value={addType === type} label={addTypesLabels[type]} onChange={() => setAddType(type)} />)}
      </>}
      <Buttons margin='24px 0 0 0'>
        <Button onClick={() => onClose()} secondary>Cancel</Button>
        <Button onClick={() => {
          if (addType !== 'skip') {
            setConfirmationText(t('N parts will be exported and saved for re-export. OK?', { count: countToExport }));
          } else {
            setConfirmationText(t('N parts will be exported without adding them to the re-export list. OK?', { count: countToExport }));
          }
        }}>Export</Button>
      </Buttons>
    </Popup>}
    {confirmationText && <Confirmation
      title={t(`Export to ${firstUppercase(exportType)}`)}
      text={confirmationText}
      buttons={[{
        label: 'Back',
        secondary: true,
        onClick: () => setConfirmationText(null),
      }, {
        label: 'Confirm',
        onClick: () => exportData(),
      }]}
      onClose={() => setConfirmationText(null)}
    />}
  </>;
};
