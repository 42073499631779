import TagManager from 'react-gtm-module';

interface Event {
  [name: string]: string | number;
}

export const sendGTMEvent = (name: string, event: Event) => {
  console.log({ event });

  TagManager.dataLayer({ dataLayerName: name, dataLayer: event });
};
