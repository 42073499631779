export const menuItems = [{
  title: 'Parts',
  link: '/parts',
}, {
  title: 'Cars',
  link: '/cars',
}, {
  title: 'Add car',
  link: '/add-car',
}, {
  title: 'Users',
  link: '/users',
  isAdmin: true,
}];

export const menuItemsFooter = [{
  title: 'Blog',
  link: 'https://reencar.com/blog',
}];
