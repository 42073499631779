import { styled as styledMui } from '@mui/system';
import MuiButton from '@mui/base/Button';
import { theme } from '../../themes';

export const ButtonStyle = styledMui(MuiButton, {
  shouldForwardProp: (prop) => !['iconLeft', 'iconRight', 'wide', 'secondary', 'minWidth', 'noIconMargin'].includes(prop as string),
})<{width?: string; minWidth?: string; margin?: string; disabled?: boolean; wide?: boolean; iconLeft: boolean; iconRight: boolean; secondary?: boolean; noIconMargin?: boolean}>`
  height: ${(p) => p.wide ? 56 : 44}px;
  width: ${(p) => p.width || 'max-content'};
  border-radius: 12px;
  color: ${(p) => p.secondary ? theme.colors.vividOrange : theme.colors.white};
  padding: ${(p) => p.wide ? 14.5 : 8.5}px 24px;
  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  border: 0;
  -webkit-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-shadow: 0px 2px 6px 0px #00000033;

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
  ${(p) => p.minWidth ? `min-width: ${p.minWidth};` : ''}

  ${(p) => p.disabled ? `
    background: ${theme.colors.veryLightGray};
  ` : `
    cursor: pointer;

    background: ${p.secondary ? theme.colors.veryPaleOrange : theme.colors.vividOrange};

    :hover {
      background: ${p.secondary ? theme.colors.veryPaleOrange : theme.colors.vividRed};
      color: ${p.secondary ? theme.colors.vividRed : theme.colors.white};
    }

    :active {
      background: ${p.secondary ? theme.colors.veryPaleOrange : theme.colors.strongOrange};
    }
  `}

  > svg {
    width: 24px;
    height: 24px;
    vertical-align: text-top;
    
    path {
      fill: white;
    }

    ${(p) => p.iconLeft && !p.noIconMargin ? `
      :first-of-type {
        margin-right: 10px;
      }
    ` : ''}

    ${(p) => p.iconRight && !p.noIconMargin ? `
      :last-of-type {
        margin-left: 10px;
      }
    ` : ''}
  }

  ${(p) => (p.iconLeft || p.iconRight) && p.noIconMargin ? `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  ` : ''}
`;
