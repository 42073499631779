import React, { useRef } from 'react';
import { Photo, PhotoIcon } from './style';

interface Props {
  src?: string;
  setImage: (file: File[]) => void;
}

export const Image = ({ src, setImage }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return <>
    <input
      type='file'
      accept='image/*'
      onChange={(event) => {
        const data = Array.from((event.target?.files || []));
        setImage(data);
      }}
      style={{ display: 'none' }}
      ref={fileInputRef}
    />
    {src && <Photo src={src} onClick={() => fileInputRef.current?.click?.()} />}
    {!src && <PhotoIcon onClick={() => fileInputRef.current?.click?.()} />}
  </>;
};
