import { Menu } from '@mui/material';
import styled from 'styled-components';

export const StyledMenu = styled(Menu)<{width?: number}>`
  & .MuiPaper-root {
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    ${(p) => p.width ? `width: ${p.width}px;` : ''}
    margin-top: 10px;
  }
`;
