import { setConfig, setConfigStatus, setExports, setShop, setUsers, setUsersLoadStatus } from '.';
import { postOptions, processResponse } from '../../../../shared';
import { Config, Export, Shop, User } from '../../../../types';
import { clearExport, clearUser } from '../../shared';
import { AppThunk } from '../store';

export const loadConfig = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setConfigStatus('load'));

    const config = await fetch(`${window.location.origin}/api/config`, { method: 'get' }).then((res) => {
      if (!res?.ok) {
        throw new Error(res?.statusText);
      } else {
        return res?.json();
      }
    }) as Config | { error: string };

    if ( 'error' in config ) {
      dispatch(setConfigStatus('error'));
    } else {
      dispatch(setConfig(config));
      dispatch(setConfigStatus('success'));
    }
  } catch (error) {
    console.error(error);
    dispatch(setConfigStatus('error'));
  }
};

export const sendTelegramMessage = (text: string): AppThunk => async () => {
  try {
    await fetch(`${window.location.origin}/api/telegram-message`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ text }),
    }).then((res) => {
      if (!res?.ok) {
        throw new Error(res?.statusText);
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const loadUsers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setUsersLoadStatus('load'));

    const users = await fetch(`${window.location.origin}/api/user/list`, { method: 'get' }).then((res) => {
      if (!res?.ok) {
        throw new Error(res?.statusText);
      } else {
        return res?.json();
      }
    }) as User[] | { error: string };

    if ( 'error' in users ) {
      console.error(users.error);
      dispatch(setUsersLoadStatus('error'));
    } else {
      dispatch(setUsers(users));
      dispatch(setUsersLoadStatus('success'));
    }
  } catch (error) {
    console.error(error);
    dispatch(setUsersLoadStatus('error'));
  }
};

export const storeUser = (user: Partial<User>, id: string, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    user = clearUser(user);
    const users = getState().App.users;

    await fetch(`/api/user/${id}`, { ...postOptions, body: JSON.stringify(user) }).then(processResponse);

    dispatch(setUsers(users.map((item) => item.id === id ? { ...item, ...user } : item)));
    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const storeExport = (data: Export, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const exports = getState().App.exports;

    const { id, ...rest } = data;

    await fetch(`${window.location.origin}/api/storage/export${id ? `/${id}` : ''}`, { ...postOptions, body: JSON.stringify(clearExport(rest)) });

    if (id) {
      dispatch(setExports((exports || []).map((item) => item.id === id ? { ...item, rest } : item)));
    } else {
      dispatch(setExports([...(exports || []), rest]));
    }
    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const loadExports = (): AppThunk => async (dispatch) => {
  try {
    const exports = await fetch(`${window.location.origin}/api/storage/export/list`, { method: 'get' })
      .then(processResponse) as Export[] | { error: string };

    if ( 'error' in exports ) {
      console.error(exports.error);
    } else {
      dispatch(setExports(exports));
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteExport = (id: number): AppThunk => async (dispatch, getState) => {
  try {
    const exports = getState().App.exports;

    await fetch(`${window.location.origin}/api/storage/export/${id}`, { method: 'delete' }).then(processResponse);

    dispatch(setExports((exports || []).filter((item) => item.id !== id)));
  } catch (error) {
    console.error(error);
  }
};

export const storeShop = (data: Shop, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    await fetch(`${window.location.origin}/api/storage/shop`, { ...postOptions, body: JSON.stringify(data) });

    dispatch(setShop(data));
    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const loadShop = (): AppThunk => async (dispatch) => {
  try {
    const shop = await fetch(`${window.location.origin}/api/storage/shop`, { method: 'get' })
      .then(processResponse) as Shop | { error: string };

    if ( 'error' in shop ) {
      console.error(shop.error);
    } else {
      dispatch(setShop(shop));
    }
  } catch (error) {
    console.error(error);
  }
};

export const deleteShop = (id: number): AppThunk => async (dispatch, getState) => {
  try {
    await fetch(`${window.location.origin}/api/storage/shop`, { method: 'delete' }).then(processResponse);

    dispatch(setShop(null));
  } catch (error) {
    console.error(error);
  }
};
