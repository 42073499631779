export const theme = {
  colors: {
    primary: '#fb8a00',
    secondary: '#747474',
    back: '#f4f4f4',

    black: '#1c1b1f',
    grayishBlue: '#a1a5b7',
    veryLightGray: '#e6e6e6',
    strongOrange: '#c5511a',
    vividOrange: '#f16522',
    vividRed: '#ff4105',
    white: '#ffffff',
    veryPaleOrange: '#fffdfa',
    whiteSmoke: '#f5f5f5',
  },
  fonts: {
    lato: 'Lato, Arial',
  },
};
