import { setCar, setCarInfo, setCars, setCarsAll, setCarsAllTotal, setCarsShort, setCarsTotal, setLoadingStatus, setNodes, setPriceList, setPriceListTotal } from '..';
import { postOptions, processResponse } from '../../../../../shared';
import { Car, CarShort, CarShortStorage, Node, PriceListItem } from '../../../../../types';
import { ListOptions, clearCar, clearNodes, clearPart, clearPriceList, genListQuery } from '../../../shared';
import { AppThunk } from '../../store';

export const saveData = (callback?: (carId: number) => void): AppThunk => async (dispatch, getState) => {
  const { id, ...car } = clearCar(getState().Car.car);
  const carInfo = getState().Car.carInfo;
  const nodes = clearNodes(getState().Car.nodes);
  const priceList = clearPriceList(getState().Car.priceList).map(({ carId, ...rest }) => rest);

  try {
    dispatch(setLoadingStatus('loading'));

    const resCar = await fetch(`/api/storage/car/${id || ''}`, { ...postOptions, body: JSON.stringify({ car, carInfo }) })
      .then(processResponse);

    const carId = resCar.id;

    const resNodes = await fetch('/api/storage/nodes', { ...postOptions, body: JSON.stringify({ carId, nodes }) })
      .then(processResponse);

    const nodesId = resNodes.id;

    await fetch(`/api/storage/part/car`, { ...postOptions, body: JSON.stringify({ carId, nodesId, priceList }) })
      .then(processResponse);

    callback?.(carId);
    dispatch(setCar({ ...car, id: carId }));
    dispatch(setLoadingStatus('done'));
  } catch (error) {
    console.error(error);
    dispatch(setLoadingStatus('error'));
  }
};

export const saveCarPriceList = (nodesId: string, callback?: () => void): AppThunk => async (dispatch, getState) => {
  const carId = getState().Car.car?.id;
  const priceList = clearPriceList(getState().Car.priceList).map(({ carId, ...rest }) => rest);

  try {
    dispatch(setLoadingStatus('loading'));

    await fetch(`/api/storage/part/car`, { ...postOptions, body: JSON.stringify({ carId, nodesId, priceList }) })
      .then(processResponse);

    callback?.();
    dispatch(setLoadingStatus('done'));
  } catch (error) {
    console.error(error);
    dispatch(setLoadingStatus('error'));
  }
};

export const loadCar = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await fetch(`/api/storage/car/${id}`).then(processResponse);

    const { carInfo, ...car } = response;

    dispatch(setCar(car));
    dispatch(setCarInfo(carInfo));
  } catch (error) {
    console.error(error);
  }
};

interface CarResponse {
  total: number;
  items: Car[];
}

interface CarShortResponse {
  total: number;
  items: CarShortStorage[];
}

export const loadCars = (options: ListOptions, short?: boolean): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoadingStatus('loading'));

    const user = getState().Auth.user;

    if (!user) {
      dispatch(setLoadingStatus('error'));
      dispatch(setCarsShort([]));
      dispatch(setCarsAllTotal(0));
      dispatch(setCarsAll([]));
      dispatch(setCarsTotal(0));
      dispatch(setCars([]));
      return;
    }

    const query = genListQuery(options);
    const all = options.all ? `/all` : '';
    const response: CarResponse = await fetch(`/api/storage/car/list${all}${query ? `?${query}` : ''}`).then(processResponse);

    if (short) {
      const mapFunction = ({ id, brandName, modelName, year }: CarShortStorage) => ({ id, brand: brandName, carName: `${brandName || ''} ${modelName || ''} ${year || ''}`.trim() });
      const items = (response as CarShortResponse).items.map(mapFunction) as CarShort[];
      dispatch(setCarsShort(items));
    } else if (options.all) {
      dispatch(setCarsAllTotal(response.total));
      dispatch(setCarsAll(response.items));
    } else {
      dispatch(setCarsTotal(response.total));
      dispatch(setCars(response.items));
    }
    dispatch(setLoadingStatus('done'));
  } catch (error) {
    console.error(error);
    dispatch(setLoadingStatus('error'));
  }
};

interface PriceListResponse {
  total: number;
  items: PriceListItem[];
}

export const loadPriceList = (options: ListOptions, carId?: number): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setLoadingStatus('loading'));
    const user = getState().Auth.user;

    if (!user) {
      dispatch(setLoadingStatus('error'));
      return;
    }

    const query = genListQuery(options);
    const response: PriceListResponse = await fetch(`/api/storage/part/list${carId ? `/${carId}` : ''}?${query}`).then(processResponse);

    dispatch(setPriceList(response.items));
    dispatch(setPriceListTotal(response.total));
    dispatch(setLoadingStatus('done'));
  } catch (error) {
    console.error(error);
    dispatch(setLoadingStatus('error'));
  }
};

export const storePart = (part: Omit<PriceListItem, 'id'>, id?: number): AppThunk => async (dispatch, getState) => {
  try {
    const priceList = getState().Car.priceList;

    // TBD: this should happen earlier
    if (part.oems) {
      part.oems = part.oems.map(({ quantity, ...item }) => ({ ...item, quantity: quantity ? +quantity : 0 }));
    }

    const response = await fetch(`/api/storage/part${id !== undefined ? `/${id}` : ''}`, { ...postOptions, body: JSON.stringify(clearPart(part)) }).then(processResponse);

    const newPriceList = id !== undefined ? (priceList || []).map((item) => item.id === id ? { ...item, ...part } : item) : [{ ...part, id: response.id }, ...(priceList || [])];

    dispatch(setPriceList(newPriceList));
  } catch (error) {
    console.error(error);
  }
};

export const storeCar = (callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const data = clearCar(getState().Car.car);

    if (!data) {
      return;
    }

    const { id, ...car } = data;

    await fetch(`/api/storage/car/${id}`, { ...postOptions, body: JSON.stringify({ car }) }).then(processResponse);
    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const storePartBatch = (part: Partial<PriceListItem>, ids: string[]): AppThunk => async (dispatch, getState) => {
  try {
    // TBD: this should happen earlier
    if (part.oems) {
      part.oems = part.oems.map(({ quantity, ...item }) => ({ ...item, quantity: quantity ? +quantity : 0 }));
    }

    await fetch(`/api/storage/part/batch`, { ...postOptions, body: JSON.stringify({ ...clearPart(part), ids }) }).then(processResponse);
  } catch (error) {
    console.error(error);
  }
};

export const deletePart = (id: number, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const priceList = getState().Car.priceList;

    await fetch(`/api/storage/part/${id}`, { method: 'delete' }).then(processResponse);

    dispatch(setPriceList((priceList || []).filter((item) => item.id !== id)));

    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const deleteCar = (id: number, callback?: () => void): AppThunk => async (dispatch, getState) => {
  try {
    const cars = getState().Car.cars;

    await fetch(`/api/storage/car/${id}`, { method: 'delete' }).then(processResponse);

    dispatch(setCars((cars || []).filter((item) => item.id !== id)));

    callback?.();
  } catch (error) {
    console.error(error);
  }
};

export const getNodes = (id: string, callback?: (nodes: Node[]) => void): AppThunk => async (dispatch, getState) => {
  const res = await fetch(`/api/storage/nodes/${id}`, { method: 'get' })
    .then(processResponse) as { nodes: Node[] };

  dispatch(setNodes(res.nodes));
  callback?.(res.nodes);
};
