import styled from 'styled-components';
import { theme } from '../../../../themes';
import { ReactComponent as LogoImage } from './images/logo.svg';
import { ReactComponent as AccountImage } from './images/account.svg';

export const Container = styled.div<{noMargin?: boolean; small?: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: absolute;
  top: 0;
  left: 0;

  padding: ${(p) => p.small ? 6 : 32}px 0;
  margin: 0 auto ${(p) => p.noMargin ? 0 : 40}px;
  width: 100vw;
  max-width: 100vw;

  transition: all 0.2s ease-out;

  ${(p) => p.small ? `
    z-index: 100;
    position: fixed;
    background: ${theme.colors.veryLightGray};
  ` : ''}

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1424px;
    margin: 0 auto;
    
    > div {
      display: flex;
      align-items: center;
    }
  }
`;

export const DummyHeader = styled.div`
  height: 150px;
`;

export const Account = styled(AccountImage)`
  height: 36px;
  width: 36px;

  cursor: pointer;
`;

export const Logo = styled(LogoImage).withConfig({
  shouldForwardProp: (prop) => !['small'].includes(prop as string),
})<{small?: boolean}>`
  height: ${(p) => p.small ? 40 : 85}px;
  width: ${(p) => p.small ? 150 : 318}px;

  cursor: pointer;
`;
