import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  gap: 12px;
  height: 100%;

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 600px;
  }
`;

export const Row = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => p.gap || 16}px;
`;
