import React from 'react';
import { DismantlingType } from '../../../../types';
import { ReactComponent as ApartImage } from './images/apart.svg';
import { ReactComponent as MissingImage } from './images/missing.svg';
import { ReactComponent as UncheckedImage } from './images/unchecked.svg';
import { ReactComponent as WholeImage } from './images/whole.svg';
import { Container } from './style';
import { useTranslation } from 'react-i18next';

const mapping: Record<DismantlingType, React.ReactNode> = {
  'whole': <WholeImage />,
  'apart': <ApartImage />,
  'missing': <MissingImage />,
};

const nextValue: Record<DismantlingType, DismantlingType> = {
  'whole': 'apart',
  'apart': 'missing',
  'missing': 'whole',
};

interface Props {
  value?: DismantlingType;
  label?: string;
  disabled?: boolean;
  onChange?: (value: DismantlingType) => void;
}

export const DismanltingTypeSelect = ({ value, label, disabled, onChange }: Props) => {
  const { t } = useTranslation();

  return <Container
    disabled={disabled}
    onClick={!disabled ? () => value ? onChange?.(nextValue[value]) : onChange?.('whole') : undefined}
  >
    {value ? mapping[value] : <UncheckedImage />}
    {label && <div title={t(label)}>{t(label)}</div>}
  </Container>;
};
