import { styled } from '@mui/system';
import { theme } from '../../themes';

export const Container = styled('div')<{disabled?: boolean}>`
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    height: 24px;
    width: 25px;
    min-width: 25px;
  }

  > div {
    margin-left: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }

  ${(p) => p.disabled ? `
    opacity: 0.3;

    svg {
      rect {
        stroke: ${theme.colors.veryLightGray};
      }
    }
  ` : ''}
`;
