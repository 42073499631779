import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { debounce } from '../../shared';
import { Container, Error, ErrorIcon, InputStyle, Placeholder, Remove, SearchIcon, SearchSmallIcon, CopyIcon, CopySmallIcon, IconContainer } from './style';

type IconType = 'search' | 'copy';

const icons: Record<IconType, ReactNode> = {
  search: <SearchIcon />,
  copy: <CopyIcon />,
};

const iconsSmall = {
  search: <SearchSmallIcon className='icon' />,
  copy: <CopySmallIcon className='icon' />,
};

interface Props {
  placeholder?: string;
  value?: string | number;
  error?: string | boolean;
  errorIcon?: boolean;
  width?: string;
  margin?: string;
  icon?: IconType;
  inputPlaceholder?: string;
  disabled?: boolean;
  small?: boolean;
  flat?: boolean;
  inline?: boolean;
  centered?: boolean;
  onChange?: (value: string) => void;
  onClickIcon?: () => void;
}

export const Input = ({ placeholder, width, value, error, errorIcon, margin, icon,
  inputPlaceholder, disabled, small, flat, inline, centered, onChange, onClickIcon }: Props) => {

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState(value);
  const [focused, setFocused] = useState(false);

  const debouncedOnChange = useMemo(() => onChange ? debounce(onChange, 500) : undefined, [onChange]);

  const setValue = useCallback((e) => {
    setInputValue(e.target?.value);
    debouncedOnChange?.(e.target?.value);
  }, [debouncedOnChange]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const active = useMemo(() => focused || (value || '') !== '', [focused, value]);

  return <Container width={width} margin={margin}>
    <div className={(!!error || errorIcon) ? 'input-error' : undefined} />
    <InputStyle
      ref={inputRef}
      active={active}
      value={inputValue || ''}
      error={!!error || errorIcon}
      errorIcon={errorIcon}
      disabled={disabled}
      small={small}
      flat={flat}
      inline={inline}
      centered={centered}
      width={width}
      placeholder={active && inputPlaceholder ? t(inputPlaceholder) : undefined}
      onChange={setValue}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    />
    {!(small && active && !inline) && <Placeholder
      active={active}
      small={small}
      onClick={() => inputRef?.current?.querySelector?.('input')?.focus()}
    >{placeholder ? t(placeholder) : ''}</Placeholder>}
    {inputValue !== '' && !disabled && !inline && <Remove small={small} onClick={() => setValue('')} />}
    {(inputValue === '' || !!onClickIcon) && icon && <IconContainer onClick={() => onClickIcon?.()} clickable={!!onClickIcon}>{small ? iconsSmall[icon] : icons[icon]}</IconContainer>}
    {errorIcon && <ErrorIcon />}
    <Error active={!!error}>{error && typeof error === 'string' ? t(error) : ''}</Error>
  </Container>;
};
