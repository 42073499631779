import React from 'react';
import { useTranslation } from 'react-i18next';
import { PartSide, PositionCenter, PositionFrontRear, PositionLeftRight, PositionUpperLower } from '../../../../types';
import { BodyType, Doors, Steering, Tailgate } from '../../types';
import { ItemBody, ItemCenter, ItemDoors, ItemFrontRear, ItemLeftRight, items, ItemSide, ItemSteering, ItemTailgate, ItemUpperLower } from './items';
import { Container, Item } from './style';

const containerWidth = {
  body: 408,
  tailgate: 156,
  'door-side': 222,
  'wheel-side': 222,
};

const iconWidth = {
  body: 60,
  tailgate: 50,
  'door-side': 32,
  'wheel-side': 32,
  'left-right': 24,
  'front-rear': 63,
  'upper-lower': 63,
  'center': 63,
};

const itemGap = {
  'door-side': 8,
  'wheel-side': 8,
};

const itemWidth = {
  'door-side': 38,
  'wheel-side': 38,
};

const height = {
  body: 40,
  tailgate: 40,
  'door-side': 32,
  'wheel-side': 32,
};

const padding = {
  'door-side': '0 3px',
  'wheel-side': '0 3px',
  'left-right': '0 6px',
  'front-rear': '6px',
  'upper-lower': '6px',
  'center': '6px',
};

const borderRadius = {
  'door-side': 4,
  'wheel-side': 4,
};

const noMargin = new Set(['door-side', 'wheel-side']);

interface PropsBase {
  width?: number;
  disabled?: boolean;
  border?: boolean;
  rightAlignment?: boolean;
  setSelected: (value: string | null) => void;
}
interface PropsDoors extends PropsBase {
  type: 'doors';
  selected?: Doors;
  disabledItems?: Doors[];
}

interface PropsSteering extends PropsBase {
  type: 'steering';
  selected?: Steering;
  disabledItems?: Steering[];
}

interface PropsBody extends PropsBase {
  type: 'body';
  selected?: BodyType;
  disabledItems?: BodyType[];
}

interface PropsTailgate extends PropsBase {
  type: 'tailgate';
  selected?: Tailgate;
  disabledItems?: Tailgate[];
}

interface PropsDoorSide extends PropsBase {
  type: 'door-side';
  selected?: PartSide;
  disabledItems?: PartSide[];
}

interface PropsWheelSide extends PropsBase {
  type: 'wheel-side';
  selected?: PartSide;
  disabledItems?: PartSide[];
}

interface PropsLeftRight extends PropsBase {
  type: 'left-right';
  selected?: PositionLeftRight;
  disabledItems?: PositionLeftRight[];
}

interface PropsFrontRear extends PropsBase {
  type: 'front-rear';
  selected?: PositionFrontRear;
  disabledItems?: PositionFrontRear[];
}

interface PropsUpperLower extends PropsBase {
  type: 'upper-lower';
  selected?: PositionUpperLower;
  disabledItems?: PositionUpperLower[];
}

interface PropsCenter extends PropsBase {
  type: 'center';
  selected?: PositionCenter;
  disabledItems?: PositionCenter[];
}

type Props = PropsDoors | PropsSteering | PropsBody | PropsTailgate | PropsDoorSide | PropsWheelSide | PropsLeftRight | PropsFrontRear | PropsUpperLower | PropsCenter;

export const Selector = ({ type, selected, disabled, disabledItems, border, rightAlignment, setSelected }: Props) => {
  const { t } = useTranslation();

  return <Container width={containerWidth[type]} itemGap={itemGap[type]} noMargin={noMargin.has(type)} disabled={disabled} border={border} rightAlignment={rightAlignment}>
    {(items?.[type] || []).map((item: ItemDoors | ItemSteering | ItemBody | ItemTailgate | ItemSide | ItemLeftRight | ItemFrontRear | ItemUpperLower | ItemCenter, i: number) =>
      <Item
        key={i}
        iconWidth={iconWidth[type]}
        itemWidth={itemWidth[type]}
        height={height[type]}
        padding={padding[type]}
        borderRadius={borderRadius[type]}
        selected={item.value === selected}
        disabled={(disabledItems as string[])?.includes(item.value)}
        onClick={!disabled && !(disabledItems as string[])?.includes(item.value) ? () => setSelected(item.value !== selected ? item.value : null) : undefined}
      >
        <div>
          <img src={item.image} />
        </div>
        {item.label && <span>{t(item.label)}</span>}
      </Item>)}
  </Container>;
};
