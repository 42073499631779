export const userRoles = ['admin', 'user'] as const;

export type UserRole = typeof userRoles[number];

export const userFields = ['name', 'surname', 'email', 'phone', 'role'];

export interface User {
  id?: string;
  createdAt?: string;
  name?: string;
  surname?: string;
  lastLogin?: string;
  loginsCount?: number;
  email?: string;
  phone?: string;
  role?: UserRole;
}

export interface InitResponse {
  user: User;
}