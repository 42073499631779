// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends any[]>(callback: (...args: T) => void, delay: number): (...args: T) => void => {
  let timeoutId: NodeJS.Timeout;

  return (...args: T) => {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      callback(...args);
    }, delay);
  };
};

