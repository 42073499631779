import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, Dropdown } from '../../../../components';
import { CarInfo } from '../../../../types';
import { Buttons, Title } from '../../style';
import { Content } from './style';

interface Props {
  cars: CarInfo[];
  onSelect: (value: number) => void;
  onClose: () => void;
}

export const PopupSelectCar = ({ cars, onSelect, onClose }: Props) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<{[field: string]: string}>({});
  const [properCars, setProperCars] = useState<boolean[]>(cars.map((_) => true));

  const { values, differentFields } = useMemo(() => {
    const values: Array<{ [field: string]: string }> = [];
    const differentFields: string[] = [];

    const fields = Array.from(new Set(cars.reduce<string[]>((acc, cur) => {
      const carValues = {};

      const names = cur.map(({ code, value }) => {
        carValues[code] = value;
        return code;
      });

      values.push(carValues);
      return [...acc, ...names];
    }, [])));

    fields.forEach((field, i) => {
      const set = new Set(Object.values(values).map((values) => values[field]));

      if (set.size > 1) {
        differentFields.push(field);
      }
    });

    if (differentFields.length === 0) {
      onSelect(0);
    }

    return { values, differentFields };
  }, [cars, onSelect]);

  return <Popup onClose={onClose}>
    <Title>{t('Please enter car parameters')}</Title>
    <Title small margin='20px 0 0'>{t('We found several cars, need additional info')}</Title>
    <Content>
      {differentFields.map((field, i) => <Dropdown
        key={i}
        width='100%'
        placeholder={field}
        value={selected[field] || ''}
        options={Array.from(new Set(values.filter((_, i) => properCars[i]).map((data) => data[field]))).filter((value) => value !== undefined).map((value) => ({ label: value, value }))}
        maxHeight={358}
        onChange={(value) => {
          const newSelected = { ...selected, [field]: value };

          const result = cars.map((_) => true);

          Object.entries(newSelected).forEach(([key, value]) => {
            cars.forEach((car, i) => {
              if (car.find(({ code }) => code === key)?.value !== value && value !== '') {
                result[i] = false;
              }
            });
          });

          setProperCars(result);

          if (result.filter((value) => value).length === 1 && !!value) {
            const carIndex = result.findIndex((value) => value);

            differentFields.filter((field) => !newSelected[field]).forEach((field) => {
              const value = values[carIndex][field];

              if (value) {
                newSelected[field] = value;
              }
            });
          }

          setSelected(newSelected);
        }}
      />)}
    </Content>
    <Buttons>
      <Button secondary onClick={() => onClose()}>Cancel</Button>
      <Button disabled={properCars.filter((value) => value).length > 1} onClick={() => onSelect(properCars.findIndex((value) => value))}>Accept</Button>
    </Buttons>
  </Popup>;
};
