import styled from 'styled-components';
import { theme } from '../../themes';
import { ReactComponent as CloseImage } from './images/close.svg';

const padding = 30;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const Content = styled.div<{width?: string; height?: string}>`
  position: relative;
  background-color: white;
  padding: ${padding}px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: calc(100vh - 50px);
  max-width: calc(100vw - 50px);

  ${(p) => p.width ? `width: calc(${p.width} + ${padding * 2}px);` : ''}
  ${(p) => p.height ? `height: calc(${p.height} + ${padding * 2}px);` : ''}
`;

export const Close = styled(CloseImage)`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Label = styled.div<{width?: string; margin?: string; bold?: boolean}>`
  color: ${theme.colors.black};
  font-family: ${theme.fonts.lato};
  font-size: ${(p) => p.bold ? 22 : 18}px;
  font-weight: ${(p) => p.bold ? 700 : 500};
  line-height: ${(p) => p.bold ? 32 : 27}px;
  letter-spacing: 0em;
  text-align: left;

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const Title = styled.div<{width?: string; margin?: string; small?: boolean}>`
  color: ${theme.colors.black};
  font-family: ${theme.fonts.lato};
  ${(p) => !p.small ? `
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  `: `
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
  `}

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const Buttons = styled.div<{margin?: string}>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const ButtonsWide = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;

  padding-bottom: 1px;
  
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const Pair = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const Column = styled.div<{gap?: number}>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.gap || 12}px;
`;
