import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Dropdown, Input } from '../../../../..';
import { AvitoData, bodySparePartTypeList, engineSparePartTypeList, goodsTypeList, sparePartTypeList, transmissionSparePartTypeList } from '../../../../../../../../types';
import { Content, Row } from './style';
import { Divider } from '../../style';
import { Label } from '../../../../style';

interface Props {
  isExport?: boolean;
  avitoData?: AvitoData
  setIsExport: (value: boolean) => void;
  setAvitoData: (data: Partial<AvitoData>) => void;
}

const sparePartTypeOptions = sparePartTypeList.map((value) => ({ value, label: value }));
const goodsTypeOptions = goodsTypeList.map((value) => ({ value, label: value }));
const bodySparePartTypeOptions = bodySparePartTypeList.map((value) => ({ value, label: value }));
const engineSparePartTypeOptions = engineSparePartTypeList.map((value) => ({ value, label: value }));
const transmissionSparePartTypeOptions = transmissionSparePartTypeList.map((value) => ({ value, label: value }));

export const PartExport = ({ isExport, avitoData, setIsExport, setAvitoData }: Props) => {
  const { t } = useTranslation();

  return <Content>
    <div>
      <Row>
        <Label>{t('Avito')}</Label>
        <Checkbox
          value={isExport || false}
          label='Export to Avito'
          onChange={(value) => setIsExport(value)}
          noPadding
          small
        />
      </Row>
      <Input
        width='100%'
        placeholder='Avito ID'
        value={avitoData?.id?.toString?.()}
        onChange={(id) => setAvitoData({ id })}
      />
      <Input
        width='100%'
        placeholder='Avito Custom ID'
        value={avitoData?.customId?.toString?.()}
        onChange={(customId) => setAvitoData({ customId })}
      />
      <Dropdown
        width='100%'
        placeholder='GoodsType'
        value={avitoData?.GoodsType}
        options={goodsTypeOptions}
        onChange={(GoodsType) => setAvitoData({ GoodsType })}
        search={goodsTypeOptions.length > 10}
      />
      <Dropdown
        width='100%'
        placeholder='SparePartType'
        value={avitoData?.SparePartType}
        options={sparePartTypeOptions}
        onChange={(SparePartType) => setAvitoData({ SparePartType })}
        search={sparePartTypeOptions.length > 10}
      />
      <Dropdown
        width='100%'
        placeholder='BodySparePartType'
        value={avitoData?.BodySparePartType}
        options={bodySparePartTypeOptions}
        onChange={(BodySparePartType) => setAvitoData({ BodySparePartType })}
        search={bodySparePartTypeOptions.length > 10}
      />
      <Dropdown
        width='100%'
        placeholder='EngineSparePartType'
        value={avitoData?.EngineSparePartType}
        options={engineSparePartTypeOptions}
        onChange={(EngineSparePartType) => setAvitoData({ EngineSparePartType })}
        search={engineSparePartTypeOptions.length > 10}
      />
      <Dropdown
        width='100%'
        placeholder='TransmissionSparePartType'
        value={avitoData?.TransmissionSparePartType}
        options={transmissionSparePartTypeOptions}
        onChange={(TransmissionSparePartType) => setAvitoData({ TransmissionSparePartType })}
        search={transmissionSparePartTypeOptions.length > 10}
      />
    </div>
    <Divider />
    <div />
  </Content>;
};
