import styled from 'styled-components';
import { ReactComponent as SelectedImage } from './images/selected.svg';

export const Container = styled.div<{disabled?: boolean}>`
  display: flex;
  gap: 8px;

  ${(p) => p.disabled ? 'opacity: 0.6;' : ''}
`;

export const Color = styled.div<{color: string}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: ${(p) => p.color};
  box-shadow: 0px 2px 6px 0px #00000033;
`;

export const Selected = styled(SelectedImage).withConfig({
  shouldForwardProp: (prop) => !['black'].includes(prop),
})<{black?: boolean}>`
  width: 18px;
  height: 18px;

  ${(p) => p.black ? `
    > * {
      fill: #1c1b1f;
      stroke: #1c1b1f;
    }
  ` : `
    > * {
      fill: white;
      stroke: white;
    }    
  `}
`;
