import { utils, writeFile } from 'xlsx';
import { OEM, PriceListItem } from "../../../../../../types";

const mainOem = (oems: OEM[], firstIfNoMain?: boolean) => (oems.find((item) => item.isMain))?.oem || (firstIfNoMain ? oems?.[0]?.oem : undefined);
const otherOems = (oems: OEM[]) => oems.filter((item) => !item.isMain).map((item) => item.oem).join(', ');

export const exportToExcel = (parts: PriceListItem[], t: (value: string) => string) => {
  const data = parts.map((item) => ({
    [t('Photo')]: item.images?.[0] || '',
    [t('Description')]: item.name || '',
    [t('Quantity')]: item.quantity || '',
    [t('Automobile')]: item.carName || '',
    [t('Price')]: item.price || '',
    [t('OEM Part Number main')]: mainOem(item.oems) || '',
    [t('OEM Part Number others')]: otherOems(item.oems) || '',
    [t('Status')]: item.status || '',
    [t('Storage cell')]: item.storageCell || '',
  }));

  const worksheet = utils.json_to_sheet(data);

  worksheet['!cols'] = [
    { width: 50 / 7.5 },
    { width: 300 / 7.5 },
    { width: 100 / 7.5 },
    { width: 300 / 7.5 },
    { width: 50 / 7.5 },
    { width: 250 / 7.5 },
    { width: 250 / 7.5 },
    { width: 100 / 7.5 },
    { width: 100 / 7.5 },
  ];

  const workbook = utils.book_new();
  utils.book_append_sheet(workbook, worksheet, 'Price List');

  const formattedDate = (new Date()).toISOString().replace(/[:T-]/g, '').slice(0, 8);
  const filename = `price-list-${formattedDate}.xlsx`;

  writeFile(workbook, filename);
};
