import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from '../../..';
import { Buttons, Label, Title } from '../../style';
import { Links, Content } from './style';
import { DemoVin } from '../../../../../../types';

interface Props {
  demoVINs?: DemoVin[];
  onSelect: (value: string) => void;
  onClose: () => void;
}

export const DemoSearch = ({ demoVINs, onSelect, onClose }: Props) => {
  const { t } = useTranslation();

  return <Popup onClose={onClose}>
    <Title>{t('Demo mode')}</Title>
    <Content>
      <Label>{t('ReenCar is available for unregistered users to use on three vehicles.')}</Label>
      <Label>{t('Select one of the vehicles below to continue:')}</Label>
      <Links>{demoVINs?.map((item, i) => <div key={i} onClick={() => onSelect(item.vin)}>
        <span>{`${i+1}. ${item.name}`}</span>
        <span>{item.vin}</span>
      </div>)}</Links>
      <Label>{t('To use ReenCar on your vehicles you need to sign up.')}</Label>
    </Content>
    <Buttons>
      <Button secondary onClick={() => onClose()}>{t('Cancel')}</Button>
      <Button onClick={() => window.location.href = '/api/sso-login'}>{t('Sign up')}</Button>
    </Buttons>
  </Popup>;
};
