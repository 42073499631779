import { update } from "ramda";

export const colors = ['white', 'gray', 'silver', 'black', 'brown', 'orange', 'red',
  'dark red', 'pink', 'violet', 'green', 'light blue', 'dark blue', 'beige', 'yellow', 'golden'] as const;

export const colorHex = {
  'white': '#FFFFFF',
  'gray': '#A1A5B7',
  'silver': '#E6E6E6',
  'black': '#1C1B1F',
  'brown': '#AB5914',
  'orange': '#F16522',
  'red': '#FF4105',
  'dark red': '#BF0813',
  'pink': '#FE3EB1',
  'violet': '#8703F9',
  'green': '#16A936',
  'light blue': '#19BCEF',
  'dark blue': '#1613B9',
  'beige': '#FEE2A3',
  'yellow': '#FFF249',
  'golden': 'linear-gradient(270deg,#DACC70 0%, rgba(87, 80, 32, 0.12) 50.09%, #DACC70 100%)',
};

export const colorName = {
  'white': 'White',
  'gray': 'Gray',
  'silver': 'Silver',
  'black': 'Black',
  'brown': 'Brown',
  'orange': 'Orange',
  'red': 'Red',
  'dark red': 'Dark red',
  'pink': 'Pink',
  'violet': 'Violet',
  'green': 'Green',
  'light blue': 'Light blue',
  'dark blue': 'Dark blue',
  'beige': 'Beige',
  'yellow': 'Yellow',
  'golden': 'Golden',
};

export const blackSelected = new Set([0, 2, 13, 14, 15]);

export type Color = typeof colors[number];

export type Fuel = 'gasoline' | 'diesel' | 'electric' | 'hybrid' | 'gas';

export const fuelList = [{
  id: 'gasoline',
  name: 'Gasoline'
}, {
  id: 'diesel',
  name: 'Diesel'
}, {
  id: 'electric',
  name: 'Electric'
}, {
  id: 'hybrid',
  name: 'Hybrid'
}, {
  id: 'gas',
  name: 'Gas'
}];

export type Transmission = 'auto' | 'manual';

export const transmissionList = [{
  id: 'auto',
  name: 'Auto'
}, {
  id: 'manual',
  name: 'Manual'
}];

export const wheelDrivesList = ['FWD', 'RWD', '4WD'] as const;
export type WheelDrive = typeof wheelDrivesList[number];

export const doorsList = ['2', '3', '4', '5', '6'] as const;
export type Doors = typeof doorsList[number];

export const steeringList = ['left', 'right'] as const;
export type Steering = typeof steeringList[number];

export const bodyTypeList = ['hatchback', 'coupe', 'pickup', 'sedan', 'liftback', 'cabriolet', 'wagon', 'van', 'truck', 'suv'] as const;
export type BodyType = typeof bodyTypeList[number];

export const tailgateList = ['back-door', 'trunk-lid', '2-doors-vertical', '2-doors-horizontal'] as const;
export type Tailgate = typeof tailgateList[number];

export const carFields = ['id', 'vin', 'brandName', 'modelName', 'modification', 'year', 'color', 'colorCode', 'engineModel', 'enginePower',
'fuel', 'price', 'odometer', 'transmission', 'transmissionModel', 'wheelDrive', 'doors', 'steering', 'body', 'tailgate', 'images'];

export interface Car {
  id?: number;
  vin?: string;
  brandName?: string;
  modelName?: string;
  modification?: string;
  year?: string;
  color?: Color;
  colorCode?: string;
  engineModel?: string;
  enginePower?: number;
  fuel?: Fuel;
  price?: number;
  odometer?: number;
  transmission?: Transmission;
  transmissionModel?: string;
  wheelDrive?: WheelDrive;
  doors?: Doors;
  steering?: Steering;
  body?: BodyType;
  tailgate?: Tailgate;
  images?: string[];
}

export interface CarRecord extends Car {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  userId?: string;
}

export interface CarShort {
  id: number;
  carName: string;
  brand: string;
}

export interface CarShortStorage {
  id: number;
  brandName: string;
  modelName: string;
  year: string;
}
