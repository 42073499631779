import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, ContextMenu, PopupSendRequest } from '../../../../components';
import { RootState } from '../../../../redux/store';
import { logoutSetReturnUrl } from '../../../../shared';
import { Divider } from '../../style';
import { LanguageSelect, Menu } from './components';
import { Account, Container, DummyHeader, Logo } from './style';

interface Props {
  noMargin?: boolean;
  authButtons?: boolean
}

export const NavigationBar = ({ noMargin, authButtons }: Props) => {
  const history = useHistory();

  const { t, i18n } = useTranslation();

  const [isNotTop, setIsNotTop] = useState(false);
  const [showSendRequestPopup, setShowSendRequestPopup] = useState(false);

  const user = useSelector((state: RootState) => state.Auth.user);
  const landingUrl = useSelector((state: RootState) => state.App.config?.url?.landing);

  useEffect(() => {
    const scrollEvent = () => {
      setIsNotTop(window.pageYOffset > 94 && window.document.documentElement.scrollHeight > window.innerHeight + 156);
    };

    window.addEventListener("scroll", scrollEvent);

    return () => window.removeEventListener("scroll", scrollEvent);
  }, []);

  return <>
    {showSendRequestPopup && <PopupSendRequest location='head' onClose={() => setShowSendRequestPopup(false)} />}
    <DummyHeader/>
    <Container noMargin={noMargin} small={isNotTop}>
      <div>
        <Logo onClick={landingUrl ? () => window.location.href = landingUrl[i18n.language] : () => history.push('/')} small={isNotTop} />
        <div>
          <Menu isAdmin={user?.role === 'admin'} />
          <Divider />
          {!authButtons && <Button minWidth='178px' wide onClick={() => setShowSendRequestPopup(true)}>Join us</Button>}
          {authButtons && !user && <Button minWidth='178px' wide onClick={() => { logoutSetReturnUrl(); document.location.href = '/api/sso-login'; }}>Login</Button>}
          {authButtons && !!user && <ContextMenu
            items={[{
              label: t('Profile'),
              onClick: () => history.push('/profile'),
            }, {
              label: t('Logout'),
              onClick: () => document.location.href = '/api/sso-logout',
            }]}>
            <Account />
          </ContextMenu>}
          <Divider />
          <LanguageSelect />
        </div>
      </div>
    </Container>
  </>;
};
