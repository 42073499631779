import styled from 'styled-components';
import { theme } from '../../themes';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;
`;

export const Content = styled.div<{margin?: string}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;

  ${(p) => p.margin ? `margin: ${p.margin}` : ''};
`;

export const Page = styled.button<{buttonType?: 'prev' | 'next' | 'active'}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background: #ffffff;
  color: #363b49;
  border: 1px solid #dfe5eb;
  border-radius: 100px;

  ${(p) => p.buttonType === 'active' ? `
    background: ${theme.colors.vividOrange};
    color: #ffffff;
    border: 1px solid ${theme.colors.vividOrange};
  `: `
    cursor: pointer;
  `}

  ${(p) => p.buttonType && ['next', 'prev'].includes(p.buttonType) ? `
    transform: rotate(${p.buttonType === 'prev' ? '90deg' : '-90deg'});
  ` : ''}
`;

export const Empty = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 10px;
  width: 32px;
  height: 32px;
  min-width: 32px;
  background: #ffffff;
`;

export const Label = styled.div`
  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  white-space: nowrap;
`;
