import React from 'react';
import { CarShort, ExportType, PriceListItem, Shop } from '../../../../types';
import { FilesContextType, useFilesContext } from '../../context';
import { FilteredListItem, NewCar, PartDetails } from './price-list';
import { Confirmation, ExportDialog, ImportDialog, PopupChangePart, PopupPartDetails } from '../popup';
import { useTranslation } from 'react-i18next';

interface Props {
  priceList: PriceListItem[];
  toDelete: number[] | null;
  toChangeStatus: number[] | null;
  toChangeStorageCell: number[] | null;
  toChangePartDetails: PartDetails;
  showSavedPopup: boolean;
  showExportPopup: ExportType | false;
  showImportPopup: boolean;
  showReloadConfirmation: boolean;
  newCar: NewCar;
  carsShort: CarShort[];
  selected: number[];
  filteredList: FilteredListItem[];
  shop?: Shop;
  setPriceList: (value: PriceListItem[]) => void;
  setToDelete: (value: number[] | null) => void;
  setToChangeStatus: (value: number[] | null) => void;
  setToChangeStorageCell: (value: number[] | null) => void;
  setToChangePartDetails: (value: PartDetails) => void;
  setShowSavedPopup: (value: boolean) => void;
  setShowExportPopup: (value: ExportType | false) => void;
  setShowImportPopup: (value: boolean) => void;
  setShowReloadConfirmation: (value: boolean) => void;
  uploadFiles: (files: File[], callback: (urls: string[]) => void) => void;
  storePart?: (part: Omit<PriceListItem, 'id'>, id?: number) => void;
  reload?: () => void;
  deletePart?: (id: number) => void;
  reloadPriceList?: () => void;
}

export const PriceListPopups = ({ priceList, toDelete, toChangeStatus, toChangeStorageCell, toChangePartDetails, showSavedPopup, showExportPopup, showImportPopup,
  showReloadConfirmation, newCar, carsShort, selected, filteredList, shop, setPriceList, setToDelete, setToChangeStatus, setToChangeStorageCell, setToChangePartDetails,
  setShowSavedPopup, setShowExportPopup, setShowImportPopup, uploadFiles, storePart, reload, deletePart, setShowReloadConfirmation, reloadPriceList }: Props) => {

  const { t } = useTranslation();

  const { files, clearFiles } = useFilesContext() as FilesContextType;

  return <>
    {showSavedPopup && <Confirmation
      title={t('Saved')}
      text={t('Data saved successfully')}
      buttons={[{ label: t('OK'), onClick: () => setShowSavedPopup(false) }]}
      onClose={() => setShowSavedPopup(false)}
    />}
    {toChangeStorageCell && toChangeStorageCell.length > 0 && <PopupChangePart
      title='Change storage cell'
      fields={['storageCell']}
      onAccept={(changes) => setPriceList(priceList.map((item) => toChangeStorageCell.includes(item.id || 0) ? { ...item, ...changes } : item))}
      onClose={() => setToChangeStorageCell(null)}
    />}
    {toChangeStatus && toChangeStatus.length > 0 && <PopupChangePart
      title='Change status'
      fields={['status']}
      onAccept={(changes) => setPriceList(priceList.map((item) => toChangeStatus.includes(item.id || 0) ? { ...item, ...changes } : item))}
      onClose={() => setToChangeStatus(null)}
    />}
    {toDelete && toDelete.length > 0 && <Confirmation
      title={t('Delete')}
      text={`${t(`Are you sure you want to delete`)} ${toDelete.length === 1 ? t('this item') : t('these items')}?`}
      buttons={[{
        label: t('Cancel'),
        secondary: true,
        onClick: () => setToDelete(null),
      }, {
        label: t('Delete'),
        onClick: () => {
          setPriceList(priceList.filter((item) => !toDelete.includes(item.id || 0)));
          setToDelete(null);

          if (deletePart) {
            toDelete.forEach((id) => deletePart(id));
          }
        },
      }]}
      onClose={() => setToDelete(null)}
    />}
    {toChangePartDetails !== null && <PopupPartDetails
      part={toChangePartDetails.mode === 'new' ? newCar : priceList.find((item) => item.id === toChangePartDetails.id)}
      carsShort={carsShort}
      mode={toChangePartDetails.mode}
      onChange={(part, filesToDelete) => {
        uploadFiles(files, (urls) => {
          const images = [...(part.images?.filter?.((item) => !filesToDelete.includes(item)) || []), ...urls];
          const newPart = { ...part, images };

          if (!toChangePartDetails.mode) {
            setPriceList(priceList.map((item) => toChangePartDetails.id === item.id ? { ...item, ...newPart } : item));
          }

          if (!storePart) {
            newPart['id'] = priceList.reduce<number>((acc, cur) => acc < (cur.id || 0) ? (cur.id || 0) : acc, 0) + 1;
          }

          clearFiles();

          if (storePart) {
            const partId = ('id' in part && toChangePartDetails.mode !== 'duplicate') ? part.id : undefined;
            storePart(newPart, partId);
          } else if (toChangePartDetails.mode) {
            setPriceList([newPart, ...priceList]);
          }
        });
      }}
      onClose={() => setToChangePartDetails(null)}
    />}
    {showExportPopup && <ExportDialog
      selectedPartIds={selected}
      pageIds={filteredList.filter((item) => !!item?.id).map((item) => item.id) as number[]}
      exportType={showExportPopup}
      shop={shop}
      onSuccess={reload}
      onClose={() => setShowExportPopup(false)}
    />}
    {showImportPopup && <ImportDialog onClose={() => setShowImportPopup(false)} />}
    {showReloadConfirmation && <Confirmation
      title={t('Warning')}
      text={t('All parts in this car will be removed and generated again')}
      onClose={() => setShowReloadConfirmation(false)}
      buttons={[{
        label: 'Cancel',
        secondary: true,
        onClick: () => setShowReloadConfirmation(false),
      }, {
        label: 'Reload',
        onClick: () => {
          setShowReloadConfirmation(false);
          reloadPriceList?.();
        },
      }]}
    />}
  </>;
};
