import { Checkbox as MuiCheckbox } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checked, Container, Unchecked } from './style';

interface Props {
  value?: boolean;
  paleUnchecked?: boolean;
  disabled?: boolean;
  label?: string;
  noPadding?: boolean;
  small?: boolean;
  noWrap?: boolean;
  margin?: string;
  width?: string;
  onChange: (value: boolean) => void;
}

export const Checkbox = ({ value, paleUnchecked, disabled, label, noPadding, small, noWrap, margin, width, onChange }: Props) => {
  const { t } = useTranslation();

  return <Container pale={(paleUnchecked && !value) || disabled} noWrap={noWrap} margin={margin} width={width}>
    <MuiCheckbox
      checked={value}
      onChange={!disabled ? (event) => onChange(event.target.checked) : undefined}
      icon={<Unchecked small={small} />}
      checkedIcon={<Checked small={small} />}
      disableRipple
      style={noPadding ? { padding: 0 } : undefined}
    />
    {label && <div onClick={!disabled ? () => onChange(!value) : undefined}>{t(label)}</div>}
  </Container>;
};
