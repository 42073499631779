import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Input, ListItemIcon, FormControlLabel, ClickAwayListener } from '@mui/material';
import { Container, Placeholder, InputPlaceholder, SelectStyle, Arrow, Remove, MenuStyle,
  SearchContainer, Search, NoResults, NoPicture, ListItemTextStyle, Error } from './style';
import { Checkbox } from '../checkbox';

export interface DropdownOption {
  label: string;
  value: string;
  image?: string;
}

interface BaseProps {
  options: DropdownOption[];
  placeholder?: string;
  width?: string;
  margin?: string
  search?: boolean;
  hasPictures?: boolean;
  maxHeight?: number;
  inputPlaceholder?: string;
  inline?: boolean;
  error?: string | boolean;
  autoDropdownWidth?: boolean;
  noClearButton?: boolean;
  disabled?: boolean;
}

interface PropsSingle extends BaseProps {
  multiple?: false;
  value?: string;
  onChange: (value: string) => void;
}

interface PropsMultiple extends BaseProps {
  multiple: true;
  value?: string[];
  onChange: (value: string[]) => void;
}

type Props = PropsSingle | PropsMultiple;

export const Dropdown = ({ options, value, placeholder, multiple, width, margin, search, hasPictures,
  maxHeight, inputPlaceholder, inline, error, autoDropdownWidth, noClearButton, disabled, onChange }: Props) => {

  const { t } = useTranslation();

  const componentRef = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [selfWidth, setSelfWidth] = useState<number>();

  const open = Boolean(anchorEl);

  const valueEmpty = useMemo(() => Array.isArray(value) ? value.length === 0 : (value || '') === '', [value]);
  const active = useMemo(() => open || !valueEmpty, [open, valueEmpty]);

  const handleClick = (event, ref?: MutableRefObject<HTMLDivElement | null>) => {
    if (disabled) {
      return;
    }

    setAnchorEl(!anchorEl ? ref?.current || event.currentTarget : null);
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchText('');
  };

  const handleSelection = (selectedValue: string) => {
    if (multiple) {
      const newValue = value?.includes?.(selectedValue)
        ? value.filter((val) => val !== selectedValue)
        : [...(value || []), selectedValue];
      onChange(newValue);
    } else {
      onChange(selectedValue);
      handleClose();
    }
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase()),
  );

  useEffect(() => {
    const handleResize = () => {
      if (componentRef.current) {
        setSelfWidth(componentRef.current.offsetWidth);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return <ClickAwayListener onClickAway={handleClose}>
    <Container ref={componentRef} width={width} margin={margin} inline={inline}>
      <div className={error ? 'input-error' : undefined} />
      <SelectStyle
        multiple={multiple}
        value={value}
        active={active}
        inline={inline}
        error={!!error}
        disabled={disabled}
        onClick={handleClick}
        renderValue={() => Array.isArray(value) ?
          value.map((item) => t(options.find((option) => option.value === item)?.label || '')).join(', ') :
          t(options.find((option) => option.value === value)?.label || '')}
      />
      {!inline && <Placeholder active={active} onClick={(event) => handleClick(event, componentRef)} width={selfWidth}>{placeholder ? t(placeholder) : ''}</Placeholder>}
      {active && valueEmpty && inputPlaceholder && <InputPlaceholder>{t(inputPlaceholder)}</InputPlaceholder>}
      {!inline && valueEmpty && <Arrow open={open} onClick={handleClick} />}
      {!inline && !valueEmpty && !noClearButton && <Remove onClick={multiple ? () => onChange([]) : () => onChange('')} />}
      {anchorEl && <MenuStyle
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        width={!autoDropdownWidth ? selfWidth : undefined}
        maxHeight={maxHeight}
        search={search}
        hasPictures={hasPictures}
        inline={inline}
        root={<div style={{ background: 'red' }} />}
      >
        <div>
          {search && <SearchContainer>
            <Input
              value={searchText}
              onChange={handleSearchTextChange}
              placeholder={t('Search')}
              fullWidth
              margin="dense"
              disableUnderline
            />
            <Search />
          </SearchContainer>}
          {filteredOptions.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              onClick={() => handleSelection(option.value)}
              disableRipple
            >
              {hasPictures && option.image && <ListItemIcon>
                <img src={option.image} alt={t(option.label)} style={{ width: 24, height: 24 }} />
              </ListItemIcon>}
              {hasPictures && !option.image && <NoPicture />}
              <ListItemTextStyle active={!!searchText} primary={t(option.label)} />
              {multiple && <ListItemIcon style={{ marginRight: -8 }}>
                <FormControlLabel style={{ marginRight: 0 }}
                  control={<Checkbox
                    value={value?.includes?.(option.value)}
                    paleUnchecked
                    onChange={() => handleSelection(option.value)}
                  />}
                  label=""
                />
              </ListItemIcon>}
            </MenuItem>
          ))}
          {filteredOptions.length === 0 && <NoResults>{t('No results found')}</NoResults>}
        </div>
      </MenuStyle>}
      <Error active={!!error}>{error && typeof error === 'string' ? t(error) : ''}</Error>
    </Container>
  </ClickAwayListener>;
};
