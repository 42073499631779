export const exportTypes = ['excel', 'avito'] as const;
export type ExportType = typeof exportTypes[number];
export const exportTypeLabels: Record<ExportType, string> = {
  'excel': 'Excel',
  'avito': 'Avito',
}
export const exportTypesExt: Record<ExportType, string> = {
  'excel': 'xlsx',
  'avito': 'xml',
}

export const encodings = ['utf8', 'win1251'] as const;
export type Encoding = typeof encodings[number];
export const encodingLabels: Record<Encoding, string> = {
  'utf8': 'UTF-8',
  'win1251': 'Windows-1251',
}

export const decimalSeparators = ['.', ','] as const;
export type DecimalSeparator = typeof decimalSeparators[number];

export const currencies = ['EUR', 'USD', 'GBP', 'RUR', 'UZS', 'SEK'] as const;
export type Currency = typeof currencies[number];

export const schedules = ['daily', 'weekly', 'hourly'] as const;
export type Schedule = typeof schedules[number];

export const scheduleLabels: Record<Schedule, string> = {
  'daily': 'Daily',
  'weekly': 'Weekly',
  'hourly': 'Hourly',
}

export const exportFields = ['name', 'type', 'encoding', 'decimalSeparator', 'currency', 'schedule', 'zip', 'email', 'status', 'customHour', 'customMinute', 'filename'];

export interface Export {
  id?: number;
  userId?: string;
  name: string;
  type?: ExportType;
  encoding?: Encoding;
  decimalSeparator?: DecimalSeparator;
  currency?: Currency;
  schedule?: Schedule;
  zip?: boolean;
  email?: string;
  status?: string;
  customHour?: number;
  customMinute?: number;
  filename?: string;
  createdAt?: string;
  updatedAt?: string;
}