import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, Dropdown, Input } from '../../..';
import { Buttons, Title } from '../../style';
import { PartStatus, PriceListItem, partStatusList, partStatusTitles } from '../../../../../../types';
import { Content } from './style';

type PartFields = 'status' | 'storageCell';

interface Props {
  title: string;
  fields: PartFields[];
  onAccept: (changes: Partial<PriceListItem>) => void;
  onClose: () => void;
}

export const PopupChangePart = ({ title, fields, onAccept, onClose }: Props) => {
  const { t } = useTranslation();

  const [status, setStatus] = React.useState<PartStatus>();
  const [storageCell, setStorageCell] = React.useState<string>();

  return <Popup onClose={onClose}>
    <Title>{t(title)}</Title>
    <Content>
      {fields.includes('status') && <Dropdown
        width='100%'
        placeholder={t('Status')}
        value={status || ''}
        options={partStatusList.map((value) => ({ label: t(partStatusTitles[value]), value }))}
        maxHeight={358}
        onChange={(value) => setStatus(value as PartStatus)}
      />}
      {fields.includes('storageCell') && <Input
        width='100%'
        placeholder={t('Storage cell')}
        value={storageCell || ''}
        onChange={(value) => setStorageCell(value)}
      />}
    </Content>
    <Buttons>
      <Button secondary onClick={() => onClose()}>Cancel</Button>
      <Button disabled={!!status && !!storageCell} onClick={() => {
        onAccept({
          ...(fields.includes('status') && { status }),
          ...(fields.includes('storageCell') && { storageCell }),
        });
        onClose();
      }}>Accept</Button>
    </Buttons>
  </Popup>;
};
