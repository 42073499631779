import React from 'react';
import { useTranslation } from 'react-i18next';
import { MenuStyle, MenuItem } from './style';

interface Props {
  items: string[]
  selected?: number;
  margin?: string;
  setSelected: (value: number) => void;
}

export const Tabs = ({ items, selected, margin, setSelected }: Props) => {
  const { t } = useTranslation();

  return <MenuStyle margin={margin}>
    {(items || []).map((title, i) => <MenuItem
      key={i}
      selected={selected === i}
      onClick={() => setSelected(i)}
    >
      {t(title)}
    </MenuItem>)}
  </MenuStyle>;
};
