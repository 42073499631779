import { setBrandFieldsValues, setBrandFieldsList, setBrandsList, setCar, setCarInfo, setSessionId, setFoundByVin,
  setSearchStatus, setPriceList, setCarsToSelect, setCarId, setLoadingStatus, setNodesParts, setNodes, setNodesTitles, resetCar } from '..';
import { avitoCategoriesMapping, Node, NodesParts, NodesTitles, PriceListResponse, PriceListStat } from '../../../../../types';
import { mockGetPriceList, getBodyAndTailgateByDoors, getTailgateAndDoorsByBody, clearNodes, clearCar } from '../../../shared';
import { BrandField, Car, Language } from '../../../types';
import { AppThunk } from '../../store';

export const getBrandsList = (): AppThunk => async (dispatch) => {
  fetch(`/api/backend/get-brands`).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res) => {
    dispatch(setBrandsList(res));
  }).catch((error) => {
    console.error(error);
  });
};

export const getBrandFields = (brandId: string): AppThunk => async (dispatch, getState) => {
  const { language } = getState().App;

  fetch(`/api/backend/get-brand-fields/${brandId}/${language}`).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res) => {
    dispatch(setSessionId(res?.session));
    dispatch(setBrandFieldsList(res?.fields));
  }).catch((error) => {
    console.error(error);
  });
};

export const setBrandField = (fieldId: number, optionId: number | null, fieldName: string, fieldValue: string, callback?: (allowListVehicles: boolean) => void): AppThunk => async (dispatch, getState) => {
  const { sessionId, brandFieldsValues } = getState().Car;

  fetch(`/api/backend/set-brand-field`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session: sessionId, fieldId, optionId }),
  }).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res) => {
    dispatch(setSessionId(res?.session));
    dispatch(setBrandFieldsList(res?.fields));

    const fields = res?.fields as BrandField[];

    const newFields = Object.fromEntries(Object.entries(brandFieldsValues || {})
      .filter(([key, value]) => !!fields?.find(({ field }) => field === key)?.options?.find((item) => item.name === value)));

    newFields[fieldName] = fieldValue;

    dispatch(setBrandFieldsValues(newFields));

    // eslint-disable-next-line promise/no-callback-in-promise
    callback?.(res?.allowListVehicles);
  }).catch((error) => {
    console.error(error);
  });
};

export const searchByParams = (): AppThunk => async (dispatch, getState) => {
  const { sessionId } = getState().Car;
  const { language } = getState().App;

  if (!sessionId) {
    return;
  }

  dispatch(setSearchStatus('loading'));
  dispatch(resetCar());

  const res = await fetch(`/api/backend/search-by-params/${sessionId}/${language}`).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  });

  if ((res?.cars?.length || 0) > 1) {
    dispatch(setCarId());
    dispatch(setCarsToSelect(res?.cars.map(({ fields }) => fields)));
  } else {
    dispatch(setCarId(0));
    dispatch(setCarInfo(res?.cars?.[0]?.fields));
  }

  dispatch(setSessionId(res?.session));
  dispatch(setFoundByVin(false));
  dispatch(setSearchStatus(res?.cars?.[0]?.fields ? 'done' : 'error'));
};

export const searchByVin = (vin: string, callbackError?: () => void): AppThunk => async (dispatch, getState) => {
  if (!vin) {
    return;
  }

  dispatch(setSearchStatus('loading'));

  const { language } = getState().App;

  dispatch(resetCar());

  const res = await fetch(`/api/backend/search-by-vin/${vin}/${language}`).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  });

  if (res?.error) {
    callbackError?.();
  } else {
    dispatch(setFoundByVin(true));
  }

  if ((res?.cars?.length || 0) > 1) {
    dispatch(setCarId());
    dispatch(setCarsToSelect(res?.cars.map(({ fields }) => fields)));
  } else {
    dispatch(setCarId(0));
    dispatch(setCarInfo(res?.cars?.[0]?.fields));
  }

  dispatch(setSessionId(res?.session));
  dispatch(setSearchStatus(res?.cars?.[0]?.fields ? 'done' : 'error'));
};

export const getCar = (params: { sessionId?: string, vin?: string }, callback: () => void): AppThunk => async (dispatch, getState) => {
  const carId = getState().Car.carId;

  fetch(`/api/backend/get-fields`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...params, carId }),
  }).then((response) => {
    if (response?.ok) {
      return response?.json() as Car;
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
      throw new Error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res) => {
    if (res?.body && !res?.doors) {
      res.doors = getTailgateAndDoorsByBody(res?.body)?.doors;
    }

    if (res?.body && !res?.tailgate) {
      res.tailgate = getTailgateAndDoorsByBody(res?.body)?.tailgate;
    }

    if (res?.doors && !res?.body && !res?.tailgate) {
      res.body = getBodyAndTailgateByDoors(res?.doors)?.body;
      res.tailgate = getBodyAndTailgateByDoors(res?.doors)?.tailgate;
    }

    dispatch(setCar(res));
    // eslint-disable-next-line promise/no-callback-in-promise
    callback();
  }).catch((error) => {
    console.error(error);
  });
};

export const getPriceList = (callback?: (stat: PriceListStat) => void): AppThunk => async (dispatch, getState) => {
  dispatch(setLoadingStatus('loading'));

  const language = getState().App.language;
  const sessionId = getState().Car.sessionId;
  const nodes = clearNodes(getState().Car.nodes);
  const car = clearCar(getState().Car.car);

  fetch(`/api/backend/get-price-list`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ language, sessionId, nodes, car }),
  }).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
      throw new Error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res) => {
    dispatch(setLoadingStatus('done'));

    const { items, stat } = res as PriceListResponse;

    const result = items?.map((item) => ({
      ...item,
      carName: `${car?.brandName || ''} ${car?.modelName || ''} ${car?.year || ''}`.trim(),
      brand: car?.brandName,
      status: 'car' as const,
      ...(item.code && avitoCategoriesMapping[item.code] ? { avito: avitoCategoriesMapping[item.code] } : {}),
    }));

    dispatch(setPriceList(result));
    // eslint-disable-next-line promise/no-callback-in-promise
    callback?.(stat);
  }).catch((error) => {
    dispatch(setLoadingStatus('error'));
    console.error(error);
  });
};

export const getPriceListMock = (callback?: () => void): AppThunk => async (dispatch) => {
  // dispatch(setPriceList(mockGetPriceList.filter((_, i) => i < 5)));
  dispatch(setPriceList(mockGetPriceList));
  callback?.();
};

export const getNodesParts = (language: Language): AppThunk => async (dispatch, getState) => {
  dispatch(setLoadingStatus('loading'));

  const car = clearCar(getState().Car.car);

  fetch('/api/backend/get-nodes-parts', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ language, car }),
  }).then((response) => {
    if (response?.ok) {
      return response?.json();
    } else {
      console.error(`Error during fetch: ${response?.statusText}`);
    }
  }).then((res: { nodesParts: NodesParts, nodesTitles: NodesTitles, nodes: Node[] }) => {
    dispatch(setNodesParts(res?.nodesParts));
    dispatch(setNodesTitles(res?.nodesTitles));

    const nodes = getState().Car.nodes;

    if (nodes.length === 0) {
      dispatch(setNodes(res?.nodes));
    }

    dispatch(setLoadingStatus('done'));
  }).catch((error) => {
    console.error(error);
    dispatch(setLoadingStatus('error'));
  });
};
