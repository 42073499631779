import styled from 'styled-components';
import { theme } from '../../themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  background: ${theme.colors.veryPaleOrange};
`;

export const Content = styled.div<{maxWidth?: number; flex?: boolean}>`
  width: 100%;
  max-width: ${(p) => p.maxWidth ? p.maxWidth : 1424}px;
  margin: 0 auto;
  flex: 1;

  ${(p) => p.flex ? `
    display: flex;
    flex-direction: column;
  ` : ''}
`;

export const Divider = styled.hr<{smallMargin?: boolean}>`
  color: ${theme.colors.grayishBlue};
  border: 1px solid ${theme.colors.grayishBlue};
  height: 44px;
  margin: 0 ${(p) => p.smallMargin ? 16 : 32}px;
`;
