import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PriceListItem } from '../../../../types';
import { Actions } from '../../redux';
import { RootState } from '../../redux/store';
import { defaultItemsPerPage, itemsPerPageList } from '../../shared';
import { useLocalStorage } from '../../shared/hooks';

export const usePriceList = (carId?: number, defaultLimit?: number) => {
  const dispatch = useDispatch();

  const [limitLS, setLimitLS] = useLocalStorage('priceListLimit', defaultLimit || defaultItemsPerPage);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(itemsPerPageList.includes(limitLS) ? limitLS : defaultItemsPerPage);
  const [searchText, setSearchText] = useState('');
  const [order, setOrder] = useState<{field: string; order: 'asc' | 'desc' } | undefined>();

  const { loadingStatus, priceList, priceListTotal } = useSelector((state: RootState) => state.Car);

  const loadPriceList = useCallback((carId?: number) => dispatch(Actions.Car.loadPriceList({ limit, page, q: searchText, order }, carId)), [limit, page, searchText, order, dispatch]);
  const setPriceList = useCallback((value: PriceListItem[]) => dispatch(Actions.Car.setPriceList(value)), [dispatch]);
  const uploadFiles = useCallback((files: File[], callback: (urls: string[]) => void) => dispatch(Actions.Car.uploadFiles(files, callback)), [dispatch]);
  const storePart = useCallback((part: Omit<PriceListItem, 'id'>, id?: number) => dispatch(Actions.Car.storePart(part, id)), [dispatch]);
  const deletePart = useCallback((id: number, carId?: number) => dispatch(Actions.Car.deletePart(id, () => loadPriceList(carId))), [dispatch, loadPriceList]);
  const reload = useCallback(() => loadPriceList(carId), [carId, loadPriceList]);

  useEffect(() => {
    loadPriceList(carId);
  }, [loadPriceList, carId]);

  useEffect(() => {
    setLimitLS(limit);
  }, [limit, setLimitLS]);

  return { loadingStatus, priceList, priceListTotal, page, limit, searchText, order, loadPriceList, setPriceList,
    uploadFiles, storePart, deletePart, setPage, setLimit, setSearchText, setOrder, reload };
};
