import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDrag, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { ReactComponent as AddImage } from './images/add.svg';
import { Add, Container, Item, Picture, Remove } from './style';
import { FilesContextType, useFilesContext } from '../../context';

const maxPictures = 3;

export const AddPicture = () => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { files, addFiles, moveFiles, removeFile } = useFilesContext() as FilesContextType;

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item) => addFiles((item as { files: File[] }).files.slice(0, maxPictures - files.length).map((file) => file)),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return <Container>
    {files.map((picture, i) => <Item key={i}>
      <DraggablePicture picture={picture} index={i} moveImage={moveFiles} notDraggable={files.length === 1} />
      <Remove onClick={() => removeFile(i)} />
      {i === 0 && <span>{t('Main photo')}</span>}
    </Item>)}
    {files.length < maxPictures && <Item>
      <input
        type='file'
        accept='image/*'
        multiple
        onChange={(event) => {
          const data = Array.from((event.target?.files || []));
          addFiles(data.slice(0, maxPictures - files.length));
        }}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <Add ref={drop} hover={isOver} onClick={() => fileInputRef.current?.click?.()}>
        <AddImage />
      </Add>
      {files.length === 0 && <span>{t('Max 3 photos')}</span>}
    </Item>}
  </Container>;
};

const DraggablePicture = ({ picture, index, moveImage, notDraggable }) => {
  const ref = useRef<HTMLImageElement>(null);

  const [, drop] = useDrop({
    accept: 'image',
    hover: (item: { id: number, index: number }, monitor) => {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'image',
    item: () => ({ id: picture.id, index }),
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  drag(drop(ref));

  return <Picture ref={ref} dragged={isDragging} notDraggable={notDraggable}>
    <img src={URL.createObjectURL(picture)} style={{ width: '100%', height: '100%' }} />
  </Picture>;
};
