import * as xmlbuilder from 'xmlbuilder';
import { Ad, PriceListItem, Shop, avitoCategoriesMapping, avitoPrefix } from '../types';
import { genUniqueId } from './gen-unique-id';

const defaultCategory = 'Запчасти и аксессуары';
const defaultAsType = 'Товар приобретен на продажу';
const defaultProductType = 'Для автомобилей';
const defaultCondition = 'Б/У'; //'Новое';
const defaultGoodsType = 'Запчасти';

export const prepareAvito = (parts: PriceListItem[], shop: Shop) => {
  const ads = parts.filter((part) => !!part.oems?.[0]).map((part) => ({
    Id: part.avito?.customId || `${avitoPrefix}${part.id?.toString?.() || genUniqueId()}`,
    Address: shop.address || '',
    ContactPhone: shop.phone,
    Category: defaultCategory,
    Description: part.description || part.name,
    Title: `${part.name} ${part.carName}`,
    GoodsType: part.avito?.GoodsType || avitoCategoriesMapping[part.code || '']?.GoodsType || defaultGoodsType,
    AdType: defaultAsType,
    ProductType: defaultProductType,
    SparePartType: part.avito?.SparePartType || avitoCategoriesMapping[part.code || '']?.SparePartType,
    Condition: defaultCondition,
    // TBD: add mapping
    Brand: part.brand || '',
    OEM: part.oems?.find?.((item) => item.isMain)?.oem,
    EngineSparePartType: part.avito?.EngineSparePartType || avitoCategoriesMapping[part.code || '']?.EngineSparePartType,
    TransmissionSparePartType: part.avito?.TransmissionSparePartType || avitoCategoriesMapping[part.code || '']?.TransmissionSparePartType,
    BodySparePartType: part.avito?.BodySparePartType || avitoCategoriesMapping[part.code || '']?.BodySparePartType,
    Price: part.price?.toString?.(),
    Images: part.images?.map?.((url) => ({ url })) || [],
    Length: part.length?.toString?.(),
    Width: part.width?.toString?.(),
    Height: part.height?.toString?.(),
  }));

  return genAvitoXML(ads);
};

export const genAvitoXML = (ads: Ad[]): string => {
  const root = xmlbuilder.create('Ads', { version: '1.0', encoding: 'UTF-8' })
    .att('formatVersion', '3')
    .att('target', 'Avito.ru');

  ads.forEach(ad => {
    const adElement = root.ele('Ad');
    Object.keys(ad).forEach(key => {
      if (key === 'Images') {
        const imagesElement = adElement.ele('Images');
        ad.Images?.forEach?.(image => {
          imagesElement.ele('Image', { url: image.url });
        });
      } else {
        adElement.ele(key, {}, ad[key]);
      }
    });
  });

  return root.end({ pretty: true });
};
