import { clearPart } from "..";
import { postOptions } from "../../../../shared";
import { PriceListItem, Shop, avitoPrefix } from "../../../../types";

const strip = (value: string) => value.replace(/<\/?[^>]+(>|$)/g, "");

export const avitoImportStatFieldsList = ['total', 'success', 'notFound', 'forbidden', 'otherError'] as const;

type AvitoImportStatFields = typeof avitoImportStatFieldsList[number];

export const avitoExportFieldTitles: Record<AvitoImportStatFields, string> = {
  total: 'Total parts in file:',
  success: 'Imported successfully:',
  notFound: 'Parts not found:',
  forbidden: 'Parts belonging to another user:',
  otherError: 'Parts has other loading errors:',
};

export interface ImportAvitoStat {
  total: number;
  otherError: number;
  notFound: number;
  forbidden: number;
  success: number;
}

const avitoStatusActive = 'Активно';

const avitoImportFields = ['Id', 'AvitoId', 'AvitoStatus', 'GoodsType', 'Title', 'Description', 'Price', 'ImageUrls',
  'Address', 'ContactPhone', 'BodySparePartType', 'Brand', 'EngineSparePartType', 'OEM', 'SparePartType', 'TransmissionSparePartType'] as const;

type AvitoImportFields = typeof avitoImportFields[number];

export const importAvito = async (rows: string[][], updateShop: (shop: Partial<Shop>) => void) => {
  const stat: ImportAvitoStat = {
    total: 0,
    otherError: 0,
    notFound: 0,
    forbidden: 0,
    success: 0,
  };

  const columns = Object.fromEntries(rows[0].map((item, i) => [item, i]).filter(([item, i]) => avitoImportFields.includes(item as AvitoImportFields)));

  const data = rows.slice(1).map((row) => Object.entries(columns).reduce((acc, [column, count]) =>
    ({ ...acc, ...(row[count as number] !== undefined ? { [column]: row[count as number] } : {}) }), {})) as unknown as Record<AvitoImportFields, string>[];

  const shopData = {} as Shop;

  for (const item of data) {
    shopData.address = item['Address'] || shopData.address;
    shopData.phone = item['ContactPhone'] || shopData.phone;
    const isReencarPart = item.Id?.startsWith?.(avitoPrefix);

    if (!item.AvitoId) {
      break;
    }

    const part: Partial<PriceListItem> = {
      ...(isReencarPart ? { id: +item.Id.slice(avitoPrefix.length) } : {
        name: strip(item.Title),
        description: strip(item.Description),
        quantity: 1,
        images: item.ImageUrls ? item.ImageUrls.split(' | ') : [],
        oems: [{
          oem: item.OEM,
          isMain: true,
        }],
      }),

      price: item.Price ? +item.Price : 0,
      brand: item.Brand,
      isExportAvito: item.AvitoStatus === avitoStatusActive,

      avito: {
        customId: item.Id,
        id: item.AvitoId,
        GoodsType: item.GoodsType,
        SparePartType: item.SparePartType,
        BodySparePartType: item.BodySparePartType,
        EngineSparePartType: item.EngineSparePartType,
        TransmissionSparePartType: item.TransmissionSparePartType,
      },
    };

    try {
      const response = await fetch(`/api/storage/part/avito`, { ...postOptions, body: JSON.stringify(clearPart(part, ['id'])) }).then((res) => res.json());

      if ('error' in response && response.statusCode === 404) {
        stat.notFound++;
      } else if ('error' in response && response.statusCode === 403) {
        stat.forbidden++;
      } else if ('error' in response) {
        stat.otherError++;
      } else {
        stat.success++;
      }
    } catch (error) {
      console.error(error);
      stat.otherError++;
    }

    stat.total++;
  }

  updateShop(shopData);

  return stat;
};
