import { Action, combineReducers, configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { ThunkAction } from 'redux-thunk';
import AuthReducer, { initialState as authInitialState } from './auth';
import AppReducer, { initialState as appInitialState } from './app';
import CarReducer, { initialState as carInitialState } from './car';

const history = createBrowserHistory();

export const rootReducer = combineReducers({
  Auth: AuthReducer,
  App: AppReducer,
  Car: CarReducer,
});

export const initialState = {
  Auth: authInitialState,
  App: appInitialState,
  Car: carInitialState,
};

export type RootState = ReturnType<typeof rootReducer>

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>

const store = configureStore({
  reducer: rootReducer,
});

export { store, history };
