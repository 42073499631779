import React, { useState } from 'react';
import { Button, Dropdown, Input, Popup } from '../../..';
import { User, UserRole, userRoles } from '../../../../../../types';
import { Buttons, Column } from '../../style';

const roleOptions = userRoles.map((value) => ({ value, label: value }));

interface Props {
  user: User;
  storeUser: (user: Partial<User>) => void;
  onClose: () => void;
}

export const PopupUser = ({ user, storeUser, onClose }: Props) => {
  const [name, setName] = useState(user.name || '');
  const [surname, setSurname] = useState(user.surname || '');
  const [email, setEmail] = useState(user.email || '');
  const [phone, setPhone] = useState(user.phone || '');
  const [role, setRole] = useState(user.role);

  return <Popup width='410px' onClose={onClose}>
    <Column>
      <Input disabled placeholder='Username' value={name} onChange={(value) => setName(value)} />
      <Input disabled placeholder='Surname' value={surname} onChange={(value) => setSurname(value)} />
      <Input disabled placeholder='Email' value={email} onChange={(value) => setEmail(value)} />
      <Input disabled placeholder='Phone' value={phone} onChange={(value) => setPhone(value)} />
      <Dropdown placeholder='Role' value={role} options={roleOptions} noClearButton onChange={(value) => setRole(value as UserRole)} />
      <Buttons margin='16px 0 0'>
        <Button width='100%' wide secondary onClick={() => onClose()}>Cancel</Button>
        <Button width='100%' wide onClick={() => storeUser({ role })}>Save</Button>
      </Buttons>
    </Column>
  </Popup>;
};
