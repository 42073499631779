import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { SocialNetworks } from '../../../social-networks';
import { menuItemsFooter } from '../../menu-items';
import { Divider } from '../../style';
import { Container, Content, Images, Mail, Menu } from './style';

// import AppStoreImage from './images/appstore.png';
// import GooglePlayImage from './images/google-play.png';

interface Props {
  noMargin?: boolean;
}

export const Footer = ({ noMargin }: Props) => {
  const { t, i18n } = useTranslation();

  const smallMargin = useMemo(() => i18n.language !== 'EN', [i18n]);

  const legal = useSelector((state: RootState) => state.App.config?.url?.legal);

  return <Container noMargin={noMargin}>
    <Content smallMargin={smallMargin}>
      <div>
        <span>{t(`© Reencar 2022-${(new Date()).getFullYear()}. All rights reserved`)}</span>
        {legal?.[i18n.language]?.privacyPolicy && <>
          <Divider smallMargin={smallMargin} />
          <a href={legal?.[i18n.language]?.privacyPolicy} target='_blank'>{t('Privacy Policy')}</a>
        </>}
        {legal?.[i18n.language]?.userAgreement && <>
          <Divider smallMargin={smallMargin} />
          <a href={legal?.[i18n.language]?.userAgreement} target='_blank'>{t('User Agreement')}</a>
        </>}
        <Divider smallMargin={smallMargin} />
        <Menu smallMargin={smallMargin}>
          {menuItemsFooter.map((item, i) => <a key={i} href={item.link} target='_blank'>{t(item.title)}</a>)}
        </Menu>
        <Divider smallMargin={smallMargin} />
        <Mail />
        <a href='mailto:mail@reencar.com'>mail@reencar.com</a>
      </div>
      <Images>
        <div>
          {/* <img src={GooglePlayImage} />
          <img src={AppStoreImage} /> */}
        </div>
        <SocialNetworks />
      </Images>
    </Content>
  </Container>;
};
