import styled from 'styled-components';
import { theme } from '../../themes';

export const Container = styled.div`
  position: fixed;
  inset: 0 0 0 0;
  backdrop-filter: blur(5px);
  z-index: 10000;

  > div {
    position: absolute;
    top: calc(50% - 50px);
  }

  > div,
  > div:before,
  > div:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }
  > div {
    color: ${theme.colors.vividOrange};
    font-size: 10px;
    margin: auto auto;
    position: relative;
    text-indent: -9999em;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  > div:before,
  > div:after {
    content: '';
    position: absolute;
    top: 0;
  }
  > div:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  > div:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`;
