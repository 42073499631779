import styled from 'styled-components';
import { theme } from '../../themes';

export const Container = styled.div`
  display: flex;
  padding: 4px 0;
  height: 56px;
  border-radius: 12px;
  background: ${theme.colors.whiteSmoke};
  /* gap: 12px; */

  > div:not(:last-of-type) {
    border-right: 1px solid ${theme.colors.veryLightGray};
  }
`;

export const Item = styled.div<{selected: boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  color: ${theme.fonts.lato};
  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  ${(p) => p.selected ? `
    border-radius: 12px;
    background: ${theme.colors.white};
    box-shadow: 0px 2px 6px 0px #00000033;
  ` : ''}
`;

