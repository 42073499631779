import React from 'react';
import ReactHintFactory from 'react-hint';
import 'react-hint/css/index.css';
import { useTranslation } from 'react-i18next';
import { blackSelected, colorHex, colorName, colors } from '../../types';
import { Color, Container, Selected } from './style';

const ReactHint = ReactHintFactory(React);

interface Props {
  selected?: string;
  disabled?: boolean;
  setSelected: (value?: string) => void;
}

export const ColorPicker = ({ selected, disabled, setSelected }: Props) => {
  const { t } = useTranslation();

  return <Container disabled={disabled}>
    {colors.map((color, i) => <Color
      key={i}
      color={colorHex[color]}
      onClick={!disabled ? () => setSelected(color !== selected ? color : undefined) : undefined}
      data-rh={t(colorName[color])}
    >
      {color === selected && <Selected black={blackSelected.has(i)} />}
    </Color>)}
    <ReactHint autoPosition events />
  </Container>;
};
