import * as Browserslist from 'browserslist-useragent';
import i18n from 'i18next';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Helmet } from 'react-helmet';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { default as packageJson } from '../package.json';
import { AppStyle } from './app.style';
import { Error } from './components';
import { FilesProvider } from './context';
import { Actions } from './redux';
import { setLanguage } from './redux/app';
import { RootState, store } from './redux/store';
import PublicRoutes from './router';
import { useLocalStorage } from './shared/hooks';
import translationEN from './translation/en.json';
import translationES from './translation/es.json';
import translationRU from './translation/ru.json';
import { Language } from './types';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      EN: { translation: translationEN },
      RU: { translation: translationRU },
      ES: { translation: translationES },
    },
    fallbackLng: 'EN',
    interpolation: {
      escapeValue: false,
    },
    lng: store.getState().App.language,
  });

store.subscribe(() => {
  i18n.changeLanguage(store.getState().App.language);
});

const App = () => {

  const dispatch = useDispatch();
  const userLoadStatus = useSelector((state: RootState) => state.Auth.userLoadStatus);
  const config = useSelector((state: RootState) => state.App.config);
  // const configStatus = useSelector((state: RootState) => state.App.configStatus);

  const [languageLS] = useLocalStorage('language', 'EN' as Language);

  const [browserApproved, setBrowserApproved] = useLocalStorage<boolean | undefined>('app.browserApproved', undefined, true);

  useEffect(() => {
    if (!browserApproved) {
      try {
        const browsers = packageJson?.browserslist[process.env.NODE_ENV || 'production'] as string[];
        if (!Browserslist.matchesUA(navigator.userAgent, { allowHigherVersions: true, browsers })) {
          const { family, version } = Browserslist.resolveUserAgent(navigator.userAgent);
          console.warn(`Possible unsupported browser ${family} version ${version}${browsers ? `. Supported browsers: ${browsers.join(', ')}` : ''}`);
          setBrowserApproved(false);
        } else {
          setBrowserApproved(true);
        }
      } catch (error) {
        console.error(error);
        setBrowserApproved(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [browserApproved]);

  useEffect(() => {
    if (userLoadStatus === 'new') {
      dispatch(Actions.Auth.authInitAction());
    }
  }, [userLoadStatus, dispatch]);

  useEffect(() => {
    if ( config === undefined ) {
      dispatch(Actions.App.loadConfig());
    }
  }, [config, dispatch]);

  useEffect(() => {
    dispatch(setLanguage(languageLS));
  }, [languageLS, dispatch]);

  // if (configStatus === 'error') {
  //   return <Error
  //     text={`Error while loading application`}
  //     skipText='Reload page'
  //     onSkip={() => location.reload()}
  //   />;
  // }

  if (browserApproved === false) {
    return <Error
      text={<>Sorry, your current browser's version doesn't<br/>support this page. Please update it and try again</>}
      onSkip={() => setBrowserApproved(true)}
    />;
  }

  return <AppStyle>
    <DndProvider backend={HTML5Backend}>
      <Helmet>
        <meta name='viewport' content='width=device-width, initial-scale=1, user-scalable=no'/>
      </Helmet>
      <I18nextProvider i18n={i18n}>
        <FilesProvider>
          <PublicRoutes googleTagId={config?.googleTag?.id} />
        </FilesProvider>
      </I18nextProvider>
    </DndProvider>
  </AppStyle>;
};

export default App;
