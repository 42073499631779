import React, { useCallback, useRef, useState } from 'react';
import { MenuItem } from '@mui/material';
import { StyledMenu } from './style';

interface Props {
  items: Array<{
    label: string;
    onClick: () => void;
  }>;
  children: React.ReactNode;
}

export const ContextMenu = ({ items, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div ref={ref} onClick={handleClick}>
        {children}
      </div>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {items.map((item, i) => (
          <MenuItem centerRipple key={i} onClick={() => {
            handleClose();
            item.onClick();
          }}>
            {item.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};
