export type ListOptions = {
  limit?: number;
  page?: number;
  order?: {
    field: string;
    order: 'asc' | 'desc';
  };
  q?: string;
  all?: boolean;
  fields?: string[];
}

export const genListQuery = (options: ListOptions, mode?: 'total') => {
  const params = new URLSearchParams();

  if (options.limit) {
    params.append('limit', options.limit.toString());
  }

  if (options.page) {
    params.append('page', options.page.toString());
  }

  if (options.q) {
    params.append('q', options.q);
  }

  if (options.order) {
    params.append('order', `${options.order.field}|${options.order.order}`);
  }

  if (options.fields) {
    params.append('fields', options.fields.join(','));
  }

  if (mode === 'total') {
    params.append('mode', 'total');
  }

  const query = params.toString();
  return query;
};
