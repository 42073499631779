import styled from 'styled-components';
import { theme } from '../../../../../../themes';

export const Container = styled.div`
  position: relative;

  color: ${theme.colors.black};
  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Select = styled.div<{opened: boolean}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > svg:first-of-type {
      border: 1px solid ${theme.colors.veryLightGray};
      margin-right: 8px;
      width: 27px;
      height: 20px;
    }
  }

  > svg:last-of-type {
    width: 11.3px;
    height: 6.7px;

    ${(p) => p.opened ? `
      transform: rotate(180deg);
    ` : ''}

    transition: all 0.2s ease-out;
  }
`;

export const Menu = styled.ul`
  position: absolute;
  top: 8px;
  right: 4px;
  padding: 0;
  width: 100%;

  > li {
    height: 27px;
    padding: 3.5px 4px;
    margin: 0;
    display: flex;
    align-items: center;
    list-style-type: none;

    :hover {
      background: ${theme.colors.veryLightGray};
      border-radius: 4px;
    }

    > svg {
      border: 1px solid ${theme.colors.veryLightGray};
      margin-right: 8px;
      width: 27px;
      height: 20px;
    }

    :hover {
      background: ${theme.colors.veryLightGray};
      cursor: pointer;
    }
  }
`;
