import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Node, NodesParts, NodesTitles, PriceListItem } from '../../../../types';
import { Brand, BrandField, BrandFieldValues, Car, CarInfo, CarRecord, CarShort } from '../../types';
export * from './actions';

type Status = 'loading' | 'done' | 'error';

interface State {
  vin?: string;
  carInfo?: CarInfo;
  carsToSelect?: CarInfo[];
  carId?: number;
  sessionId?: string;
  brandId?: number;
  brandsList: Brand[];
  brandFieldsValues?: BrandFieldValues;
  brandFieldsList?: BrandField[];
  car?: Car;
  foundByVin?: boolean;
  vinNotFound?: boolean;
  showSearchButton?: boolean;
  searchStatus?: Status;
  nodes: Node[];
  priceList?: PriceListItem[];
  priceListTotal?: number;
  loadingStatus?: Status;
  nodesParts?: NodesParts;
  nodesTitles?: NodesTitles;
  cars: CarRecord[];
  carsAll: CarRecord[];
  carsTotal?: number;
  carsAllTotal?: number;
  carsShort: CarShort[];
}

export const initialState: State = {
  vin: '',
  brandsList: [],
  nodes: [],
  cars: [],
  carsAll: [],
  carsShort: [],
};

const Slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setVin(state, action: PayloadAction<string>) {
      state.vin = action.payload;
    },
    setCarInfo(state, action: PayloadAction<CarInfo>) {
      state.carInfo = action.payload;
    },
    setCarsToSelect(state, action: PayloadAction<CarInfo[] | undefined>) {
      state.carsToSelect = action.payload;
    },
    setCarId(state, action: PayloadAction<number | undefined>) {
      state.carId = action.payload;
    },
    setSessionId(state, action: PayloadAction<string>) {
      state.sessionId = action.payload;
    },
    setBrandsList(state, action: PayloadAction<Brand[]>) {
      state.brandsList = action.payload;
    },
    setBrandId(state, action: PayloadAction<number | undefined>) {
      state.brandId = action.payload;
    },
    setBrandFieldsValues(state, action: PayloadAction<{[option: string]: string | undefined}>) {
      state.brandFieldsValues = action.payload;
    },
    setBrandFieldsList(state, action: PayloadAction<BrandField[]>) {
      state.brandFieldsList = action.payload;
    },
    clearBrandFields(state, action: PayloadAction<undefined>) {
      state.brandFieldsValues = undefined;
      state.brandFieldsList = undefined;
    },
    setCar(state, action: PayloadAction<Car>) {
      state.car = action.payload;
    },
    updateCar(state, action: PayloadAction<Partial<Car>>) {
      state.car = { ...state.car, ...action.payload };
    },
    setFoundByVin(state, action: PayloadAction<boolean>) {
      state.foundByVin = action.payload;
    },
    setVinNotFound(state, action: PayloadAction<boolean>) {
      state.vinNotFound = action.payload;
    },
    setShowSearchButton(state, action: PayloadAction<boolean>) {
      state.showSearchButton = action.payload;
    },
    clearBrandData(state, action: PayloadAction<undefined>) {
      state.brandId = undefined;
      state.brandFieldsValues = undefined;
      state.brandFieldsList = undefined;
    },
    setSearchStatus(state, action: PayloadAction<Status>) {
      state.searchStatus = action.payload;
    },
    setNodes(state, action: PayloadAction<Node[]>) {
      state.nodes = action.payload;
    },
    setPriceList(state, action: PayloadAction<PriceListItem[]>) {
      state.priceList = action.payload;
    },
    setPriceListTotal(state, action: PayloadAction<number | undefined>) {
      state.priceListTotal = action.payload;
    },
    setLoadingStatus(state, action: PayloadAction<Status>) {
      state.loadingStatus = action.payload;
    },
    setNodesParts(state, action: PayloadAction<NodesParts>) {
      state.nodesParts = action.payload;
    },
    setNodesTitles(state, action: PayloadAction<NodesTitles>) {
      state.nodesTitles = action.payload;
    },
    resetCar(state) {
      state.car = initialState.car;
      state.carId = initialState.carId;
      state.nodes = initialState.nodes;
    },
    setCars(state, action: PayloadAction<Car[]>) {
      state.cars = action.payload;
    },
    setCarsAll(state, action: PayloadAction<Car[]>) {
      state.carsAll = action.payload;
    },
    setCarsTotal(state, action: PayloadAction<number | undefined>) {
      state.carsTotal = action.payload;
    },
    setCarsAllTotal(state, action: PayloadAction<number | undefined>) {
      state.carsAllTotal = action.payload;
    },
    setCarsShort(state, action: PayloadAction<CarShort[]>) {
      state.carsShort = action.payload;
    },
  },
});

export const {
  setVin,
  setCarInfo,
  setCarId,
  setSessionId,
  setBrandId,
  setBrandsList,
  setBrandFieldsValues,
  setBrandFieldsList,
  clearBrandFields,
  setCar,
  updateCar,
  setFoundByVin,
  setVinNotFound,
  setShowSearchButton,
  clearBrandData,
  setSearchStatus,
  setNodes,
  setPriceList,
  setPriceListTotal,
  setCarsToSelect,
  setLoadingStatus,
  setNodesParts,
  setNodesTitles,
  resetCar,
  setCars,
  setCarsAll,
  setCarsTotal,
  setCarsAllTotal,
  setCarsShort,
} = Slice.actions;

export default Slice.reducer;
