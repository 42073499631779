import styled from 'styled-components';
import { theme } from '../../../../../../themes';

export const MenuStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  margin-left: 20px;

  color: ${theme.colors.black};

  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

export const MenuItem = styled.div<{selected?: boolean}>`
  cursor: pointer;
  position: relative;
  white-space: nowrap;

  ${(p) => p.selected ? `
    background-image: linear-gradient(to bottom, #F165224C, #F165224C);
    background-position: 1px 15px;
    background-size: 1px 8px;
    background-repeat: repeat-x;
  ` : ''}
`;

