import React from 'react';
import { ReactComponent as ArrowUpImage } from './images/arrow-up.svg';
import { ReactComponent as ArrowDownImage } from './images/arrow-down.svg';
import { Clear, Container, InputStyle } from './style';

interface Props {
  value?: number;
  maxValue?: number;
  label?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const InputNumeric = ({ value, maxValue, label, disabled, onChange }: Props) => {

  return <Container disabled={disabled}>
    <div>
      <div>
        <div onClick={((!maxValue || (value || 0) < maxValue) || disabled)? () => onChange((value || 0) + 1) : undefined}>
          <ArrowUpImage />
        </div>
        <hr />
        <div onClick={((value || 0) > 0 || disabled ) ? () => onChange((value || 0) - 1) : undefined}>
          <ArrowDownImage />
        </div>
      </div>
      <InputStyle
        value={value}
        onKeyUp={!disabled ? (value) => {
          if (Number.isFinite(+value.key) && (!maxValue || +value.key <= maxValue)) {
            onChange(+value.key);
          }
        } : undefined}
        type="number"
      />
      <Clear onClick={!disabled ? () => onChange(0) : undefined} />
    </div>
    {label && <div>{label}</div>}
  </Container>;
};
