import React, { memo, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ContextMenu, Input, Order, Pagination, PartDetailsMode, Table, TableItem } from '..';
import { Car, CarShort, ExportType, OEM, PriceListItem, Shop, partStatusTitles } from '../../../../types';
import { Image } from './image';
import { PriceListPopups } from './popups';
import { Container, DeleteIcon, DuplicateIcon, Text, ToolBar } from './style';

export type PartDetails = { id?: number, mode?: PartDetailsMode } | null;

export interface NewCar {
  carId?: number;
  carName: string;
  brand?: string;
}

export interface FilteredListItem extends Omit<PriceListItem, 'status'> {
  status: string;
}

interface Props {
  car?: Car;
  priceList: PriceListItem[];
  title?: string;
  page?: number;
  total?: number;
  limit?: number;
  order?: Order;
  searchText?: string;
  carsShort: CarShort[];
  shop?: Shop;
  setPriceList: (value: PriceListItem[]) => void;
  uploadFiles: (files: File[], callback: (urls: string[]) => void) => void;
  setSearchText?: (value: string) => void;
  storePart?: (part: Omit<PriceListItem, 'id'>, id?: number) => void;
  deletePart?: (id: number) => void;
  setPage?: (value: number) => void;
  setLimit?: (value: number) => void;
  setOrder?: (value?: Order) => void;
  reload?: () => void;
  reloadPriceList?: (callback?: () => void) => void;
}

export const PriceList = ({ car, priceList, title, page, total, limit, order, searchText, carsShort, shop,
  setPriceList, uploadFiles, setPage, setLimit, setOrder, setSearchText, storePart, deletePart, reload, reloadPriceList }: Props) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<number[]>([]);
  const [toDelete, setToDelete] = useState<number[] | null>(null);
  const [toChangeStatus, setToChangeStatus] = useState<number[] | null>(null);
  const [toChangeStorageCell, setToChangeStorageCell] = useState<number[] | null>(null);
  const [toChangePartDetails, setToChangePartDetails] = useState<PartDetails>(null);
  const [showSavedPopup, setShowSavedPopup] = useState(false);
  const [showExportPopup, setShowExportPopup] = useState<ExportType | false>(false);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [showReloadConfirmation, setShowReloadConfirmation] = useState(false);

  const mainOem = useCallback((oems: OEM[], firstIfNoMain?: boolean) => (oems.find((item) => item.isMain))?.oem || (firstIfNoMain ? oems?.[0]?.oem : undefined), []);

  const filteredList = useMemo(() => priceList
    .filter((item) => !search || item.name.toLowerCase().includes(search?.toLowerCase?.()))
    .map((item) => ({
      ...item,
      image: item.images?.[0] || car?.images?.[0],
      status: item?.status ? t(partStatusTitles[item?.status]) : '',
      oemsList: `${mainOem(item.oems, true) || ''}${item.oems.length > 1 ? ` (+${item.oems.length - 1})` : ''}`,
    })), [priceList, search, car?.images, t, mainOem]);

  const setImage = useCallback((file: File[], id: number) => {
    uploadFiles(file, (urls) => {
      setPriceList(priceList.map((item) => item.id === id ? { ...item, images: urls } : item));
    });
  }, [priceList, setPriceList, uploadFiles]);

  const tableColumns = useMemo(() => [
    { title: '#', key: 'id' },
    { title: 'Photo', icon: (item: TableItem) => <Image src={item?.images?.[0] as string} setImage={(file) => setImage(file, item?.id as number)} />, width: '98px', centered: true },
    { title: 'Description', key: 'name', width: '382px' },
    { title: 'Quantity', key: 'quantity', width: '121px', centered: true },
    { title: 'Automobile', key: 'carName', width: '143px' },
    { title: 'Price', key: 'price', width: '90px', centered: true },
    { title: 'OEM Part Number', key: 'oemsList', width: '191px' },
    { title: 'Status', key: 'status', width: '100px', centered: true },
    { title: 'Storage cell', key: 'storageCell', width: '142px', centered: true },
    { title: '', icon: <DuplicateIcon />, width: '55px', centered: true, onClick: (id: string) => setToChangePartDetails({ id: +id, mode: 'duplicate' }) },
    { title: '', icon: <DeleteIcon />, width: '55px', centered: true, onClick: (id: string) => setToDelete([+id]) },
  ], [setImage]);

  const contextMenuExport = useMemo(() => [{
    label: t('Export to Excel'),
    onClick: () => setShowExportPopup('excel'),
  }, {
    label: t('Export to Avito'),
    onClick: () => setShowExportPopup('avito'),
  }], [t]);

  const contextMenuBulkActions = useMemo(() => [{
    label: t('Delete selected'),
    onClick: () => setToDelete(selected),
  }, {
    label: t('Change status'),
    onClick: () => setToChangeStatus(selected),
  }, {
    label: t('Change storage cell'),
    onClick: () => setToChangeStorageCell(selected),
  }, ...contextMenuExport], [contextMenuExport, selected, t]);

  const newCar = useMemo(() => ({
    carId: car?.id,
    carName: `${car?.brandName || ''} ${car?.modelName || ''} ${car?.year || ''}`.trim(),
    brand: car?.brandName,
  }), [car]);

  const PaginationComponent = memo(() => <>
    {setPage && page !== undefined && total !== undefined && limit !== undefined &&
      <Pagination
        page={page}
        totalPages={Math.ceil(total / limit)}
        itemsPerPage={limit}
        totalItems={total}
        setPage={setPage}
        setItemsPerPage={(itemsPerPage: number) => setLimit?.(itemsPerPage)}
      />}
  </>);

  const priceListPopupsProps = useMemo(() => ({
    priceList, toDelete, toChangeStatus, toChangeStorageCell, toChangePartDetails, showSavedPopup,
    showExportPopup, showImportPopup, showReloadConfirmation, newCar, carsShort, selected, filteredList, shop, setPriceList, setToDelete,
    setToChangeStatus, setToChangeStorageCell, setToChangePartDetails, setShowSavedPopup, setShowExportPopup, setShowImportPopup,
    setShowReloadConfirmation, uploadFiles, storePart, reload, deletePart, reloadPriceList,
  }), [carsShort, filteredList, newCar, priceList, selected, shop, showExportPopup,
    showImportPopup, showReloadConfirmation, showSavedPopup, toChangePartDetails, toChangeStatus, toChangeStorageCell,
    toDelete, setPriceList, storePart, uploadFiles, reloadPriceList, deletePart, reload]);

  return <>
    <PriceListPopups {...priceListPopupsProps} />
    <Container>
      {title && <Text marginBottom={32}>{title}</Text>}
      <ToolBar>
        <PaginationComponent />
        {!setSearchText && <Input margin='0 0 0 auto' placeholder='Content search' icon='search' small flat value={search} onChange={(value) => setSearch(value)} width='230px' />}
        {setSearchText && <Input margin='0 0 0 auto' placeholder='Content search' icon='search' small flat value={searchText} onChange={(value) => setSearchText(value)} width='230px' />}
        <Button width='180px' iconRight='add' onClick={() => setToChangePartDetails({ mode: 'new' })}>{t('Add')}</Button>
        <ContextMenu items={contextMenuBulkActions}>
          <Button width='160px' disabled={selected.length === 0}>{t('Bulk actions')}</Button>
        </ContextMenu>
        <ContextMenu items={contextMenuExport}>
          <Button width='160px' iconRight='export'>{t('Export')}</Button>
        </ContextMenu>
        <Button iconRight='import' onClick={() => setShowImportPopup(true)} />
        {reloadPriceList && <Button iconRight='refresh' onClick={() => setShowReloadConfirmation(true)} />}
      </ToolBar>
      <Table
        columns={tableColumns}
        data={filteredList}
        selected={selected}
        order={order}
        margin='12px 0'
        onRowClick={(id: string) => setToChangePartDetails({ id: +id })}
        setSelected={(ids: number[]) => setSelected(ids)}
        setOrder={setOrder}
      />
      <PaginationComponent />
    </Container>
  </>;
};
