import React, { createContext, useState, useContext, ReactNode, useCallback } from 'react';

export interface FilesContextType {
  files: File[];
  addFiles: (newFiles: File[]) => void;
  moveFiles: (dragIndex: number, hoverIndex: number) => void;
  removeFile: (index: number) => void;
  clearFiles: () => void;
}

const FilesContext = createContext<FilesContextType | null>(null);

export const useFilesContext = () => {
  const context = useContext(FilesContext);
  if (context === undefined) {
    throw new Error('useFilesContext must be used within a FilesProvider');
  }
  return context;
};

interface FilesProviderProps {
  children: ReactNode;
}

export const FilesProvider = ({ children }: FilesProviderProps) => {
  const [files, setFiles] = useState<File[]>([]);

  const addFiles = useCallback((newFiles: File[]) => {
    setFiles([...files, ...newFiles]);
  }, [files]);

  const moveFiles = useCallback((dragIndex, hoverIndex) => {
    setFiles((prev) => {
      const cloned = [...prev];
      const removedItem = cloned.splice(dragIndex, 1)[0];
      cloned.splice(hoverIndex, 0, removedItem);
      return cloned;
    });
  }, []);

  const removeFile = useCallback((index: number) => {
    setFiles([...files.slice(0, index), ...files.slice(index + 1)]);
  }, [files]);

  const clearFiles = useCallback(() => {
    setFiles([]);
  }, []);

  return (
    <FilesContext.Provider value={{ files, addFiles, moveFiles, removeFile, clearFiles }}>
      {children}
    </FilesContext.Provider>
  );
};
