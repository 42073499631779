import styled from 'styled-components';
import { theme } from '../../themes';

export const Style = styled.div<{error?: boolean}>`
  color: ${(p) => p.error ? theme.colors.vividRed : theme.colors.grayishBlue};

  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;
