import styled from 'styled-components';
import { theme } from '../../../../themes';

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: baseline;
  gap: 24px;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  
  > div {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
`;

export const Divider = styled.hr`
  color: ${theme.colors.grayishBlue};
  border: 1px solid ${theme.colors.grayishBlue};
  margin: 0 16px;
`;
