import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AddPicture, ColorPicker, Dropdown, Input, Selector, WheelDriveSelect, Label } from '..';
import { getTailgateAndDoorsByBody } from '../../shared';
import { BodyType, Car, Color, Doors, Fuel, Steering, Tailgate, Transmission, WheelDrive, colorName, fuelList, transmissionList } from '../../types';
import { Form, Pair, PairWithLabels, Text } from './style';

const currentYear = +(new Date()).getFullYear();
const years = Object.keys([...new Array(30)]).map((num) => currentYear - +num);

interface Props {
  car: Car;
  foundByVin?: boolean;
  showError?: boolean;
  addCar?: boolean;
  updateCar: (car: Partial<Car>) => void;
}

export const Customize = ({ car, foundByVin, showError, addCar, updateCar }: Props) => {
  const { t } = useTranslation();

  const [doorsChangedByUser, setDoorsChangedByUser] = useState(false);
  const [tailgateChangedByUser, setTailgateChangedByUser] = useState(false);

  return <>
    {addCar && <Text marginBottom={24}>{t('Select the parameters of your car')}</Text>}
    <Form>
      <Pair>
        <Input placeholder='Brand' value={car.brandName} onChange={(value) => updateCar({ brandName: value })} width='100%' error={showError && !car.brandName} />
        <Input placeholder='VIN number' disabled={foundByVin} value={car.vin} onChange={(value) => updateCar({ vin: value })} width='100%' />
      </Pair>
      <Pair>
        <Input placeholder='Model' value={car.modelName} onChange={(value) => updateCar({ modelName: value })} width='100%' error={showError && !car.modelName} />
        <Input placeholder='Modification / version' value={car.modification} onChange={(value) => updateCar({ modification: value })} width='100%' />
      </Pair>
      <Pair>
        <Dropdown
          width='100%'
          placeholder='Year'
          inputPlaceholder='Choose your car year'
          value={car.year || ''}
          options={years?.map((year) => ({ value: year.toString(), label: year.toString() }))}
          maxHeight={358}
          onChange={(value) => updateCar({ year: value })}
          error={showError && !car.year}
          search
        />
        <Dropdown
          width='100%'
          placeholder='Fuel'
          inputPlaceholder='Choose your car fuel'
          value={car.fuel || ''}
          options={fuelList?.map(({ id, name }) => ({ value: id.toString(), label: t(name) }))}
          maxHeight={358}
          onChange={(value) => updateCar({ fuel: value as Fuel })}
          error={showError && !car.fuel}
        />
      </Pair>
      <Pair>
        <Input placeholder='Engine type' value={car.engineModel} onChange={(value) => updateCar({ engineModel: value })} width='100%' />
        <Input placeholder= 'Engine power' value={car.enginePower?.toString?.( ) || ''} onChange={(value) => updateCar({ enginePower: value ? +value: undefined })} width='100%'/>
      </Pair>
      <Pair>
        <Dropdown
          width='100%'
          placeholder='Transmission'
          inputPlaceholder='Choose your car transmission'
          value={car.transmission || ''}
          options={transmissionList?.map(({ id, name }) => ({ value: id.toString(), label: t(name) }))}
          maxHeight={358}
          onChange={(value) => updateCar({ transmission: value as Transmission })}
          error={showError && !car.transmission}
        />
        <Input placeholder='Transmission model' value={car.transmissionModel} onChange={(value) => updateCar({ transmissionModel: value })} width='100%' />
      </Pair>
      <Pair>
        <Input placeholder='Price' value={car.price?.toString?.() || ''} onChange={(value) => updateCar({ price: value ? +value : undefined })} width='100%' />
        <Input placeholder='Odometer' value={car.odometer?.toString?.() || ''} onChange={(value) => updateCar({ odometer: value ? +value : undefined })} width='100%' />
      </Pair>
      <Pair>
        <Input placeholder='Color' disabled value={car.color ? t(colorName[car.color]) : ''}  onChange={(value) => updateCar({ color: value as Color })} width='100%'/>
        <Input placeholder='Color code' value={car.colorCode} onChange={(value) => updateCar({ colorCode: value })} width='100%'/>
      </Pair>
      <Label error={showError && !car.color}>{t('Color')}</Label>
      <ColorPicker selected={car.color} setSelected={(value) => updateCar({ color: value as Color })} />
      <Label error={showError && !car.wheelDrive}>{t('Wheel drive')}</Label>
      <WheelDriveSelect selected={car.wheelDrive} setSelected={(value) => updateCar({ wheelDrive: value as WheelDrive })} />
      <PairWithLabels>
        <div>
          <Label error={showError && !car.body}>{t('Car body type')}</Label>
          <Selector type='body' selected={car.body} setSelected={(value) => updateCar({
            body: value as BodyType,
            doors: doorsChangedByUser ? car.doors : getTailgateAndDoorsByBody(value as BodyType)?.doors,
            tailgate: tailgateChangedByUser ? car.tailgate : getTailgateAndDoorsByBody(value as BodyType)?.tailgate,
          })} />
        </div>
        <div>
          <Label error={showError && !car.body}>{t('Tailgate')}</Label>
          <Selector type='tailgate' selected={car.tailgate} rightAlignment setSelected={(value) => {
            updateCar({ tailgate: value as Tailgate });
            setTailgateChangedByUser(true);
          }} />
        </div>
      </PairWithLabels>
      <PairWithLabels>
        <div>
          <Label error={showError && !car.doors}>{t('Doors')}</Label>
          <Selector type='doors' selected={car.doors} setSelected={(value) => {
            updateCar({ doors: value as Doors });
            setDoorsChangedByUser(true);
          }} />
        </div>
        <div>
          <Label error={showError && !car.steering}>{t('Steering')}</Label>
          <Selector type='steering' selected={car.steering} setSelected={(value) => updateCar({ steering: value as Steering })} />
        </div>
      </PairWithLabels>
      <Label>{t('Upload photos')}</Label>
      <AddPicture />
    </Form>
  </>;
};
