import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config, Export, Language, LoadStatus, Shop, User } from '../../../../types';
export * from './actions';

interface State {
  config?: Config;
  configStatus: LoadStatus;
  language: Language;
  users: User[];
  usersLoadStatus: LoadStatus;
  exports?: Export[];
  shop?: Shop;
}

export const initialState: State = {
  configStatus: 'new',
  language: 'EN',
  users: [],
  usersLoadStatus: 'new',
};

const Slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    setConfig(state, action: PayloadAction<Config>) {
      state.config = action.payload;
    },
    setConfigStatus(state, action: PayloadAction<LoadStatus>) {
      state.configStatus = action.payload;
    },
    setLanguage(state, action: PayloadAction<Language>) {
      state.language = action.payload;
    },
    setUsers(state, action: PayloadAction<User[]>) {
      state.users = action.payload;
    },
    setUsersLoadStatus(state, action: PayloadAction<LoadStatus>) {
      state.usersLoadStatus = action.payload;
    },
    setExports(state, action: PayloadAction<Export[]>) {
      state.exports = action.payload;
    },
    setShop(state, action: PayloadAction<Shop | null>) {
      state.shop = action.payload || undefined;
    },
  },
});

export const {
  setConfig,
  setConfigStatus,
  setLanguage,
  setUsers,
  setUsersLoadStatus,
  setExports,
  setShop,
} = Slice.actions;

export default Slice.reducer;
