import * as Auth from './auth';
import * as App from './app';
import * as Car from './car';
export * from './store';

export const Actions = {
  Auth,
  App,
  Car,
};
