/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as xlsx from 'xlsx';
import { Button, Popup } from '../../..';
import { Shop } from '../../../../../../types';
import { Actions } from '../../../../redux';
import { Buttons, Label } from '../../style';
import { avitoExportFieldTitles, avitoImportStatFieldsList, importAvito, ImportAvitoStat } from '../../../../shared/avito/import';
import { Dropzone, Statistics } from './style';

interface Props {
  onClose: () => void;
}

export const ImportDialog = ({ onClose }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>();
  const [stat, setStat] = useState<ImportAvitoStat | null>();

  const updateShop = useCallback((shop: Partial<Shop>) => dispatch(Actions.App.storeShop(shop)), [dispatch]);

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item) => setFile((item as { files: File[] }).files[0]),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const importData = useCallback(() => {
    (async () => {
      if (!file) {
        return;
      }

      const reader = new FileReader();

      const data = await new Promise((resolve, reject) => {
        const process = (result) => result && typeof result !== 'string' ? new Uint8Array(result) : null;
        reader.onload = (event) => resolve(process(event.target?.result));
        reader.onerror = (error) => reject(error);
        reader.readAsArrayBuffer(file);
      });

      const workbook = xlsx.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows = xlsx.utils.sheet_to_json(sheet, { header: 1 });

      const stat = await importAvito(rows as string[][], updateShop);

      setStat(stat);
    })();
  }, [file, updateShop]);

  return <Popup width='610px' onClose={onClose}>
    <Label bold margin='0 0 24px 0'>{!stat ? t(`Import from Avito`) : t(`Import from Avito statistics`)}</Label>
    {!stat && <Dropzone ref={drop} hover={isOver}>
      <input
        type='file'
        accept='.xls,.xlsx'
        multiple
        onChange={(event) => {
          const data = Array.from((event.target?.files || []));
          setFile(data[0]);
        }}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      {<div>
        <span>{!file ? t('Drop file here or') : file.name}</span>
        <Button
          margin='0 0 0 12px'
          secondary={!!file}
          onClick={() => !file ? fileInputRef.current?.click?.() : setFile(null)}
        >{!file ? t('Browse files') : t('Clear')}</Button>
      </div>}
    </Dropzone>}
    {stat && <Statistics>
      {avitoImportStatFieldsList.map((field) => <div key={field}>
        <span>{t(avitoExportFieldTitles[field])}</span>
        <span>{stat[field]}</span>
      </div>)}
    </Statistics>}
    <Buttons margin='24px 0 0 0'>
      <Button onClick={() => onClose()} secondary>{!stat ? 'Cancel' : 'Close'}</Button>
      <Button disabled={!stat && !file} onClick={!stat ? () => importData() : () => { setStat(null); setFile(null); }}>{!stat ? 'Import' : 'Import another file'}</Button>
    </Buttons>
  </Popup>;
};

