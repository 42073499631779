import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Background, Content, Close } from './style';

interface Props {
  children: React.ReactNode | React.ReactNode[];
  width?: string;
  height?: string;
  onClose: () => void;
  onEnter?: () => void;
}

export const Popup = ({ children, width, height, onClose, onEnter }: Props) => {
  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose?.();
    }
  };

  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      if ( e.key === 'Escape' ) {
        onClose();
      } else if ( e.key === 'Enter' ) {
        onEnter?.();
      }
    };
    window.addEventListener('keydown', handler);
    return () => window.removeEventListener('keydown', handler);
  }, [onClose, onEnter]);

  return ReactDOM.createPortal(<Background onClick={handleBackgroundClick}>
    <Content width={width} height={height}>
      {children}
      <Close onClick={onClose} />
    </Content>
  </Background>, document.body);
};
