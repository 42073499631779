import React, { useCallback, useMemo, useState } from 'react';
import { OEM } from '../../../../../../../../types';
import { Table } from '../../../../../table';
import { Title } from '../../../../style';
import { Content, DeleteIcon, Image } from './style';

interface Props {
  oems: Array<OEM>;
  setOems: (oems: Array<OEM>) => void;
}

export const OEMs = ({ oems, setOems }: Props) => {
  const [selected, setSelected] = useState(0);

  const selectedData = useMemo(() => oems.find((_, i) => i === selected), [oems, selected]);

  const fieldChange = useCallback((value: string | number, id: number, field: string) => {
    setOems(oems.map((item, i) => i === id ? { ...item, [field]: value } : item));
  }, [oems, setOems]);

  const fieldChangeBoolean = useCallback((value: string | number, id: number, field: string) => {
    setOems(oems.map((item, i) => i === id ? { ...item, [field]: value === 'true' } : { ...item, [field]: value === 'true' ? false : item[field] }));
  }, [oems, setOems]);

  const tableColumns = useMemo(() => [
    { title: 'Main', key: 'isMain', width: '75px', centered: true, checkbox: true, onChange: fieldChangeBoolean },
    { title: 'Num', key: 'codeOnImage', width: '75px', centered: true },
    { title: 'OEM', key: 'oem', width: '200px', centered: true, onChange: fieldChange },
    { title: 'Name', key: 'partName', width: '275px', onChange: fieldChange },
    { title: 'Q-ty', key: 'quantity', width: '50px', centered: true, onChange: fieldChange },
    { title: '', icon: <DeleteIcon />, width: '55px', centered: true, onClick: (id: string) => setOems(oems.filter((_, i) => i !== +id)) },
  ], [oems, fieldChange, fieldChangeBoolean, setOems]);

  return <Content>
    <div>
      <Table
        columns={tableColumns}
        data={oems?.map((item, id) => ({ ...item, id })) || []}
        onRowClick={(id) => setSelected(+id)}
      />
    </div>
    <div>
      <Title small>{selectedData?.imageName}</Title>
      {selected !== undefined && selectedData?.image ? <Image
        background={selectedData.image}
        onClick={() => window.open(selectedData.image as string, '_blank')}
      /> : <span>No image</span>}
    </div>
  </Content>;
};
