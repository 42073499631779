import { BodyType, Doors, Steering, Tailgate } from '../../types';
import Doors2 from './images/doors/2.png';
import Doors3 from './images/doors/3.png';
import Doors4 from './images/doors/4.png';
import Doors5 from './images/doors/5.png';
import Doors6 from './images/doors/6.png';
import SteeringLeft from './images/steering/left.png';
import SteeringRight from './images/steering/right.png';
import BodyHatchback from './images/body/hatchback.png';
import BodyCoupe from './images/body/coupe.png';
import BodyPickup from './images/body/pickup.png';
import BodySedan from './images/body/sedan.png';
import BodyLiftback from './images/body/liftback.png';
import BodyCabriolet from './images/body/cabriolet.png';
import BodyWagon from './images/body/wagon.png';
import BodyVan from './images/body/van.png';
import BodyTruck from './images/body/truck.png';
import BodySUV from './images/body/suv.png';
import TailgateBackDoor from './images/tailgate/back-door.png';
import TailgateTrunkLid from './images/tailgate/trunk-lid.png';
import Tailgate2DoorsVertical from './images/tailgate/2-doors-vertical.png';
import Tailgate2DoorsHorizontal from './images/tailgate/2-doors-horizontal.png';
import DoorSideFL from './images/door-side/FL.png';
import DoorSideFR from './images/door-side/FR.png';
import DoorSideRL from './images/door-side/RL.png';
import DoorSideRR from './images/door-side/RR.png';
import DoorSideALL from './images/door-side/ALL.png';
import WheelSideFL from './images/wheel-side/FL.png';
import WheelSideFR from './images/wheel-side/FR.png';
import WheelSideRL from './images/wheel-side/RL.png';
import WheelSideRR from './images/wheel-side/RR.png';
import WheelSideALL from './images/wheel-side/ALL.png';
import Left from './images/left-right/left.png';
import Right from './images/left-right/right.png';
import Front from './images/front-rear/front.png';
import Rear from './images/front-rear/rear.png';
import Upper from './images/upper-lower/upper.png';
import Lower from './images/upper-lower/lower.png';
import Center from './images/center/center.png';
import { PartSide, PositionCenter, PositionFrontRear, PositionLeftRight, PositionUpperLower } from '../../../../types';

interface ItemBase {
  image: string;
  label?: string;
}

export interface ItemDoors extends ItemBase {
  value: Doors;
}

export interface ItemSteering extends ItemBase {
  value: Steering;
}

export interface ItemBody extends ItemBase {
  value: BodyType;
}

export interface ItemTailgate extends ItemBase {
  value: Tailgate;
}

export interface ItemSide extends ItemBase {
  value: PartSide;
}

export interface ItemLeftRight extends ItemBase {
  value: PositionLeftRight;
}

export interface ItemFrontRear extends ItemBase {
  value: PositionFrontRear;
}

export interface ItemUpperLower extends ItemBase {
  value: PositionUpperLower;
}

export interface ItemCenter extends ItemBase {
  value: PositionCenter;
}

interface Items {
  doors: ItemDoors[];
  steering: ItemSteering[];
  body: ItemBody[];
  tailgate: ItemTailgate[];
  'door-side': ItemSide[];
  'wheel-side': ItemSide[];
  'left-right': ItemLeftRight[];
  'front-rear': ItemFrontRear[];
  'upper-lower': ItemUpperLower[];
  'center': ItemCenter[];
}

export const items: Items = {
  doors: [{
    image: Doors2,
    label: '2 doors',
    value: '2',
  }, {
    image: Doors3,
    label: '3 doors',
    value: '3',
  }, {
    image: Doors4,
    label: '4 doors',
    value: '4',
  }, {
    image: Doors5,
    label: '5 doors',
    value: '5',
  }, {
    image: Doors6,
    label: '6 doors',
    value: '6',
  }],
  steering: [{
    image: SteeringLeft,
    label: 'Left',
    value: 'left',
  }, {
    image: SteeringRight,
    label: 'Right',
    value: 'right',
  }],
  body: [{
    image: BodyHatchback,
    label: 'Hatchback',
    value: 'hatchback',
  }, {
    image: BodyCoupe,
    label: 'Coupe',
    value: 'coupe',
  }, {
    image: BodyPickup,
    label: 'Pickup',
    value: 'pickup',
  }, {
    image: BodySedan,
    label: 'Sedan',
    value: 'sedan',
  }, {
    image: BodyLiftback,
    label: 'Liftback',
    value: 'liftback',
  }, {
    image: BodyCabriolet,
    label: 'Cabriolet',
    value: 'cabriolet',
  }, {
    image: BodyWagon,
    label: 'Wagon',
    value: 'wagon',
  }, {
    image: BodyVan,
    label: 'Van',
    value: 'van',
  }, {
    image: BodyTruck,
    label: 'Truck',
    value: 'truck',
  }, {
    image: BodySUV,
    label: 'SUV',
    value: 'suv',
  }],
  tailgate: [{
    image: TailgateBackDoor,
    label: 'Back door',
    value: 'back-door',
  }, {
    image: TailgateTrunkLid,
    label: 'Trunk lid',
    value: 'trunk-lid',
  }, {
    image: Tailgate2DoorsVertical,
    label: '2 doors vertical',
    value: '2-doors-vertical',
  }, {
    image: Tailgate2DoorsHorizontal,
    label: '2 doors horizontal',
    value: '2-doors-horizontal',
  }],
  'door-side': [{
    image: DoorSideALL,
    value: 'ALL',
  }, {
    image: DoorSideFL,
    value: 'FL',
  }, {
    image: DoorSideFR,
    value: 'FR',
  }, {
    image: DoorSideRL,
    value: 'RL',
  }, {
    image: DoorSideRR,
    value: 'RR',
  }],
  'wheel-side': [{
    image: WheelSideALL,
    value: 'ALL',
  }, {
    image: WheelSideFL,
    value: 'FL',
  }, {
    image: WheelSideFR,
    value: 'FR',
  }, {
    image: WheelSideRL,
    value: 'RL',
  }, {
    image: WheelSideRR,
    value: 'RR',
  }],
  'left-right': [{
    image: Left,
    value: 'left',
    label: 'Left',
  }, {
    image: Right,
    value: 'right',
    label: 'Right',
  }],
  'front-rear': [{
    image: Front,
    value: 'front',
    label: 'Front',
  }, {
    image: Rear,
    value: 'rear',
    label: 'Rear',
  }],
  'upper-lower': [{
    image: Upper,
    value: 'upper',
    label: 'Upper',
  }, {
    image: Lower,
    value: 'lower',
    label: 'Lower',
  }],
  'center': [{
    image: Center,
    value: 'center',
    label: 'Center',
  }],
};
