import styled from 'styled-components';

export const Content = styled.div`
  max-height: calc(100vh - 300px);
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0;
  padding: 5px;
`;
