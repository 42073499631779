/* eslint-disable no-unused-vars */

export const nodeNameList = ['engine', 'luggage-door', 'doors', 'wheels', 'clutch',
  'pedals', 'steering-rack', 'amortization-strut', 'steering-knuckle'] as const;
export type NodeName = typeof nodeNameList[number];
export type PartName = string; // названия частей узлов

interface WheelParams {
  count?: number;
  dismantlingType?: DismantlingType;
  complect?: boolean;
}

export type RequestPriceList = {
  [name: string]: {
    parts?: {
      [name in PartName]: boolean | [boolean, boolean, boolean, boolean] | WheelParams;
    };
  }
};

export const dismantlingTypeList = ['whole' , 'apart' , 'missing'] as const;
export type DismantlingType = typeof dismantlingTypeList[number];
export const nodeTypeList = ['single', '4-sides', 'wheels'] as const;
export type NodeType = typeof nodeTypeList[number];

export interface Node {
  name: NodeName;
  done?: boolean;
  type: NodeType;
  partsSingle?: {
    [name in PartName]: DismantlingType | undefined;
  };
  parts4Side?: {
    [name in PartName]: [DismantlingType | undefined, DismantlingType | undefined, DismantlingType | undefined, DismantlingType | undefined];
  };
  partsWheels?: {
    [name in PartName]: WheelParams;
  };
}

export type NodesPartsResponse = {
  [name in NodeName]: {
    id: number;
    title: string;
    type: NodeType;
    parts: Array<{
      name: string;
      title: string;
    }>;
  };
};

export interface Part {
  name: string;
  title: string;
}

export type NodesParts = {
  // TBD fix type
  // [name in NodeName]: Array<Part>;
  [name: string]: Array<Part>;
};

export type NodesTitles = {
  [name: string]: string;
}

export const partSideList = ['FL', 'FR', 'RL', 'RR', 'ALL'] as const;
export type PartSide = typeof partSideList[number];

export const partSideNames = {
  'FL': 'Front left',
  'FR': 'Front right',
  'RL': 'Rear left',
  'RR': 'Rear right',
  'ALL': 'All'
};
