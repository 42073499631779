import { TableBody, TableHead, TableRow } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { valueToString } from '../../shared';
import { Checkbox } from '../checkbox';
import { Dropdown, DropdownOption } from '../dropdown';
import { Input } from '../input';
import { CellStyle, Sort, TableStyle } from './style';

export interface Order {
  field: string;
  order: 'asc' | 'desc';
}

const defineSort = (field?: string, sort?: Order | null) => {
  if (!field) {
    return;
  }

  if (sort?.order === 'asc') {
    return { field, order: 'desc' as const };
  } else if (sort?.order === 'desc') {
    return;
  } else {
    return { field, order: 'asc' as const };
  }
};

export interface TableItem {
  [name: string]: string | string[] | number | null | undefined;
}

interface Column {
  title?: string;
  key?: string;
  width?: string;
  icon?: React.ReactNode | ((src: string) => React.ReactNode);
  centered?: boolean;
  dropdownOptions?: DropdownOption[];
  checkbox?: boolean;
  onClick?: (id: string, e) => void;
  onChange?: (value: string, id: number, field: string) => void;
}

interface Props {
  columns: Array<Column>;
  // TBD: add type
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Array<any>;
  selected?: number[];
  order?: Order;
  margin?: string;
  setSelected?: (value: number[]) => void;
  onRowClick?: (id: string) => void;
  setOrder?: (value?: Order) => void;
}

export const Table = ({ columns, data, selected, order, margin, setSelected, onRowClick, setOrder }: Props) => {
  const { t } = useTranslation();

  const [sort, setSort] = useState<Order | undefined>(order);

  const sorting = useCallback((a, b) => {
    if (!sort?.field) {
      return 0;
    }

    if ((a[sort.field] < b[sort.field] && sort.order === 'asc') || (a[sort.field] > b[sort.field] && sort.order === 'desc')) {
      return -1;
    } else if ((a[sort.field] > b[sort.field] && sort.order === 'asc') || (a[sort.field] < b[sort.field] && sort.order === 'desc')) {
      return 1;
    } else {
      return 0;
    }
  }, [sort]);

  const sorted = useMemo(() => ((!order && sort) ? [...data].sort(sorting) : data) || [], [sort, order, data, sorting]);

  useEffect(() => {
    setSort(order);
  }, [order, setSort]);

  const genId = useCallback((i: number, row: TableItem) => {
    const checkBoxKey = columns.find((column) => column.checkbox)?.key;

    if (!checkBoxKey) {
      return i.toString();
    } else {
      return `${i}${row[checkBoxKey]}`;
    }
  }, [columns]);

  return (
    <TableStyle clickable={!!onRowClick} margin={margin}>
      <TableHead>
        <TableRow>
          {selected && <CellStyle centered><Checkbox
            small
            value={selected.length === data.length}
            onChange={(value) => setSelected?.(value ? data.map(({ id }) => id) : [])}
          /></CellStyle>}
          {columns.map((column, i) => <CellStyle
            key={i}
            centered={column.centered}
            header
            sort={!!column.key && sort?.field === column.key}
            sortable={!!column.key}
            onClick={column.key ? () => (setOrder ? setOrder(defineSort(column.key, sort)) : setSort(defineSort(column.key, sort))) : undefined}
          >
            <div>
              {column.key && sort?.field === column.key && <Sort order={sort?.order} />}
              {t(column.title || '')}
            </div>
          </CellStyle>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {sorted.map((row, i) => <TableRow key={genId(i, row)}>
          {selected && <CellStyle centered><Checkbox
            key={i}
            small
            value={selected.includes(row.id)}
            onChange={(value) => setSelected?.(value ? [...selected, row.id] : selected.filter((id) => row.id !== id))}
          /></CellStyle>}
          {columns.map((column, j) => <CellStyle
            key={j}
            centered={column.centered || !!column.icon}
            width={column.width}
            clickable={column.onClick ? true : false}
            onClick={(e) => {
              if (column.checkbox) {
                return;
              }

              e.stopPropagation();
              e.preventDefault();

              if (column?.onClick) {
                column?.onClick(row.id, e);
              } else {
                onRowClick?.(row.id);
              }
            }}
          >
            {column.icon && typeof column.icon !== 'function' && <div>{column.icon}</div>}
            {column.icon && typeof column.icon === 'function' && <div>{column.icon((row))}</div>}
            {column.onChange && !column.dropdownOptions && !column.checkbox && <Input
              value={row[column.key || '']}
              onChange={(value) => column?.onChange?.(value, row.id, column.key || '')}
              centered={column.centered}
              width='100%'
              inline
            />}
            {column.onChange && column.dropdownOptions && <Dropdown
              value={row[column.key || '']}
              options={column.dropdownOptions}
              onChange={(value) => column?.onChange?.(value, row.id, column.key || '')}
              autoDropdownWidth
              inline
            />}
            {column.onChange && column.checkbox && <Checkbox
              value={row[column.key || '']}
              onChange={(value) => column?.onChange?.(value.toString(), row.id, column.key || '')}
              noPadding
              small
            />}
            {!column.icon && !column.onChange && column.key && <div title={valueToString(row[column.key])}>{valueToString(row[column.key])}</div>}
          </CellStyle>)}
        </TableRow>)}
      </TableBody>
    </TableStyle>
  );
};

