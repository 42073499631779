import { Node, PriceListItem, priceListFields, userFields, User, Export, exportFields } from '../../../types';
import { Car, carFields } from '../types';

export const clearCar = (car?: Car) => Object.fromEntries(Object.entries(car || {}).filter(([name, value]) => !!value && carFields.includes(name)).map(([name, value]) => [name, value]));

export const clearNodes = (nodes?: Node[]) => nodes?.map((node) => ({
  name: node.name,
  partsSingle: node.partsSingle,
  parts4Side: node.parts4Side,
  partsWheels: node.partsWheels,
})) || [];

export const clearPart = (part?: Partial<PriceListItem>, addFields?: string[]) => Object.fromEntries(Object.entries(part || {}).filter(([name, value]) => value !== undefined && [...priceListFields, ...(addFields || [])].includes(name)).map(([name, value]) => [name, value]));

export const clearPriceList = (priceList?: PriceListItem[]) => priceList?.map?.((item) => clearPart(item)) || [];

export const clearUser = (user?: User) => Object.fromEntries(Object.entries(user || {}).filter(([name, value]) => !!value && userFields.includes(name)).map(([name, value]) => [name, value]));

export const clearUsers = (users?: User[]) => users?.map?.(clearUser) || [];

export const clearExport = (item?: Export) => Object.fromEntries(Object.entries(item || {}).filter(([name, value]) => !!value && exportFields.includes(name)).map(([name, value]) => [name, value]));
