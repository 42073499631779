import { Table, TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';
import { theme } from '../../themes';
import { ReactComponent as SortImage } from './images/sort.svg';

export const TableStyle = styled(Table, {
  shouldForwardProp: (prop) => !['clickable'].includes(prop as string),
})((params: { clickable?: boolean, margin?: string }) => ({
  // tableLayout: 'fixed',
  minWidth: 650,
  margin: params.margin,
  '& thead th': {
    borderTop: `1px solid ${theme.colors.veryLightGray}`,
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    fontSize: 18,
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: theme.colors.whiteSmoke,
  },
  '& tbody td': {
    cursor: params.clickable ? 'pointer' : 'default',
  },
}));

export const Sort = styled(SortImage)<{order?: 'asc' | 'desc'}>`
  height: 12px;
  width: 18px;
  margin-right: 7.5px;

  ${(p) => p.order === 'asc' ? `
    transform: rotate(180deg);
  `: ''}
`;

export const CellStyle = styled(TableCell, {
  shouldForwardProp: (prop) => !['header', 'centered', 'clickable', 'sort', 'sortable'].includes(prop as string),
})<{header?: boolean; centered?: boolean; clickable?: boolean; width?: string; sort?: boolean; sortable?: boolean}>`
  padding: 6px 2px;
  border-left: 1px solid ${theme.colors.veryLightGray};
  border-right: 1px solid ${theme.colors.veryLightGray};
  border-bottom: none;
  border-top: none;

  width: ${(p) => p.width ? p.width : 'auto'} !important;

  font-family: ${theme.fonts.lato};
  text-align: ${(p) => p.centered ? 'center' : 'left'};

  cursor: ${(p) => p.clickable ? 'pointer' : 'default'};
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    display: flex;
    justify-content: ${(p) => p.centered ? 'center' : 'flex-start'};
    align-items: center;
    max-width: ${(p) => p.width ? p.width : 'auto'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0 10px;

    ${(p) => p.sort ? `
      color: ${theme.colors.vividOrange};
    ` : ''};
  }

  ${(p) => p.header ? `
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  ` : `
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;  
  `}

  ${(p) => p.sortable ? `
    cursor: poiner;
    user-select: none;
  ` : ''};
`;
