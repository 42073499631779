import styled from 'styled-components';
import { ReactComponent as PhotoImage } from './images/photo.svg';
import { ReactComponent as EditImage } from './images/edit.svg';
import { ReactComponent as DeleteImage } from './images/delete.svg';
import { ReactComponent as DuplicateImage } from './images/duplicate.svg';
import { theme } from '../../themes';

export const Container = styled.div`
  background-color: white;
  width: 1424px;
`;

export const ToolBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

export const PhotoIcon = styled(PhotoImage)`
  width: 28px;
  height: 24px;
  cursor: pointer;
`;

export const Photo = styled.img`
  max-height: 42px;
  border-radius: 10px;
  cursor: pointer;
`;

export const EditIcon = styled(EditImage)`
  width: 24px;
  height: 24px;
`;

export const DeleteIcon = styled(DeleteImage)`
  width: 24px;
  height: 24px;
`;

export const DuplicateIcon = styled(DuplicateImage)`
  width: 20px;
  height: 20px;
`;

export const Text = styled.div<{marginTop?: number; marginBottom?: number}>`
  color: ${theme.colors.black};

  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;

  ${(p) => p.marginTop ? `
    margin-top: ${p.marginTop}px;
  ` : ''}

${(p) => p.marginBottom ? `
    margin-bottom: ${p.marginBottom}px;
  ` : ''}
`;
