import MuiInput from '@mui/base/Input';
import { styled } from '@mui/system';
import { theme } from '../../themes';
import { ReactComponent as ClearImage } from './images/clear.svg';

export const Container = styled('div')<{disabled?: boolean}>`
  display: flex;
  align-items: center;
  gap: 12px;

  ${(p) => p.disabled ? 'opacity: 0.3;' : ''};

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;

    border-radius: 4px;
    border: 1px solid ${theme.colors.grayishBlue};
    width: 52px;
    height: 24px;
    position: relative;


    > div:first-of-type {
      position: absolute;
      z-index: 10;

      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;

      width: 12px;
      height: 100%;
      border-right: 1px solid ${theme.colors.grayishBlue};
      cursor: pointer;

      > hr {
        margin: 0;
        border: 0.5px solid ${theme.colors.grayishBlue};
        width: 100%;
      }

      > div {
        position: relative;
        height: 12px;
        flex: 1;
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        :last-of-type > svg {
          position: relative;
          left: 0.5px;
        }
      }
    }
  }
`;

export const InputStyle = styled(MuiInput)`
  position: relative;
  padding: 0 17px 2px 17px;

  > input {
    width: 100%;
    border: none;
    outline: none;
  }

  /* Chrome, Safari, Edge, Opera */
  > input::-webkit-outer-spin-button,
  > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  > input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const Clear = styled(ClearImage)`
  position: absolute;
  right: 7px;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

