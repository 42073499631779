import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  gap: 12px;

  > div {
    :nth-of-type(1), :nth-of-type(3) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 600px;
    }
  }
`;

export const Row = styled.div<{gap?: number}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${(p) => p.gap || 16}px;
`;

export const StorageImage = styled.img`
  height: 42px;
  width: 46px;
`;
