import styled from 'styled-components';
import { ReactComponent as DeleteImage } from './images/delete.svg';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: right;

  padding: 5px;
  gap: 12px;

  > div:first-of-type {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    overflow: scroll;
  }

  > div:last-of-type {
    height: 50%;
    padding: 20px;
    border: 1px solid black;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const DeleteIcon = styled(DeleteImage)`
  width: 24px;
  height: 24px;
`;

export const Image = styled.div<{background: string}>`
  height: 100%;
  width: 100%;
  margin: 0 0 auto;
  
  background-image: url(${(p) => p.background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  cursor: pointer;
`;
