import styled from 'styled-components';
import { theme } from '../../../../themes';
import { ReactComponent as MailImage } from './images/mail.svg';

export const Container = styled.div<{noMargin?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background: ${theme.colors.veryLightGray};
  height: 140px;
  min-height: 140px;

  ${(p) => !p.noMargin ? `
    margin-top: 48px;
  ` : ''}
`;

export const Content = styled.div<{smallMargin?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 32px;

  width: 100%;
  max-width: 1424px;
  margin: 0 auto;

  a, img {
    white-space: nowrap;
    cursor: pointer;
  }

  > div {
    display: flex;
    align-items: center;

    font-family: ${theme.fonts.lato};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    > span {
      white-space: nowrap;
    }

    :last-of-type {
      gap: ${(p) => p.smallMargin ? 16 : 32}px;

      > div {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }
`;

export const Images = styled.div`
  > div:first-of-type > img {
    width: 138px;
    height: 40px;
  }
`;

export const Mail = styled(MailImage)`
  width: 20px;
  height: 30px;
  margin-right: 5px;
  margin-top: 2px;
`;

export const Menu = styled.div<{smallMargin?: boolean}>`
  display: flex;
  gap: ${(p) => p.smallMargin ? 16 : 32}px;

  > a {
    white-space: nowrap;
  }
`;
