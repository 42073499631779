import { styled } from '@mui/system';
import { Select, Menu } from '@mui/base';
import { theme } from '../../themes';
import { ReactComponent as ArrowImage } from './images/arrow.svg';
import { ReactComponent as RemoveImage } from './images/remove.svg';
import { ReactComponent as SearchImage } from './images/search.svg';
import { ReactComponent as NoPictureImage } from './images/no-picture.svg';
import { ListItemText } from '@mui/material';

export const Container = styled('div')<{width?: string; margin?: string; inline?: boolean}>`
  height: ${(p) => p.inline ?  '30': '56'}px;
  display: flex;
  flex-direction: column;
  position: relative;

  ${(p) => p.width ? `width: ${p.width};` : ''}
  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const SelectStyle = styled(Select, {
  shouldForwardProp: (prop) => !['active', 'MenuProps', 'inline', 'error', 'disabled'].includes(prop as string),
})<{active?: boolean; inline?: boolean; error?: boolean; disabled?: boolean}>`
  height: 64px;
  padding: 21px 50px 0 20px;
  border: 0;
  border-radius: 12px;
  box-shadow: 0px 2px 6px 0px #00000033;
  color: ${(p) => p.disabled ? theme.colors.grayishBlue : theme.colors.black};
  background: white;

  outline: none;
  width: 100%;

  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: all 0.2s ease-out;

  ${(p) => {
    if (p.error) {
      return `border: 2px solid ${theme.colors.vividRed};`;
    } else if (p.active) {
      return `border: 2px solid ${theme.colors.grayishBlue};`;
    } else {
      return '';
    }
  }}

  ${(p) => p.inline ? `
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid ${theme.colors.veryLightGray};
    background: none;
    height: 30px;
  ` : ''}
`;

export const Placeholder = styled('span')<{active: boolean; width?: number}>`
  position: absolute;
  top: ${(p) => p.active ? 5 : 14.5}px;
  left: 24px;
  color: ${theme.colors.grayishBlue};
  cursor: default;

  font-family: ${theme.fonts.lato};
  font-size: ${(p) => p.active ? 12 : 18}px;
  font-weight: 500;
  line-height: ${(p) => p.active ? 18 : 27}px;
  letter-spacing: 0em;
  text-align: left;

  transition: all 0.2s ease-out;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(p) => p.width ? `max-width: ${p.width-61}px;` : ''}
`;

export const InputPlaceholder = styled('span')`
  position: absolute;
  top: 24px;
  left: 24px;
  color: ${theme.colors.veryLightGray};

  font-family: ${theme.fonts.lato};
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Arrow = styled(ArrowImage)<{open?: boolean}>`
  position: absolute;
  top: 20px;
  right: 24px;
  height: 24px;
  width: 24px;

  ${(p) => p.open ? `
    transform: rotate(180deg);
  ` : ''}

  transition: all 0.2s ease-out;
`;

export const Remove = styled(RemoveImage)`
  position: absolute;
  top: 16px;
  right: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const Search = styled(SearchImage)`
  position: absolute;
  top: 20px;
  right: 24px;
  height: 24px;
  width: 24px;
`;

export const NoPicture = styled(NoPictureImage)`
  margin-right: 16px;
  height: 40px;
  width: 40px;
`;

export const MenuStyle = styled(Menu, {
  shouldForwardProp: (prop) => !['maxHeight', 'search', 'hasPictures', 'inline'].includes(prop as string),
})<{width?: number; search?: boolean; hasPictures?: boolean; inline?: boolean}>`
  padding: 12px 0;
  z-index: 200;

  ul {
    padding: 0;
    border: 0;
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0px #00000033;
    color: ${theme.colors.black};
    background: white;
    margin: 0;
    overflow: hidden;
    
    
    > div {
      margin: ${(p) => p.inline ? '0' : '24px 0'};
      max-height: ${(p) => p.maxHeight || 300}px;
      ${(p) => p.width ? `width: ${p.width}px;` : ''}
      overflow-y: scroll;

      li {
        padding: 8px ${(p) => p.search ? 48 : 24}px;
        height: ${(p) => p.hasPictures ? 56 : 42}px;

        ${(p) => p.inline ? `
          padding: 8px;
        ` : ''}
      }
    }
  }
`;

export const SearchContainer = styled('div')`
  position: relative;
  height: 64px;
  margin: 8px 24px 16px;
  padding: 18.5px 24px;
  border-radius: 12px;
  background: ${theme.colors.whiteSmoke};

  input {
    font-family: ${theme.fonts.lato};
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const NoResults = styled('div')`
  padding-top: 8px;
  margin: 0 48px;
  color: ${theme.colors.black};

  font-family: ${theme.fonts.lato};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const ListItemTextStyle = styled(ListItemText, {
  shouldForwardProp: (prop) => !['active'].includes(prop as string),
})<{active?: boolean}>`
  > span {
    display: inline;
    margin: 0 auto 0 0;

    ${(p) => p.active ? `
      background-image: linear-gradient(to bottom, #F165224C, #F165224C);
      background-position: 0 6px;
      background-size: 1px 8px;
      background-repeat: repeat-x;
    ` : ''}
  }
`;

export const Error = styled('span')<{active: boolean}>`
  opacity: ${(p) => p.active ? 1 : 0};
  position: absolute;
  bottom: -14px;
  left: 24px;
  color: ${theme.colors.vividRed};

  font-family: ${theme.fonts.lato};
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  transition: opacity 0.5s ease-out;
`;
