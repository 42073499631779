import React, { useCallback, useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { FilesContextType, useFilesContext } from '../../../../../../context';
import { Container, Label, Photo, OtherContainer, PhotoPlaceholder, Remove } from './style';

const maxPictures = 5;

interface Props {
  urls?: string[];
  addToDelete: (file: string) => void;
}

export const AddPicture = ({ urls, addToDelete }: Props) => {
  const { t } = useTranslation();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const { files, addFiles, removeFile } = useFilesContext() as FilesContextType;

  const images = useMemo(() => [...(urls || []), ...files.map((file) => URL.createObjectURL(file))], [files, urls]);

  const [, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: (item) => addFiles((item as { files: File[] }).files.slice(0, maxPictures - images.length).map((file) => file)),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const remove = useCallback((index: number) => {
    if (urls?.includes(images[index])) {
      addToDelete(images[index]);
    } else {
      removeFile(index);
    }
  }, [addToDelete, images, removeFile, urls]);

  return <Container ref={drop}>
    <input
      type='file'
      accept='image/*'
      multiple
      onChange={(event) => {
        const data = Array.from((event.target?.files || []));
        addFiles(data.slice(0, maxPictures - files.length));
      }}
      style={{ display: 'none' }}
      ref={fileInputRef}
    />
    <Label margin='0 0 12px 0'>{t('Product photos')}</Label>
    <OtherContainer>
      {[0, 1, 2, 3].map((i) => <Photo
        key={i}
        src={images[i] ? images[i] : undefined}
        onClick={!images?.[i] ? () => fileInputRef.current?.click?.() : undefined}
      >
        {!images?.[i] && <PhotoPlaceholder />}
        {images?.[i] && <>
          <div />
          <Remove onClick={() => remove(i)} />
        </>}
      </Photo>)}
    </OtherContainer>
  </Container>;
};
