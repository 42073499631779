import { equals } from 'ramda';
import { authFailure, authSuccess, setAuthStatus } from '.';
import { InitResponse } from '../../../../types';
import { AppThunk } from '../store';

export const authInitAction = (): AppThunk => async (dispatch, getState) => {

  try {
    dispatch(setAuthStatus('load'));
    const auth: InitResponse = await fetch(`${window.location.origin}/api/auth/init`).then(res => res.json());

    dispatch(authSuccess(auth.user));
  } catch (error) {
    dispatch(authFailure((error as Error).toString()));
  }

  const checkAuthPeriod = 30 * 60 * 1000;

  const checkAuth = async () => {
    try {
      const user = getState().Auth.user;
      const userId = user?.id;
      const auth: InitResponse = await fetch(`${window.location.origin}/api/auth/init`).then(res => res.json());
      const logout = getState().Auth.logout;

      if ( logout ) {
        window.location.href = '/api/sso-logout';
        return;
      }

      if (!equals(auth.user, user) || auth.user.id !== userId) {
        dispatch(authSuccess(auth.user));
      }
      if (userId && !auth.user) {
        document.location.href = '/api/sso-login';
      }
    } catch (error) {
      dispatch(authFailure((error as Error).toString()));
    }
    setTimeout(checkAuth, checkAuthPeriod);
  };

  setTimeout(checkAuth, checkAuthPeriod);
};
