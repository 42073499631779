import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadStatus, User } from '../../../../types';

interface State {
  userLoadStatus: LoadStatus;
  user?: User;
  error?: string;
  logout?: boolean;
}

export const initialState: State = {
  userLoadStatus: 'new',
};

const Slice = createSlice({
  name: 'Auth',
  initialState,
  reducers: {
    setAuthStatus(state, action: PayloadAction<LoadStatus>) {
      state.userLoadStatus = action.payload;
    },
    authSuccess(state, action: PayloadAction<User | null>) {
      state.user = action.payload ?? undefined;
      state.userLoadStatus = 'success';
    },
    authReset(state) {
      state.user = undefined;
      state.userLoadStatus = 'new';
    },
    authFailure(state, action: PayloadAction<string>) {
      state.userLoadStatus = 'error';
      state.error = action.payload;
    },
    setUser(state, action: PayloadAction<User | null>) {
      state.user = action.payload ?? undefined;
    },
    setLogout(state, action: PayloadAction<boolean>) {
      state.logout = action.payload;
    },
  },
});

export const {
  setAuthStatus,
  authSuccess,
  authFailure,
  authReset,
  setUser,
  setLogout,
} = Slice.actions;

export default Slice.reducer;

export * from './actions';
