import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup, Tabs } from '../../..';
import { AvitoData, CarShort, PriceListItem } from '../../../../../../types';
import { ButtonsWide, Label, Title } from '../../style';
import { Basic, OEMs, PartExport } from './components';
import { Content, Divider, Header, PopupContent } from './style';

const tabNames = ['Basic', 'Export'];

export type PartDetailsMode = 'new' | 'duplicate';

const modeToTitle: Record<PartDetailsMode, string> = {
  new: 'Add part',
  duplicate: 'Duplicate part',
};

interface Props {
  part?: Partial<PriceListItem>;
  carsShort: CarShort[];
  mode?: PartDetailsMode;
  onChange: (part: Omit<PriceListItem, 'id'> | PriceListItem, filesToDelete: string[]) => void;
  onClose: () => void;
}

export const PopupPartDetails = ({ part, carsShort, mode, onChange, onClose }: Props) => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(0);
  const [localPart, setLocalPart] = useState(part);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  return <Popup width='1320px' height='985px' onClose={onClose}>
    <PopupContent>
      <Header>
        <Title>{t(mode && modeToTitle[mode] ? modeToTitle[mode] : 'Change part')}</Title>
        {part?.id && !mode && <Label>{`# ${part?.id}`}</Label>}
        <Tabs
          items={tabNames}
          selected={tab}
          margin='0 0 0 auto'
          setSelected={setTab}
        />
      </Header>
      {tab === 0 && <Content>
        <div>
          <Basic
            part={localPart}
            filesToDelete={filesToDelete}
            carsShort={carsShort}
            setPart={(part) => setLocalPart({ ...localPart, ...part } as PriceListItem)}
            addFileToDelete={(file: string) => setFilesToDelete([...filesToDelete, file])}
          />
          <Divider />
          <OEMs
            oems={localPart?.oems || []}
            setOems={(oems) => setLocalPart({ ...localPart, oems } as PriceListItem)}
          />
        </div>
      </Content>}
      {tab === 1 && <PartExport
        isExport={localPart?.isExportAvito}
        avitoData={localPart?.avito}
        setIsExport={(value: boolean) => setLocalPart({ ...localPart, ...part, isExportAvito: value })}
        setAvitoData={(avitoData: Partial<AvitoData>) => setLocalPart({
          ...part,
          ...localPart,
          avito: {
            ...(localPart?.avito || {}),
            ...(part?.avito || {}),
            ...avitoData,
          },
        })}
      />}
      <ButtonsWide>
        <Button onClick={() => setLocalPart({ ...localPart, oems: [...(localPart?.oems || []), { isMain: !localPart?.oems?.find((item) => item.isMain) }] })}>Add OEM</Button>
        <div>
          <Button secondary onClick={() => onClose()}>Cancel</Button>
          <Button onClick={() => {
            if (localPart) {
              const localPartChanged = {
                name: '',
                quantity: 1,
                ...localPart,
                oems: localPart.oems?.filter?.((item) => !!item.oem) || [],
              };

              onChange(localPartChanged, filesToDelete);
            }
            onClose();
          }}>Accept</Button>
        </div>
      </ButtonsWide>
    </PopupContent>
  </Popup >;
};
