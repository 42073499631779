import React from 'react';
import { WheelDrive, wheelDrivesList } from '../../types';
import { Container, Item } from './style';

interface Props {
  selected?: WheelDrive;
  setSelected: (value: WheelDrive) => void;
}

export const WheelDriveSelect = ({ selected, setSelected }: Props) => {
  return <Container>
    {wheelDrivesList.map((value, i) => <Item key={i} selected={value === selected} onClick={() => setSelected(value as WheelDrive)}>{value}</Item>)}
  </Container>;
};
