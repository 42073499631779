import styled from 'styled-components';
import { theme } from '../../../../../../themes';
import { ReactComponent as PhotoImage } from './images/photo.svg';
import { ReactComponent as RemoveImage } from './images/remove.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OtherContainer = styled.div`
  display: flex;
  gap: 16px;
`;

export const Photo = styled.div<{src?: string; hover?: boolean}>`
  display: flex;
  width: 136px;
  height: 82px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  position: relative;

  border-radius: 12px;
  border: 1px solid ${theme.colors.grayishBlue};

  ${(p) => p.src ? `
    > div {
      height: 100%;
      width: 100%;

      background-image: url(${p.src});
      background-size: cover;
    }` : `
      cursor: pointer;
    `}
`;

export const Label = styled.div<{margin?: string}>`
  font-family: ${theme.fonts.lato};
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: ${theme.colors.grayishBlue};

  ${(p) => p.margin ? `margin: ${p.margin};` : ''}
`;

export const Remove = styled(RemoveImage)`
  position: absolute;
  top: 10px;
  right: 10px;

  height: 10px;
  width: 10px;

  cursor: pointer;
`;

export const PhotoPlaceholder = styled(PhotoImage).withConfig({
  shouldForwardProp: (prop) => !['main'].includes(prop as string),
})<{main?: boolean}>`
  ${(p) => p.main ? `
    width: 296px;
    height: 230px;
  ` : `
    width: 40px;
    height: 33px;
  `}
`;
